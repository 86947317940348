import { Directive, EventEmitter, HostBinding, Inject, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { CxNumericPipe, RoutingService } from '@spartacus/core';
import { BehaviorSubject, Observable, Subject, Subscription, combineLatest, of } from 'rxjs';
import { filter, map, mapTo, shareReplay, startWith, switchMap, take, withLatestFrom } from 'rxjs/operators';
import { ArticleService, CategoryService } from '../../../core/catalog';
import { GlobalMessageService, GlobalMessageType } from '../../../core/global-message';
import { TranslationService } from '../../../core/i18n';
import {
  AccountingCodeList,
  Article,
  ArticleCarouselActionMode,
  ArticleComparativePrice,
  ArticlePrice,
  AutoResolveChangeType,
  BasicOrderLine,
  CartError,
  CartType,
  ColumnAttribute,
  ConditionType,
  ConfigurationInfo,
  CuttingRequest,
  DetailedOrderLine,
  HorizontalAlignment,
  LoaderError,
  OrderCondition,
  OrderDetailsOrderLine,
  OrderEntry,
  OrderReturn,
  OrderReturnEntry,
  PrePrintedSpecial,
  Price,
  ReelCuttingRequest,
  ResolveOptionActionType,
  SimulatedCartMessage,
  SubstituteRef,
  SubstituteRefType,
  SubstituteType,
} from '../../../core/model';
import { RoutingHelperService } from '../../../core/routing';
import { ArticleInfoAttributesFacade, PriceFacade, PrincipalConfigurationService } from '../../../core/user';
import { prepareUrlForLink } from '../../../core/util';
import { ActiveCartFacade } from '../../../features/cart/base';
import { CatalogTabTypes } from '../../../features/catalog/container/catalog/catalog-search.service';
import { OrderReturnFacade } from '../../../features/order-base';
import { SoldToFacade } from '../../../features/sold-to-base';
import { CART_TYPE_PARAM_KEY } from '../../guards';
import { UnitPipe } from '../../pipes/unit.pipe';
import { getTextToDisplayInsteadOfPrice } from '../../utils/price-utility';
import { Option } from '../dropdown/dropdown.component';
import { ORDER_ENTRY_CONFIG, OrderEntryConfig } from './order-entry.config';

export type OrderEntryComponentEntry = BasicOrderLine &
  Partial<DetailedOrderLine> &
  Partial<Pick<OrderDetailsOrderLine, 'netPrice' | 'explodedOrderLines' | 'itemNumber'>> &
  Partial<
    Pick<
      OrderEntry,
      | 'autoResolveChange'
      | 'resolveOptions'
      | 'totalPrice'
      | 'splitOrderEntry'
      | 'discount'
      | 'cartPrice'
      | 'configurationInfos'
      | 'contractedPrice'
      | 'entryNumber'
      | 'id'
      | 'messages'
      | 'showCustomerSelectedUnit'
      | 'prePrintedMaterialNumber'
    >
  >;

export interface UpdateQuantityEvent {
  quantity: number;
  unitCode?: string;
}
@Directive()
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export abstract class OrderEntryComponent implements OnInit, OnDestroy {
  readonly carouselActionModes: typeof ArticleCarouselActionMode = ArticleCarouselActionMode;
  readonly prePrintedSpecial = PrePrintedSpecial;

  @ViewChild('addNote') addNote: any;
  @ViewChild('addStatisticsCode') addStatisticsCode: any;
  @ViewChild('accountingCodes') accountingCodes: any;

  @Input() set entry(entry: OrderEntryComponentEntry) {
    this._entry = entry;
    this.entryUpdated$.next();
    if (this.config.loadAndShowArticlePriceInsteadOfEntryPrice && !getTextToDisplayInsteadOfPrice(this.cartType)) {
      this.loadPrice();
    }
  }
  @Input() set article(article: Article) {
    this._article = article;
    if (article) {
      if (!getTextToDisplayInsteadOfPrice(this.cartType)) {
        this.loadPrice(true, false);
      }

      this.loadArticleSubstitutes(article);
    }
    this.article$.next(article);
  }
  @Input() selected = false;
  @Input() loading = false;
  @Input() similarArticles$: Observable<Article[]> | null;
  @Input() showPrice: boolean = true;
  @Input() showSubstitutesArticles: boolean = true;
  @Input() showBrowseReplacementButton: boolean = true;
  @Input() formGroup: UntypedFormGroup;
  @Input() controlsEnabled: boolean = true;
  @Input() enableEditEntryAndShowingSimilar?: boolean = true;
  @Input() isCartWriter?: boolean;
  @Input() showAccountingCodeTooltip: boolean = true;
  @Input() accountingCodeList$: Observable<AccountingCodeList>;
  @Input() accountingCodeListLoading: boolean;
  @Input() disableAccountingCodeSelection: boolean;
  @Input() cartType: CartType;
  @Input() cartErrors: CartError[] | null;
  @Input() showConsignmentStockLevel = false;
  @Input()
  @HostBinding('class.secondary-article-row-variant')
  enableSecondaryArticleRowVariant = false;
  @Input() entryMessages?: SimulatedCartMessage[];
  @Input() orderReturn?: OrderReturn;

  @Output() delete = new EventEmitter();
  @Output() replace = new EventEmitter<Article>();
  @Output() addToCart = new EventEmitter<Article>();
  @Output() updateNote = new EventEmitter<string | null>();
  @Output() updateStatisticsCode = new EventEmitter<string | null>();
  @Output() updateQuantity = new EventEmitter<UpdateQuantityEvent>();
  @Output() entrySelect = new EventEmitter<boolean>();
  @Output() selectResolveOption = new EventEmitter<ResolveOptionActionType>();
  @Output() accountingCodeUpdated = new EventEmitter();
  @Output() fullPalletUpgraded = new EventEmitter();

  isSimilarArticlesExpanded = false;
  isOrderNoteMandatory: boolean = false;
  isStatisticsCodeMandatory: boolean = false;
  isOnCheckoutPage: boolean = false;
  isOnOrderHistoryPage: boolean = false;
  showExtrasSectionInReturnMode: boolean = false;
  isInOrderReturnMode$: Observable<boolean>;
  stockQuantityWarning$: Observable<string>;
  stockQuantityWarningForPartialDelivery$: Observable<string>;
  outOfStockAction$: Observable<string>;
  articleFailure$: Observable<LoaderError | undefined>;
  cartTypeQueryParams: Record<string, CartType>;
  cartTypeStock = CartType.stock;
  substituteBadgeType$: Observable<SubstituteType>;
  substituteRefs$: Observable<SubstituteRef[]>;
  columnAttributes$: Observable<ColumnAttribute[]>;
  displayCuttingMessage$: Observable<boolean>;
  displayReamingMessage$: Observable<boolean>;
  initialMinimumQuantity$: Observable<number>;

  private _entry: OrderEntryComponentEntry;
  private _article: Article;
  private entryUpdated$ = new Subject<void>();
  private subscriptions = new Subscription();
  private entryFormGroupId = Math.random().toString(36).substr(2, 9);
  private article$ = new BehaviorSubject<Article>(this.article);
  private enableCuttingOrEnableReelCutting$: Observable<boolean>;
  private enableReaming$: Observable<boolean>;

  articlePrice$: Observable<ArticlePrice>;
  loadingPrice$: Observable<boolean>;
  emitLoadPrice$ = new BehaviorSubject<{ async: boolean; forceLoadPrice: boolean }>({ async: true, forceLoadPrice: true });
  horizontalAlignments = HorizontalAlignment;

  entryFormGroup = new UntypedFormGroup({
    note: new UntypedFormControl(''),
    statisticsCode: new UntypedFormControl(''),
    accountingCode: new UntypedFormControl(''),
    id: new UntypedFormControl(this.entryFormGroupId),
  });

  get entry(): OrderEntryComponentEntry {
    return this._entry;
  }

  get article(): Article {
    if (this._article || this.loading) {
      return this._article;
    }

    if (!!this.entry?.shortText) {
      return {
        displayName: this.entry.shortText,
        articleNumber: this.entry.articleRef,
        hideLinkToProductPage: true,
      };
    }

    return undefined;
  }

  get showIndentNotification(): boolean {
    return this.entry?.indent;
  }

  get hasResolveOptions(): boolean {
    return this.entry?.resolveOptions?.length > 0;
  }

  get hasDefaultResolveOptions(): boolean {
    return (
      this.entry?.resolveOptions?.filter((resolveOption) => resolveOption.action !== ResolveOptionActionType.ALTERNATIVE_ARTICLE)
        ?.length > 0
    );
  }

  get activeOrderConditions(): OrderCondition[] {
    const conditionTypes = [
      ConditionType.YVS1,
      ConditionType.YVS2,
      ConditionType.YVS4,
      ConditionType.YVS6,
      ConditionType.YVS7,
      ConditionType.YVS8,
    ];
    return (
      this.entry.conditions?.filter(
        (orderCondition) =>
          orderCondition.active &&
          !conditionTypes.includes(orderCondition.type as ConditionType) &&
          orderCondition.value?.value > 0
      ) || []
    );
  }

  get hasActiveOrderConditions(): boolean {
    return this.activeOrderConditions.length > 0;
  }

  get nameConfigurationInfo(): ConfigurationInfo {
    return this.entry.configurationInfos?.find((configurationInfo) => configurationInfo.qualifier === 'name-marking');
  }

  get hasNameMarking(): boolean {
    return !!this.nameConfigurationInfo;
  }

  get articleNumber(): string {
    if (this.entry?.articleRef !== PrePrintedSpecial.VOLVO) {
      return this.article?.articleNumber;
    }
    return this.entry?.prePrintedMaterialNumber || this.entry?.prePrintedLabel?.materialNumber || this.article?.articleNumber;
  }

  get hasPrePrintableLabel(): boolean {
    return this.config.enablePrePrintedLabels && !!this.article?.prePrintable && this.articleNumber !== PrePrintedSpecial.VOLVO;
  }

  get showPrePrintedLabelButton(): boolean {
    return (
      this.hasPrePrintableLabel &&
      this.config.showPrePrintedLabelsButton &&
      this.cartType === this.cartTypeStock &&
      this.entry?.articleRef !== PrePrintedSpecial.VOLVO
    );
  }

  get isDiscontinued(): boolean {
    return this.articleService.isArticleDiscontinued(this.article);
  }

  get isSalesBlocked(): boolean {
    return this.article?.salesBlocked;
  }

  get isOrderEntryReturned(): boolean {
    return this.orderReturn?.entries.some((orderReturnEntry) => orderReturnEntry.itemNumber === this.entry.itemNumber);
  }

  updateNameMarking(values: string[]): void {
    if (this.hasNameMarking) {
      this.activeCartService.updateNameMarking(
        this.entry,
        this.nameConfigurationInfo.qualifier,
        this.nameConfigurationInfo.id,
        this.nameConfigurationInfo.extendedIdentifier,
        values,
        this.cartType
      );
    }
  }

  updateCutting(cuttingRequestData: CuttingRequest): void {
    this.activeCartService.addEntryCutting(this.entry, cuttingRequestData, this.cartType);
  }

  removeCutting(): void {
    this.activeCartService.removeEntryCutting(this.entry, this.cartType);
  }

  updateReelCutting(cuttingRequestData: ReelCuttingRequest): void {
    this.activeCartService.addEntryReelCutting(this.entry, cuttingRequestData, this.cartType);
  }

  removeReelCutting(): void {
    this.activeCartService.removeEntryReelCutting(this.entry, this.cartType);
  }

  addReaming(): void {
    this.activeCartService.addEntryReaming(this.entry, this.cartType);
  }

  removeReaming(): void {
    this.activeCartService.removeEntryReaming(this.entry, this.cartType);
  }

  onFullPalletUpgraded(): void {
    this.fullPalletUpgraded.emit();
  }

  updateQuantityAndUnit(values: { quantity: number; unitCode: string }): void {
    this.updateQuantity.emit(values);
  }

  get hasTransferConfiguration(): boolean {
    return this.entry.configurationInfos?.some((configurationInfo) => configurationInfo.qualifier === 'transfer');
  }

  get unitDropdownOptions(): Option[] {
    return (
      this.article?.units?.map((unit) => ({
        value: unit.code,
        label: this.unitPipe.transform(unit, this.entry.quantity),
        description: `${this.decimalPipe.transform(unit?.inEcommerceUnit, '1.0-0')} ${this.unitPipe.transform(
          this.article.unit,
          unit?.inEcommerceUnit
        )}`,
      })) || []
    );
  }

  get inEcommerceQuantity(): number {
    if (this.entry.unit?.inEcommerceUnit === 0) {
      const unit = this.article?.units?.find((u) => u.code === this.entry.unit?.code);
      return this.entry.quantity * unit?.inEcommerceUnit;
    }
    return this.entry.quantity * this.entry.unit?.inEcommerceUnit;
  }

  get unitQuantityMessage(): string | undefined {
    return this.inEcommerceQuantity
      ? `${this.decimalPipe.transform(this.inEcommerceQuantity, '1.0-0')} ${this.unitPipe.transform(
          this.article?.unit,
          this.inEcommerceQuantity
        )}`
      : `${this.decimalPipe.transform(this.entry?.quantity, '1.0-0')} ${this.unitPipe.transform(
          this.entry?.unit,
          this.entry?.quantity
        )}`;
  }

  get canExpandSimilarArticles(): boolean {
    return this.similarArticles$ != null && this.enableEditEntryAndShowingSimilar;
  }

  get hideCartLink(): boolean {
    return this.enableEditEntryAndShowingSimilar === false;
  }

  get price(): Price {
    return this.entry.totalPrice || this.entry.cartPrice || this.entry.netPrice;
  }

  get contractedPrice(): Price {
    return this.entry.contractedPrice;
  }

  get showPalletFlagNote(): boolean {
    return this.config.enableFullPalletNoteControl && this.cartType === this.cartTypeStock && this.entry.unit?.code === 'PAL';
  }

  get getReturnEntryForCurrentEntry(): OrderReturnEntry {
    return this.orderReturn?.entries.find((returnEntry) => returnEntry.itemNumber === this.entry.itemNumber);
  }

  private get formGroupEntries(): UntypedFormArray {
    return this.formGroup?.get('formGroupEntries') as UntypedFormArray;
  }

  constructor(
    @Inject(ORDER_ENTRY_CONFIG) public config: OrderEntryConfig,
    private unitPipe: UnitPipe,
    private decimalPipe: CxNumericPipe,
    private translationService: TranslationService,
    private principalConfigurationService: PrincipalConfigurationService,
    private articleService: ArticleService,
    private activeCartService: ActiveCartFacade,
    private priceService: PriceFacade,
    private categoryService: CategoryService,
    private routingService: RoutingService,
    private routingHelperService: RoutingHelperService,
    private globalMessageService: GlobalMessageService,
    private articleInfoAttributesService: ArticleInfoAttributesFacade,
    private orderReturnService: OrderReturnFacade,
    private soldToService: SoldToFacade,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.cartTypeQueryParams = { [CART_TYPE_PARAM_KEY]: this.cartType };

    this.isOnCheckoutPage = this.routingHelperService.getCurrentUrlWithoutParams()?.includes('/checkout') ? true : false;
    this.isOnOrderHistoryPage = this.routingHelperService.getCurrentUrlWithoutParams()?.includes('/order-history');

    this.stockQuantityWarning$ = this.entryUpdated$.pipe(
      mapTo(this.entry),
      startWith(this.entry),
      filter((entry) => entry?.autoResolveChange?.changeType === AutoResolveChangeType.CHANGE_TYPE_QUANTITY),
      switchMap((entry) =>
        this.translationService.translate('checkout.review.resolveOptionChangeQuantity_hint', {
          quantity: entry.autoResolveChange.confirmedQuantity,
          unit: entry.autoResolveChange.confirmedUnit,
        })
      )
    );

    this.stockQuantityWarningForPartialDelivery$ = this.entryUpdated$.pipe(
      mapTo(this.entry),
      startWith(this.entry),
      filter((entry) => !!entry && !!entry.autoResolveChange),
      filter((entry) =>
        [AutoResolveChangeType.CHANGE_TYPE_QUANTITY, AutoResolveChangeType.CHANGE_TYPE_DATE].includes(
          entry.autoResolveChange.changeType
        )
      ),
      switchMap((entry) =>
        this.translationService.translate('checkout.review.resolveOptionChangeQuantity_hint', {
          quantity: entry.autoResolveChange.confirmedQuantity,
          unit: entry.autoResolveChange.confirmedUnit,
        })
      )
    );

    this.outOfStockAction$ = this.translationService.translate(this.config.outOfStockAction);

    this.subscriptions.add(
      this.principalConfigurationService.isEnabled('enableMandatoryOrderLineNote').subscribe((isEnabled) => {
        this.isOrderNoteMandatory = isEnabled && this.config.enableMandatoryNoteControlOption;
      })
    );

    this.subscriptions.add(
      this.principalConfigurationService.isEnabled('enableMandatoryStatisticsCode').subscribe((isEnabled) => {
        this.isStatisticsCodeMandatory = isEnabled && this.config.enableMandatoryStatisticsCodeControl;
      })
    );

    this.enableCuttingOrEnableReelCutting$ = combineLatest([
      this.principalConfigurationService.isEnabled('enableCutting'),
      this.principalConfigurationService.isEnabled('enableReelCutting'),
    ]).pipe(
      map(([enableCutting, enableReelCutting]) => enableCutting || enableReelCutting),
      shareReplay({ bufferSize: 1, refCount: true })
    );

    this.enableReaming$ = this.principalConfigurationService.isEnabled('enableReaming');

    this.formGroupEntries?.push(this.entryFormGroup);

    this.articleFailure$ = this.articleService.getArticleFailure(this.entry?.articleRef).pipe(
      filter((d) => !!d),
      shareReplay({ bufferSize: 1, refCount: true })
    );

    this.loadingPrice$ = this.emitLoadPrice$.pipe(
      switchMap((_value) => {
        if (this.config.loadAndShowArticlePriceInsteadOfEntryPrice) {
          return this.priceService.loadingPrice(this.entry?.articleRef, this.entry?.quantity, this.entry?.unit);
        }
        return of(this.loading);
      })
    );

    this.articlePrice$ = this.emitLoadPrice$.pipe(
      filter(
        (_emitLoadPrice) => !!this.article && !this.isDiscontinued && this.config.loadAndShowArticlePriceInsteadOfEntryPrice
      ),
      switchMap((emitLoadPrice) =>
        this.priceService.getArticlePrice(
          this.entry?.articleRef,
          this.entry?.quantity,
          this.entry?.unit,
          emitLoadPrice.async,
          emitLoadPrice.forceLoadPrice
        )
      )
    );

    this.substituteBadgeType$ = this.activeCartService
      .getActiveCartId(this.cartType)
      .pipe(
        switchMap((cartId) =>
          this.globalMessageService
            .getGlobalMessageByUid(GlobalMessageType.MSG_TYPE_CONFIRMATION, `${cartId}-${this.entry.articleRef}`)
            .pipe(map((message) => (!!message ? SubstituteType.Replacement : undefined)))
        )
      );

    this.columnAttributes$ = this.article$.pipe(
      filter((article) => !!article),
      switchMap((article) =>
        this.articleInfoAttributesService.getColumnAttributes(article, this.enableSecondaryArticleRowVariant)
      )
    );

    const displayCuttingAndReamingMessages$ = this.principalConfigurationService.isEnabled('displayCuttingAndReamingMessages');

    this.displayCuttingMessage$ = this.article$.pipe(
      filter(() => !!this.isOnCheckoutPage),
      withLatestFrom(this.enableCuttingOrEnableReelCutting$, displayCuttingAndReamingMessages$),
      map(
        ([article, enableCuttingOrRealCutting, displayCuttingAndReamingMessages]) =>
          !!article?.cuttable && enableCuttingOrRealCutting && displayCuttingAndReamingMessages
      )
    );

    this.displayReamingMessage$ = this.article$.pipe(
      filter(() => !!this.isOnCheckoutPage),
      withLatestFrom(this.enableReaming$, displayCuttingAndReamingMessages$),
      map(
        ([article, enableReaming, displayReamingAndReamingMessages]) =>
          !!article?.reamable && enableReaming && displayReamingAndReamingMessages
      )
    );

    const orderCode = this.route.snapshot.params['orderCode'];
    if (orderCode) {
      this.isInOrderReturnMode$ = this.orderReturnService.isOrderReturnDraftActiveForOrder(orderCode);
    }

    this.initialMinimumQuantity$ = this.soldToService
      .getActiveSoldTo()
      .pipe(map((soldTo) => (soldTo.paperManagementEnabled ? 1 : undefined)));
  }

  navigateToReplacementCategoryOrSearchForDisplayName(categoryCode: string, displayName): void {
    if (!!categoryCode) {
      this.categoryService
        .getCategory(categoryCode)
        .pipe(
          take(1),
          filter((category) => !!category)
        )
        .subscribe((category) =>
          this.routingService.goByUrl(prepareUrlForLink(category.url, { c: category.code, tab: CatalogTabTypes.Articles }))
        );
    } else {
      this.routingService.go(
        { cxRoute: 'catalog' },
        {
          queryParams: { query: `${displayName}::`, tab: CatalogTabTypes.Articles },
        }
      );
    }
  }

  loadArticleSubstitutes(article: Article): void {
    if (this.isDiscontinued && this.showSubstitutesArticles) {
      this.substituteRefs$ = this.articleService
        .getSubstitutesArticles(article.code)
        .pipe(map((substitutes) => substitutes?.filter((substitute) => substitute.refType === SubstituteRefType.Article)));
    }
  }

  loadPrice(async: boolean = false, forceLoadPrice: boolean = false) {
    this.emitLoadPrice$.next({ async, forceLoadPrice });
  }

  onAccountingCodeUpdated() {
    this.accountingCodeUpdated.emit();
  }

  markAsDirty() {
    this.addNote?.markAsDirty();
    this.addStatisticsCode?.markAsDirty();
    this.accountingCodes?.markAsDirty();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
    this.formGroupEntries?.removeAt(this.formGroupEntries?.value.findIndex((e) => e.id === this.entryFormGroupId));
  }

  onConfirmedDelete() {
    this.delete.emit();
  }

  onShowSimilarItems() {
    if (this.canExpandSimilarArticles) {
      this.isSimilarArticlesExpanded = !this.isSimilarArticlesExpanded;
    }
  }

  onReplaceWithSimilarItem(replacementArticle: Article) {
    this.replace.emit(replacementArticle);
  }

  onReplaceWithSubstitute(substitute: Article) {
    this.replace.emit(substitute);
  }

  onAddToCart(substitute: Article) {
    this.addToCart.emit(substitute);
  }

  onSelect(selected: boolean) {
    this.entrySelect.emit(selected);
  }

  onNoteSave($event) {
    this.updateNote.emit($event);
  }

  onStatisticsCodeSave($event) {
    this.updateStatisticsCode.emit($event);
  }

  onResolveOptionSelect(action: ResolveOptionActionType) {
    this.selectResolveOption.emit(action);
  }

  getComparativePrice(articlePrice: ArticlePrice): ArticleComparativePrice | undefined {
    const entryComparativePrice = this.entry?.comparativePrice;
    if (this.config.loadAndShowArticlePriceInsteadOfEntryPrice || !entryComparativePrice) {
      return articlePrice?.comparativePrice;
    }
    if (!!entryComparativePrice.price) {
      return <ArticleComparativePrice>{ ...entryComparativePrice, ...entryComparativePrice.price };
    }
    return undefined;
  }
}
