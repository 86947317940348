<div class="mt-8 d-block return-form-wrapper">
  <h5>{{ 'orderHistory.orderReturn.reasonForReturn_title' | cxTranslate }}</h5>

  <ng-container
    *ngIf="{ returnTypesOptions: returnTypesOptions$ | async, returnReasonsOptions: returnReasonsOptions$ | async } as data"
  >
    <form [formGroup]="returnEntryForm">
      <div class="mt-5 quantity-to-return-wrapper">
        <span class="d-flex label-text">
          {{ 'orderHistory.orderReturn.quantityToReturn_heading' | cxTranslate }}
          <i
            class="d-flex align-self-center ml-2 tooltip-icon"
            nz-icon
            nzType="question-circle"
            [ngbTooltip]="'orderHistory.orderReturn.quantityToReturn_tooltip' | cxTranslate"
          ></i>
        </span>
        <div *ngFor="let returnTypeOption of data.returnTypesOptions" class="mt-2">
          <py-radio [showLabel]="true">
            <input formControlName="returnType" type="radio" [value]="returnTypeOption.value" />
            <span class="radio-title" slot="label">{{ returnTypeOption.label | cxTranslate }}</span>
          </py-radio>
        </div>
      </div>

      <div
        *ngIf="returnTypeFormControl.value === returnTypes.PARTIAL_QUANTITY"
        class="mt-5 quantity-and-unit-wrapper"
        formGroupName="quantityAndUnit"
      >
        <ng-container *ngIf="isQuantityAndUnitSelectionPossible(article, entry); else manuallyTypedQuantityTemplate">
          <span class="label-text">
            {{ 'orderHistory.orderReturn.selectQuantity_heading' | cxTranslate }}
          </span>

          <div class="mt-2 d-flex flex-column">
            <py-quantity-and-unit-selector
              *ngIf="article.units"
              [article]="article"
              [values]="{ quantity: 1, unit: article.units[0].code }"
              [initialMaximumQuantity]="
                calculateInitialMaximumQuantityInSalesUnit(article.units, entry?.unit?.code, entry?.quantity)
              "
              [initialMinimumQuantity]="1"
              [useMinimumQuantityStep]="true"
              [showUnselectableOption]="false"
              [minDisabledTooltipTextKey]="'orderHistory.orderReturn.minimumReturnQuantityIndication_tooltip'"
              (valueChanges)="updateQuantityAndUnit($event)"
            ></py-quantity-and-unit-selector>
          </div>
        </ng-container>
        <ng-template #manuallyTypedQuantityTemplate>
          <span class="d-flex label-text">
            {{ 'orderHistory.orderReturn.quantityAndUnit_heading' | cxTranslate }}
            <i
              class="d-flex align-self-center ml-2 tooltip-icon"
              nz-icon
              nzType="question-circle"
              [ngbTooltip]="'orderHistory.orderReturn.quantityAndUnit_tooltip' | cxTranslate"
            ></i>
          </span>

          <div class="mt-2 d-flex flex-column">
            <py-reactive-form-input-field
              [group]="returnEntryForm"
              [fieldName]="'quantityText'"
              [placeholder]="'orderHistory.orderReturn.quantityAndUnit_placeholder' | cxTranslate"
              [showTouchedFieldAsInvalid]="true"
            ></py-reactive-form-input-field>
          </div>
        </ng-template>
      </div>

      <div class="mt-5 return-reasons-wrapper" *ngIf="data.returnReasonsOptions?.length > 0">
        <span class="d-flex label-text">
          {{ 'orderHistory.orderReturn.reasonForReturn_heading' | cxTranslate }}
          <i
            class="d-flex align-self-center ml-2 tooltip-icon"
            nz-icon
            nzType="question-circle"
            [ngbTooltip]="'orderHistory.orderReturn.reasonForReturn_tooltip' | cxTranslate"
          ></i>
        </span>

        <py-dropdown
          class="mt-2"
          [ngClass]="{
            invalid: (returnReasonFormControl.dirty || returnReasonFormControl.touched) && returnReasonFormControl.invalid,
          }"
          formControlName="returnReason"
          [title]="'orderHistory.orderReturn.reasonForReturn_placeholder' | cxTranslate"
          [options]="data.returnReasonsOptions"
          (dropdownClose)="onDropdownClose()"
        ></py-dropdown>

        <div
          *ngIf="(returnReasonFormControl.dirty || returnReasonFormControl.touched) && returnReasonFormControl.invalid"
          class="mt-2 d-block invalid-feedback"
        >
          <span *ngIf="returnReasonFormControl.errors.required">
            {{ 'orderHistory.orderReturn.reasonForReturnMandatoryError_hint' | cxTranslate }}
          </span>
        </div>
      </div>

      <div class="mt-5 comment-wrapper">
        <span class="d-flex label-text">
          {{ 'orderHistory.orderReturn.comment_heading' | cxTranslate }}
          <i
            class="d-flex align-self-center ml-2 tooltip-icon"
            nz-icon
            nzType="question-circle"
            [ngbTooltip]="'orderHistory.orderReturn.comment_tooltip' | cxTranslate"
          ></i>
        </span>

        <textarea
          class="mt-2 pt-2 pl-4 form-control"
          [ngClass]="{ invalid: (commentFormControl.dirty || commentFormControl.touched) && commentFormControl.invalid }"
          formControlName="comment"
          [maxlength]="commentMaxLength"
          [placeholder]="'orderHistory.orderReturn.comment_placeholder' | cxTranslate"
        ></textarea>

        <div class="mt-2 d-flex">
          <div
            *ngIf="(commentFormControl.dirty || commentFormControl.touched) && commentFormControl.invalid"
            class="d-block invalid-feedback"
          >
            <span *ngIf="commentFormControl.errors.required">
              {{ 'orderHistory.orderReturn.commentMandatoryError_hint' | cxTranslate }}
            </span>
            <span *ngIf="commentFormControl.errors.minlength">
              {{ 'validation.minLength_hint' | cxTranslate: { min: commentMinLength } }}
            </span>
          </div>

          <div class="w-100 max-length text-right">{{ commentFormControl.value?.length }} / {{ commentMaxLength }}</div>
        </div>
      </div>

      <div class="my-5">
        <span class="d-flex label-text">
          {{ 'orderHistory.orderReturn.uploadedFiles_heading' | cxTranslate }}
          {{ isFilesUploadOptional ? ('common.optionalWithinParentheses_hint' | cxTranslate) : '' }}
          <i
            class="d-flex align-self-center ml-2 tooltip-icon"
            nz-icon
            nzType="question-circle"
            [ngbTooltip]="'orderHistory.orderReturn.uploadedFiles_tooltip' | cxTranslate"
          ></i>
        </span>

        <div class="mt-2 sub-text">
          {{ 'orderHistory.orderReturn.uploadedFiles_hint' | cxTranslate }}
        </div>

        <!-- Files preview -->
        <div class="d-flex flex-wrap pt-2 pb-3" *ngIf="{ uploadedFiles: uploadedFiles$ | async } as data">
          <ng-container *ngFor="let uploadedFile of data.uploadedFiles; let fileIndex = index">
            <ng-container *ngIf="uploadedFile?.thumbnail; else noUploadedThumbnailTemplate">
              <!-- Thumbnail received from backend -->
              <div class="position-relative">
                <img
                  class="mr-2 mb-2 file-preview"
                  [class.p-2]="hasPdfType(uploadedFile.filename)"
                  [pyMedia]="uploadedFile.thumbnail"
                  [alt]="uploadedFile.thumbnail.altText"
                  [ngbTooltip]="uploadedFile.filename"
                  [placement]="['right', 'bottom']"
                  (click)="openUploadedFilesGallery(data.uploadedFiles, fileIndex)"
                />

                <ng-container *ngTemplateOutlet="removeFileButtonTemplate"></ng-container>
              </div>
            </ng-container>
            <ng-template #noUploadedThumbnailTemplate>
              <ng-container *ngIf="getFilePreview(uploadedFile.qualifier) as filePreview; else placeholderTemplate">
                <!-- Thumbnail from file uploaded into browser -->
                <div class="position-relative file-wrapper">
                  <img
                    class="mr-2 mb-2 file-preview"
                    [ngClass]="{ 'p-3': !hasImageType(filePreview.file.type) }"
                    [src]="
                      hasImageType(filePreview.file.type) ? filePreview.fileUrl : ('assets/outline/no-pdf.svg' | mediaLocalAsset)
                    "
                    [alt]="filePreview.file.name"
                    [ngbTooltip]="filePreview.file.name"
                    [placement]="['right', 'bottom']"
                    (click)="openUploadedFilesGallery(data.uploadedFiles, fileIndex)"
                  />

                  <ng-container *ngTemplateOutlet="removeFileButtonTemplate"></ng-container>
                </div>
              </ng-container>

              <ng-template #placeholderTemplate>
                <!-- Placeholder for image file, for pdf file thumbnail is returned immediately -->
                <div class="position-relative file-wrapper">
                  <img
                    class="mr-2 mb-2 file-preview"
                    [src]="'assets/outline/no-image.svg' | mediaLocalAsset"
                    [alt]="uploadedFile.filename"
                    [ngbTooltip]="
                      'orderHistory.orderReturn.convertingUploadedFile_tooltip' | cxTranslate: { filename: uploadedFile.filename }
                    "
                    [placement]="['right', 'bottom']"
                    (click)="openUploadedFilesGallery(data.uploadedFiles, fileIndex)"
                  />

                  <ng-container *ngTemplateOutlet="removeFileButtonTemplate"></ng-container>
                </div>
              </ng-template>
            </ng-template>

            <ng-template #removeFileButtonTemplate>
              <button
                type="button"
                class="delete-button"
                (click)="removeFile(uploadedFile.qualifier)"
                [ngbTooltip]="'orderHistory.orderReturn.removeFile_tooltip' | cxTranslate"
                [placement]="'top'"
              >
                <i nz-icon nzType="close-circle" nzTheme="fill" class="d-flex"></i>
              </button>
            </ng-template>
          </ng-container>

          <span *ngIf="uploadingFile" class="mr-2 mb-2 loading-box d-flex align-items-center justify-content-center">
            <cx-spinner></cx-spinner>
          </span>

          <span
            class="d-inline-block"
            tabindex="0"
            [ngbTooltip]="'orderHistory.orderReturn.maximumUpload_tooltip' | cxTranslate"
            [placement]="['right', 'bottom']"
            [disableTooltip]="data.uploadedFiles?.length < maxFilesNumber"
          >
            <py-list-insert
              class="h-100 w-100 position-relative mb-2"
              [ngClass]="{
                disabled: data.uploadedFiles?.length >= maxFilesNumber,
                invalid: isFileFormControlInvalid,
              }"
              [clickable]="true"
              [iconTemplate]="uploadFileIconTemplate"
              [ngbTooltip]="'orderHistory.orderReturn.uploadFile_tooltip' | cxTranslate"
              [placement]="['right', 'bottom']"
              (click)="uploadFileInput.click()"
            >
              <input
                #uploadFileInput
                style="display: none"
                type="file"
                [accept]="'.pdf, .jpg, .jpeg, .png, .tif, .tiff'"
                (change)="onFileUpload($event)"
              />

              <div *ngIf="isFileFormControlInvalid" class="warning-alert">
                <i nz-icon nzType="warning" nzTheme="fill" class="d-flex"></i>
              </div>
            </py-list-insert>

            <div *ngIf="isFileFormControlInvalid" class="mt-2 d-block invalid-feedback">
              <span *ngIf="filesFormControl.errors.required">
                {{ 'orderHistory.orderReturn.uploadFileMandatoryError_hint' | cxTranslate }}
              </span>
            </div>
          </span>
        </div>
      </div>
    </form>
  </ng-container>
</div>

<ng-template #uploadFileIconTemplate>
  <i nz-icon nzType="plus" class="list-insert-icon"></i>
</ng-template>
