import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  HostBinding,
  HostListener,
  Inject,
  inject,
  OnDestroy,
  OnInit,
  Optional,
  PLATFORM_ID,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { IconDefinition } from '@ant-design/icons-angular';
import { AuthService, EventService, LoggerService, RoutingService, WindowRef } from '@spartacus/core';
import { RecaptchaComponent } from 'ng-recaptcha';
import { NzIconService } from 'ng-zorro-antd/icon';
import { BehaviorSubject, Observable, Subject, Subscription } from 'rxjs';
import { distinctUntilChanged, filter, take } from 'rxjs/operators';
import { GlobalMessageService, GlobalMessageType } from '../../core/global-message';
import { RoutingHelperService } from '../../core/routing';
import { BaseSiteService } from '../../core/site-context';
import { ExecuteRecaptchaEvent, ExperimentalFeaturesFacade, RecaptchaCompleteEvent } from '../../core/user';
import { BrandConfigService } from '../../shared';
import { HeaderResizeService } from '../../shared/services/header-resize/header-resize.service';
import { BrandConfig } from './brand-config';
import { cssFromFontFace, getFontStack, isFallbackFontFace, loadFont, scriptFromFontFace } from './font-utils';
import { BRAND_ICONS, USED_LIBRARY_ICONS } from './icons.constants';

@Component({
  selector: 'py-storefront',
  templateUrl: './storefront.component.html',
  styleUrls: ['./storefront.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StorefrontComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('currentCustomerBannerElement') currentCustomerBannerElement: ElementRef<HTMLElement>;
  @HostBinding('class.zoomed-out') isZoomedOut = false;
  @ViewChild('captchaRef') captchaRef: RecaptchaComponent;

  private subscriptions = new Subscription();
  private get brandConfig(): BrandConfig {
    return this.brandConfigService.getBrandConfig();
  }

  isUserLoggedIn$: Observable<boolean>;
  hasDebugMenu$: Observable<boolean>;
  searchBoxVisible$: Observable<boolean>;
  modalClosed$ = new Subject<string>();
  captchaPublicKey$ = new BehaviorSubject<string>(undefined);

  protected logger = inject(LoggerService);

  constructor(
    private renderer: Renderer2,
    private elementRef: ElementRef,
    private routingService: RoutingService,
    private routingHelperService: RoutingHelperService,
    private authService: AuthService,
    private baseSiteService: BaseSiteService,
    private globalMessageService: GlobalMessageService,
    private nzIconService: NzIconService,
    protected headerResizeService: HeaderResizeService,
    private experimentalFeaturesService: ExperimentalFeaturesFacade,
    private eventService: EventService,
    private winRef: WindowRef,
    protected brandConfigService: BrandConfigService,
    @Inject(PLATFORM_ID) protected platform: any,
    @Optional() @Inject(BRAND_ICONS) protected brandIcons: IconDefinition[]
  ) {}

  @HostListener('window:resize')
  onResize() {
    this.setIsZoomedOut();
  }

  reCaptchaComplete(recaptchaKey: string) {
    if (recaptchaKey) {
      // eslint-disable-next-line no-console
      this.logger.info('reCaptcha complete', recaptchaKey);
      this.eventService.dispatch(new RecaptchaCompleteEvent(recaptchaKey));
    }
  }

  reCaptchaError(error) {
    // eslint-disable-next-line no-console
    this.logger.info('reCaptcha error', error);
  }

  initReCaptcha() {
    this.subscriptions.add(
      this.baseSiteService
        .get()
        .pipe(filter(Boolean), take(1))
        .subscribe((baseSite) => {
          if (baseSite.captchaConfig?.enabled && !!baseSite.captchaConfig?.publicKey) {
            this.captchaPublicKey$.next(baseSite.captchaConfig.publicKey);
          }
        })
    );

    this.subscriptions.add(
      this.eventService.get(ExecuteRecaptchaEvent).subscribe(() => {
        if (this.captchaRef) {
          // eslint-disable-next-line no-console
          this.logger.info('waiting for reCaptcha', this.captchaRef);
          this.captchaRef.reset();
          this.captchaRef.execute();
        } else {
          // Captcha is disabled; complete the request directly
          this.eventService.dispatch(new RecaptchaCompleteEvent(undefined));
        }
      })
    );
  }

  ngAfterViewInit(): void {
    if (isPlatformBrowser(this.platform)) {
      this.initReCaptcha();
      this.handleDisplayingOnFallbackBaseSite();
      this.subscriptions.add(
        this.isUserLoggedIn$.pipe(filter(Boolean), take(1)).subscribe(() => {
          this.headerResizeService.observe(this.currentCustomerBannerElement?.nativeElement);
        })
      );
      this.subscriptions.add(
        this.routingService
          .isNavigating()
          .pipe(distinctUntilChanged())
          .subscribe((isNavigating) => {
            if (isNavigating) {
              this.renderer.addClass(this.elementRef.nativeElement, 'navigating');
            } else {
              this.renderer.removeClass(this.elementRef.nativeElement, 'navigating');
            }
          })
      );
    }
  }

  setIsZoomedOut(): void {
    if (isPlatformBrowser(this.platform)) {
      if (window.devicePixelRatio < 1) {
        this.isZoomedOut = true;
      } else {
        this.isZoomedOut = false;
      }
    }
  }

  private loadFonts(): void {
    const fontStack = getFontStack(this.brandConfig.fonts);

    if (isPlatformBrowser(this.platform)) {
      // load font stack in browser (if not SSR rendered already)
      if (!this.winRef.document.getElementById('brand-fonts')) {
        fontStack.forEach((font) => {
          loadFont(font, this.winRef.document);
        });
      }
    }

    if (isPlatformServer(this.platform)) {
      // add non-fallback fonts style to <head> for SSR rendering
      const fontStyle: HTMLStyleElement = this.renderer.createElement('style');
      fontStyle.id = 'brand-fonts';
      fontStyle.textContent = fontStack
        .filter((font) => !isFallbackFontFace(font))
        .map((font) => cssFromFontFace(font))
        .join('\n');
      this.winRef.document.head.appendChild(fontStyle);

      // add script to load fallback fonts while application is loading
      const fontScript: HTMLScriptElement = this.renderer.createElement('script');
      fontScript.id = 'brand-fonts-load';
      fontScript.textContent = fontStack
        .filter((font) => isFallbackFontFace(font))
        .map((font) => scriptFromFontFace(font))
        .join('\n');
      this.winRef.document.head.appendChild(fontScript);
    }
  }

  private loadIcons(): void {
    if (!isPlatformBrowser(this.platform)) {
      // Loading library icons statically to fix the issue with icons not being rendered in SSR
      this.nzIconService.addIcon(...USED_LIBRARY_ICONS);
      if (this.brandIcons) {
        this.nzIconService.addIcon(...this.brandIcons);
      }
    }
    if (this.brandConfig.iconPathPrefix) {
      this.nzIconService.changeAssetsSource(this.brandConfig.iconPathPrefix);
    }
  }

  ngOnInit() {
    this.loadFonts();
    this.loadIcons();

    if (isPlatformBrowser(this.platform)) {
      //If we put setIsZoomedOut in the ngAfterViewInit method we get a ExpressionChangedAfterItHasBeenCheckedError
      this.setIsZoomedOut();

      this.searchBoxVisible$ = this.routingHelperService.searchBoxVisible();
      this.isUserLoggedIn$ = this.authService.isUserLoggedIn();
      this.hasDebugMenu$ = this.experimentalFeaturesService.hasDebugMenu();
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
    this.headerResizeService.unobserve(this.currentCustomerBannerElement?.nativeElement);
  }

  private handleDisplayingOnFallbackBaseSite() {
    if (this.baseSiteService.isFallback()) {
      this.globalMessageService.add(
        { key: 'errors.pageNotFoundRedirectedToLandingPage_message' },
        GlobalMessageType.MSG_TYPE_INFO
      );
      this.baseSiteService.setIsFallback(false);
    }
  }
}
