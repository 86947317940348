import { Action } from '@ngrx/store';
import { PROCESS_FEATURE, StateUtils } from '@spartacus/core';
import {
  ActionTokenPayload,
  AutoResolveChange,
  Cart,
  CartSimulation,
  HttpErrorModel,
  OpeningSchedule,
  Order,
  OrderEntry,
  OrderMergeProposal,
  ResolveOptionActionType,
} from '../../../../../core/model';
import { PyStateUtils } from '../../../../../core/state/utils';
import { ProcessIdBuilder } from '../../../../../core/user/store/util/process-id-builder';
import {
  CHECKOUT_ACTIVE_ORDER_PROCESS,
  CHECKOUT_HOLIDAY,
  LastFullPalletUpgradeEntry,
  PLACE_ORDER_PROCESS,
  SET_RESOLVE_OPTION_PROCESS,
  SIMULATE_CART_PROCESS,
} from '../checkout-state';
import { CheckoutStepsEntityAction, PayloadWithCheckoutKey } from './checkout-steps-entity.action';

export enum CheckoutActionTypes {
  SetOrderReviewed = '[Checkout] Set order reviewed',

  SetCartId = '[Checkout] Set cart id',

  PlaceOrder = '[Checkout] Place order',
  PlaceOrderFail = '[Checkout] Place order fail',
  PlaceOrderSuccess = '[Checkout] Place order success',
  ResetPlaceOrder = '[Checkout] Reset place order',

  SimulateCart = '[Checkout] Simulate cart',
  SimulateCartFail = '[Checkout] Simulate cart fail',
  SimulateCartSuccess = '[Checkout] Simulate cart success',
  ResetSimulateCart = '[Checkout] Reset simulate cart',

  SetResolveOption = '[Checkout] Set resolve option',
  SetResolveOptionFail = '[Checkout] Set resolve option fail',
  SetResolveOptionSuccess = '[Checkout] Set resolve option success',
  ResetSetResolveOption = '[Checkout] Reset set resolve option',

  LoadHolidays = '[Checkout] Load holidays',
  LoadHolidaysFail = '[Checkout] Load holidays fail',
  LoadHolidaysSuccess = '[Checkout] Load holidays success',

  LoadActiveOrderProcess = '[Checkout] Load active order process',
  LoadActiveOrderProcessFail = '[Checkout] Load active order process fail',
  LoadActiveOrderProcessSuccess = '[Checkout] Load active order process success',

  CancelActiveOrderProcess = '[Checkout] Cancel active order process',
  CancelActiveOrderProcessFail = '[Checkout] Cancel active order process fail',
  CancelActiveOrderProcessSuccess = '[Checkout] Cancel active order process success',

  ClearActiveOrderProcess = '[Checkout] Clear active order process',

  ResumeActiveOrderProcess = '[Checkout] Resume active order process',
  ResumeActiveOrderProcessFail = '[Checkout] Resume active order process fail',
  ResumeActiveOrderProcessSuccess = '[Checkout] Resume active order process success',

  LastFullPalletUpgrade = '[Checkout] Add last full pallet upgrade entry',
  ResetLastFullPalletUpgrade = '[Checkout] Reset last full pallet upgrade entry',

  UpdateTaxCode = '[Checkout] Update tax code',
  UpdateTaxCodeFail = '[Checkout] Update tax code fail',
  UpdateTaxCodeSuccess = '[Checkout] Update tax code success',

  ClearSteps = '[Checkout] Clear steps',

  CriticalError = '[Checkout] Critical error',
}

interface SetCartIdPayload extends PayloadWithCheckoutKey {
  userId: string;
  cartId: string;
}

interface PlaceOrderPayload extends PayloadWithCheckoutKey {
  userId: string;
  orderToMergeWith?: OrderMergeProposal;
}

interface PlaceOrderFailPayload extends PlaceOrderPayload {
  error?: HttpErrorModel;
}

interface PlaceOrderSuccessPayload extends PlaceOrderPayload {
  order: Order;
}

interface SimulateCartPayload extends PayloadWithCheckoutKey {
  userId: string;
  resetRequestedDeliveryDate?: boolean;
}

interface SimulateCartFailPayload extends SimulateCartPayload {
  error?: HttpErrorModel;
}

interface SimulateCartSuccessPayload extends SimulateCartPayload {
  cartSimulation: CartSimulation;
  cart?: Cart;
}

interface SetResolveOptionPayload extends PayloadWithCheckoutKey {
  userId: string;
  entry: OrderEntry;
  type: ResolveOptionActionType;
  autoResolveChange: AutoResolveChange;
}

interface SetResolveOptionFailPayload extends SetResolveOptionPayload {
  error?: HttpErrorModel;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface SetResolveOptionSuccessPayload extends SetResolveOptionPayload {}

interface LoadHolidaysPayload {
  userId: string;
}

interface SetOrderReviewedPayload extends PayloadWithCheckoutKey {
  isReviewed?: boolean;
}

export interface LastFullPalletUpgradeEntryPayload extends LastFullPalletUpgradeEntry, PayloadWithCheckoutKey {}
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ResetLastFullPalletUpgradeEntryPayload extends PayloadWithCheckoutKey {}

interface LoadActiveOrderProcessPayload {
  userId: string;
}

interface LoadActiveOrderProcessFailPayload extends LoadActiveOrderProcessPayload {
  error?: HttpErrorModel;
}

interface LoadActiveOrderProcessSuccessPayload extends LoadActiveOrderProcessPayload {
  order?: Order;
}

interface CancelActiveOrderProcessPayloadBase {
  userId: string;
  orderCode?: string;
}

interface CancelActiveOrderProcessPayload extends CancelActiveOrderProcessPayloadBase, ActionTokenPayload<boolean> {}

interface CancelActiveOrderProcessFailPayload extends CancelActiveOrderProcessPayloadBase {
  error?: HttpErrorModel;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface CancelActiveOrderProcessSuccessPayload extends CancelActiveOrderProcessPayloadBase {}

interface ResumeActiveOrderProcessPayload extends ActionTokenPayload<boolean> {
  userId: string;
}

interface ResumeActiveOrderProcessFailPayload extends CancelActiveOrderProcessPayloadBase {
  error?: HttpErrorModel;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface ResumeActiveOrderProcessSuccessPayload extends CancelActiveOrderProcessPayloadBase {}

interface CriticalErrorPayload extends PayloadWithCheckoutKey {
  userId: string;
}

interface UpdateTaxCodePayloadBase {
  cartId: string;
  userId: string;
  taxCode: string;
}

interface UpdateTaxCodePayload extends UpdateTaxCodePayloadBase, ActionTokenPayload<boolean> {}

interface UpdateTaxCodeFailPayload extends UpdateTaxCodePayloadBase {
  error?: HttpErrorModel;
}

interface UpdateTaxCodeSuccessPayload extends UpdateTaxCodePayloadBase {}

export class SetCartId implements CheckoutStepsEntityAction {
  readonly type = CheckoutActionTypes.SetCartId;

  constructor(public payload: SetCartIdPayload) {}
}

export class PlaceOrder extends StateUtils.EntityLoadAction implements CheckoutStepsEntityAction {
  readonly type = CheckoutActionTypes.PlaceOrder;

  constructor(public payload: PlaceOrderPayload) {
    super(PROCESS_FEATURE, ProcessIdBuilder.generateId(PLACE_ORDER_PROCESS, payload.key));
  }
}

export class PlaceOrderFail extends PyStateUtils.EntityFailAction implements CheckoutStepsEntityAction {
  readonly type = CheckoutActionTypes.PlaceOrderFail;

  constructor(public payload: PlaceOrderFailPayload) {
    super(PROCESS_FEATURE, ProcessIdBuilder.generateId(PLACE_ORDER_PROCESS, payload.key), payload.error);
  }
}

export class PlaceOrderSuccess extends StateUtils.EntitySuccessAction implements CheckoutStepsEntityAction {
  readonly type = CheckoutActionTypes.PlaceOrderSuccess;

  constructor(public payload: PlaceOrderSuccessPayload) {
    super(PROCESS_FEATURE, ProcessIdBuilder.generateId(PLACE_ORDER_PROCESS, payload.key));
  }
}

export class ResetPlaceOrder extends StateUtils.EntityLoaderResetAction implements CheckoutStepsEntityAction {
  readonly type = CheckoutActionTypes.ResetPlaceOrder;

  constructor(public payload: PayloadWithCheckoutKey) {
    super(PROCESS_FEATURE, ProcessIdBuilder.generateId(PLACE_ORDER_PROCESS, payload.key));
  }
}

export class SimulateCart extends StateUtils.EntityLoadAction implements CheckoutStepsEntityAction {
  readonly type = CheckoutActionTypes.SimulateCart;

  constructor(public payload: SimulateCartPayload) {
    super(PROCESS_FEATURE, SIMULATE_CART_PROCESS);
  }
}

export class SimulateCartFail extends PyStateUtils.EntityFailAction implements CheckoutStepsEntityAction {
  readonly type = CheckoutActionTypes.SimulateCartFail;

  constructor(public payload: SimulateCartFailPayload) {
    super(PROCESS_FEATURE, SIMULATE_CART_PROCESS, payload.error);
  }
}

export class SimulateCartSuccess extends StateUtils.EntitySuccessAction implements CheckoutStepsEntityAction {
  readonly type = CheckoutActionTypes.SimulateCartSuccess;

  constructor(public payload: SimulateCartSuccessPayload) {
    super(PROCESS_FEATURE, SIMULATE_CART_PROCESS);
  }
}

export class ResetSimulateCart extends StateUtils.EntityLoaderResetAction {
  readonly type = CheckoutActionTypes.ResetSimulateCart;

  constructor() {
    super(PROCESS_FEATURE, SIMULATE_CART_PROCESS);
  }
}

export class SetResolveOption extends StateUtils.EntityLoadAction implements CheckoutStepsEntityAction {
  readonly type = CheckoutActionTypes.SetResolveOption;

  constructor(public payload: SetResolveOptionPayload) {
    super(PROCESS_FEATURE, SET_RESOLVE_OPTION_PROCESS);
  }
}

export class SetResolveOptionFail extends PyStateUtils.EntityFailAction implements CheckoutStepsEntityAction {
  readonly type = CheckoutActionTypes.SetResolveOptionFail;

  constructor(public payload: SetResolveOptionFailPayload) {
    super(PROCESS_FEATURE, SET_RESOLVE_OPTION_PROCESS, payload.error);
  }
}

export class SetResolveOptionSuccess extends StateUtils.EntitySuccessAction implements CheckoutStepsEntityAction {
  readonly type = CheckoutActionTypes.SetResolveOptionSuccess;

  constructor(public payload: SetResolveOptionSuccessPayload) {
    super(PROCESS_FEATURE, SET_RESOLVE_OPTION_PROCESS);
  }
}

export class ResetSetResolveOption extends StateUtils.EntityLoaderResetAction {
  readonly type = CheckoutActionTypes.ResetSetResolveOption;

  constructor() {
    super(PROCESS_FEATURE, SET_RESOLVE_OPTION_PROCESS);
  }
}

export class LoadHolidays extends StateUtils.LoaderLoadAction {
  readonly type = CheckoutActionTypes.LoadHolidays;

  constructor(public payload: LoadHolidaysPayload) {
    super(CHECKOUT_HOLIDAY);
  }
}

export class LoadHolidaysFail extends PyStateUtils.LoaderFailAction {
  readonly type = CheckoutActionTypes.LoadHolidaysFail;

  constructor(public payload?: any) {
    super(CHECKOUT_HOLIDAY, payload);
  }
}

export class LoadHolidaysSuccess extends StateUtils.LoaderSuccessAction {
  readonly type = CheckoutActionTypes.LoadHolidaysSuccess;

  constructor(public payload: OpeningSchedule) {
    super(CHECKOUT_HOLIDAY);
  }
}

export class LoadActiveOrderProcess extends StateUtils.LoaderLoadAction {
  readonly type = CheckoutActionTypes.LoadActiveOrderProcess;

  constructor(public payload: LoadActiveOrderProcessPayload) {
    super(CHECKOUT_ACTIVE_ORDER_PROCESS);
  }
}

export class LoadActiveOrderProcessFail extends PyStateUtils.LoaderFailAction {
  readonly type = CheckoutActionTypes.LoadActiveOrderProcessFail;

  constructor(public payload: LoadActiveOrderProcessFailPayload) {
    super(CHECKOUT_ACTIVE_ORDER_PROCESS, payload.error);
  }
}

export class LoadActiveOrderProcessSuccess extends StateUtils.LoaderSuccessAction {
  readonly type = CheckoutActionTypes.LoadActiveOrderProcessSuccess;

  constructor(public payload: LoadActiveOrderProcessSuccessPayload) {
    super(CHECKOUT_ACTIVE_ORDER_PROCESS);
  }
}

export class ResumeActiveOrderProcess implements Action {
  readonly type = CheckoutActionTypes.ResumeActiveOrderProcess;

  constructor(public payload: ResumeActiveOrderProcessPayload) {}
}

export class ResumeActiveOrderProcessFail implements Action {
  readonly type = CheckoutActionTypes.ResumeActiveOrderProcessFail;

  constructor(public payload: ResumeActiveOrderProcessFailPayload) {}
}

export class ResumeActiveOrderProcessSuccess implements Action {
  readonly type = CheckoutActionTypes.ResumeActiveOrderProcessSuccess;

  constructor(public payload: ResumeActiveOrderProcessSuccessPayload) {}
}

export class CancelActiveOrderProcess implements Action {
  readonly type = CheckoutActionTypes.CancelActiveOrderProcess;

  constructor(public payload: CancelActiveOrderProcessPayload) {}
}

export class CancelActiveOrderProcessFail implements Action {
  readonly type = CheckoutActionTypes.CancelActiveOrderProcessFail;

  constructor(public payload: CancelActiveOrderProcessFailPayload) {}
}

export class CancelActiveOrderProcessSuccess implements Action {
  readonly type = CheckoutActionTypes.CancelActiveOrderProcessSuccess;

  constructor(public payload: CancelActiveOrderProcessSuccessPayload) {}
}

export class ClearActiveOrderProcess implements Action {
  readonly type = CheckoutActionTypes.ClearActiveOrderProcess;
}

export class CriticalError implements CheckoutStepsEntityAction {
  readonly type = CheckoutActionTypes.CriticalError;

  constructor(public payload: CriticalErrorPayload) {}
}

export class ClearSteps implements CheckoutStepsEntityAction {
  readonly type = CheckoutActionTypes.ClearSteps;

  constructor(public payload: PayloadWithCheckoutKey) {}
}

export class SetOrderReviewed implements CheckoutStepsEntityAction {
  readonly type = CheckoutActionTypes.SetOrderReviewed;

  constructor(public payload: SetOrderReviewedPayload) {}
}

export class LastFullPalletUpgrade implements CheckoutStepsEntityAction {
  readonly type = CheckoutActionTypes.LastFullPalletUpgrade;

  constructor(public payload: LastFullPalletUpgradeEntryPayload) {}
}

export class ResetLastFullPalletUpgrade implements CheckoutStepsEntityAction {
  readonly type = CheckoutActionTypes.ResetLastFullPalletUpgrade;

  constructor(public payload: ResetLastFullPalletUpgradeEntryPayload) {}
}

export class UpdateTaxCode implements Action {
  readonly type = CheckoutActionTypes.UpdateTaxCode;

  constructor(public payload: UpdateTaxCodePayload) {}
}

export class UpdateTaxCodeFail implements Action {
  readonly type = CheckoutActionTypes.UpdateTaxCode;

  constructor(public payload: UpdateTaxCodeFailPayload) {}
}

export class UpdateTaxCodeSuccess implements Action {
  readonly type = CheckoutActionTypes.UpdateTaxCode;

  constructor(public payload: UpdateTaxCodeSuccessPayload) {}
}

export type CheckoutActions =
  | SetCartId
  | PlaceOrder
  | PlaceOrderFail
  | PlaceOrderSuccess
  | ResetPlaceOrder
  | SimulateCart
  | SimulateCartFail
  | SimulateCartSuccess
  | ResetSimulateCart
  | SetResolveOption
  | SetResolveOptionFail
  | SetResolveOptionSuccess
  | ResetSetResolveOption
  | LoadHolidays
  | LoadHolidaysFail
  | LoadHolidaysSuccess
  | LoadActiveOrderProcess
  | LoadActiveOrderProcessFail
  | LoadActiveOrderProcessSuccess
  | CancelActiveOrderProcess
  | CancelActiveOrderProcessFail
  | CancelActiveOrderProcessSuccess
  | ClearSteps
  | LastFullPalletUpgrade
  | ResetLastFullPalletUpgrade
  | UpdateTaxCode
  | UpdateTaxCodeFail
  | UpdateTaxCodeSuccess;
