import { ChangeDetectorRef } from '@angular/core';
import { AbstractControl, AsyncValidatorFn, ValidationErrors } from '@angular/forms';
import { Observable, from } from 'rxjs';
import { tap } from 'rxjs/operators';

export function validatorMarkForCheck(validator: AsyncValidatorFn, changeDetectorRef: ChangeDetectorRef): AsyncValidatorFn {
  return (control: AbstractControl): Observable<ValidationErrors | null> => {
    return from(validator(control)).pipe(tap(() => changeDetectorRef?.markForCheck()));
  };
}
