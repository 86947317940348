import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { KeyboardFocusModule } from '@spartacus/storefront';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { I18nModule } from '../../../../core/i18n';
import { LaunchDialogService } from '../../../../core/modal';
import { PipesModule } from '../../../pipes';
import { ActionButtonModule } from '../../action-button';
import { ModalModule } from '../../modal';
import { SpinnerModule } from '../../spinner';
import { soldToSelectorModalLayoutConfig } from './sold-to-selector-modal-layout.config';
import { SoldToSelectorModalComponent } from './sold-to-selector-modal.component';

@NgModule({
  imports: [
    CommonModule,
    I18nModule,
    PipesModule,
    ModalModule,
    InfiniteScrollModule,
    KeyboardFocusModule,
    SpinnerModule,
    ReactiveFormsModule,
    ActionButtonModule,
  ],
  declarations: [SoldToSelectorModalComponent],
  exports: [SoldToSelectorModalComponent],
})
export class SoldToSelectorModalModule {
  constructor(private launchDialogService: LaunchDialogService) {
    this.launchDialogService.registerModalLayoutConfig(soldToSelectorModalLayoutConfig);
  }
}
