import { Component, EventEmitter, HostBinding, Input, OnInit, Output, TemplateRef } from '@angular/core';
import { BehaviorSubject, Observable, combineLatest, concat } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, shareReplay, switchMap, take } from 'rxjs/operators';
import { ArticleService } from '../../../core/catalog';
import {
  Article,
  ArticleStatus,
  ArticleStockInfo,
  ConsignmentArticleStock,
  LoaderError,
  StockInfoDetails,
  StockStatus,
  Unit,
  WarehouseStock,
  WarehouseStockInfo,
} from '../../../core/model';
import { AssortmentFacade, StockInfoFacade } from '../../../core/user';
import { allWarehousesHaveZeroStock } from '../../utils/stock-utils';

@Component({
  selector: 'py-detailed-stock-info',
  templateUrl: './detailed-stock-info.component.html',
  styleUrls: ['./detailed-stock-info.component.scss'],
})
export class DetailedStockInfoComponent implements OnInit {
  private valueChanges$: Observable<[Article, number, string]>;
  private quantity$ = new BehaviorSubject<number>(0);
  private unitCode$ = new BehaviorSubject<string>('');
  private showConsignmentStockLevel$ = new BehaviorSubject<boolean>(false);
  private article$ = new BehaviorSubject<Article | undefined>(undefined);

  @Output() articleOutOfStockChange = new EventEmitter<{ status: boolean; outOfStockOnWebshop: boolean }>();

  @Input() set article(article: Article) {
    this.article$.next(article);
  }

  @Input() set quantity(quantity: number) {
    this.quantity$.next(quantity);
  }

  @Input() set unitCode(unitCode: string) {
    this.unitCode$.next(unitCode);
  }

  @Input() cssClasses: string;

  @Input() showStockInfoButton = true;

  @Input() showDiscontinued = false;

  @Input() forceShowLoading = false;

  get quantity(): number {
    return this.quantity$.getValue();
  }

  get unitCode(): string {
    return this.unitCode$.getValue();
  }

  @Input() small: boolean;

  get article(): Article {
    return this.article$.getValue();
  }

  get isArticleDiscontinued(): boolean {
    return this.articleService.isArticleDiscontinued(this.article);
  }

  @Input() set showConsignmentStockLevel(showConsignmentStockLevel: boolean) {
    this.showConsignmentStockLevel$.next(showConsignmentStockLevel);
  }

  get showConsignmentStockLevel(): boolean {
    return this.showConsignmentStockLevel$.getValue();
  }

  @HostBinding('class.secondary-styling')
  @Input()
  enableSecondaryArticleRowVariant: boolean = false;

  @Input() similarArticlesTemplate: TemplateRef<any>;

  stockStatus = StockStatus;
  status$: Observable<StockStatus>;
  showDetailed = false;

  @Input() set expandedByDefault(expanded: boolean) {
    this.showDetailed = !!expanded;
  }

  // Default detailed stock info
  stockInfo$: Observable<ArticleStockInfo>;
  loadingStockInfo$: Observable<boolean>;
  stockInfoError$: Observable<LoaderError>;

  // Exteded detailed stock info
  stockInfoDetails$: Observable<StockInfoDetails>;
  loadingStockInfoDetails$: Observable<boolean>;
  stockInfoDetailsError$: Observable<LoaderError>;

  // Consignment stock article stock info
  consignmentStockArticleStockInfo$: Observable<ConsignmentArticleStock>;
  loadingConsignmentStockArticleStockInfo$: Observable<boolean>;
  consignmentStockArticleStockInfoSuccess$: Observable<boolean>;
  consignmentStockArticleStockInfoError$: Observable<LoaderError>;

  unit: Unit;

  constructor(
    private stockInfoService: StockInfoFacade,
    private assortmentService: AssortmentFacade,
    private articleService: ArticleService
  ) {}

  ngOnInit() {
    const quantityAndUnitCode$ = combineLatest([
      this.quantity$.pipe(distinctUntilChanged((prevVal, currVal) => prevVal === currVal)),
      this.unitCode$.pipe(distinctUntilChanged((prevVal, currVal) => prevVal === currVal)),
    ] as [Observable<number>, Observable<string>]);

    // Debouncing all subsequent changes of quantity$ and unitCode$, to avoid unnecessary emission when change of unit entails quantity change
    // e.g. when changing simultaneously from 1 KAR to 250 ST, we skip unnecessary mid-state emission of "[1, "ST"]"
    const debouncedQuantityAndUnitCode$ = concat(quantityAndUnitCode$.pipe(take(1)), quantityAndUnitCode$.pipe(debounceTime(10)));

    this.valueChanges$ = combineLatest([this.article$, debouncedQuantityAndUnitCode$]).pipe(
      filter(([article, [quantity, unitCode]]) => !!article && !!quantity && !!unitCode),
      map(([article, [quantity, unitCode]]) => [article, quantity, unitCode] as [Article, number, string]),
      shareReplay({ bufferSize: 1, refCount: true })
    );

    this.initializeDefaultDetailedStockInfoObservables();
    this.initializeExtendedDetailedStockInfoObservables();
    this.initializeConsignmentStockArticlesStockInfoObservables();

    this.status$ = combineLatest([this.article$, this.stockInfo$]).pipe(
      filter(([article, stockInfo]) => !!article && !!stockInfo),
      map(([article, stockInfo]) => {
        if (article.salesBlocked) {
          return StockStatus.SalesBlocked;
        }

        if (allWarehousesHaveZeroStock(stockInfo)) {
          if ([ArticleStatus.ZA, ArticleStatus.ZT].includes(article.articleStatus)) {
            this.articleOutOfStockChange.emit({
              status: true,
              outOfStockOnWebshop: true,
            });
            return StockStatus.OutOfStockOnWebshop;
          } else {
            this.articleOutOfStockChange.emit({
              status: true,
              outOfStockOnWebshop: false,
            });
            return StockStatus.OutOfStock;
          }
        }

        this.articleOutOfStockChange.emit({ status: false, outOfStockOnWebshop: false });
        const unit = this.getUnitFromArticleByUnitCode(this.article, this.unitCode);

        if (stockInfo.stockInfos[0]?.unit?.code === unit?.code && stockInfo.stockInfos[0]?.quantity >= this.quantity) {
          return StockStatus.InStock;
        }

        if (stockInfo.stockInfos[0]?.quantity >= this.quantity * unit?.inEcommerceUnit) {
          return StockStatus.InStock;
        }

        return StockStatus.LowInStock;
      }),
      shareReplay({ bufferSize: 1, refCount: true })
    );
  }

  initializeDefaultDetailedStockInfoObservables(): void {
    this.stockInfo$ = this.valueChanges$.pipe(
      filter(([article, _quantity, _unitCode]) => !this.articleService.isArticleDiscontinued(article)),
      switchMap(([article, quantity, unitCode]) => {
        this.unit = this.getUnitFromArticleByUnitCode(article, unitCode);

        return this.stockInfoService.getStockInfo(article.code, quantity, this.unit);
        //return this.stockInfoService.getStockInfo(article.code, unit ? unit.inEcommerceUnit * quantity : quantity, article.unit);
      }),
      shareReplay({ bufferSize: 1, refCount: true })
    );

    this.loadingStockInfo$ = this.valueChanges$.pipe(
      switchMap(([article, quantity, unitCode]) => {
        const unit = this.getUnitFromArticleByUnitCode(article, unitCode);
        return this.stockInfoService.loadingStockInfo(article.code, quantity, unit);
        //return this.stockInfoService.loadingStockInfo(article.code, unit ? unit.inEcommerceUnit * quantity : quantity, article.unit);
      }),
      shareReplay({ bufferSize: 1, refCount: true })
    );

    this.stockInfoError$ = this.valueChanges$.pipe(
      switchMap(([article, quantity, unitCode]) => {
        const unit = this.getUnitFromArticleByUnitCode(article, unitCode);
        return this.stockInfoService.getStockInfoError(article.code, quantity, unit);
      }),
      shareReplay({ bufferSize: 1, refCount: true })
    );
  }

  initializeExtendedDetailedStockInfoObservables(): void {
    this.stockInfoDetails$ = this.valueChanges$.pipe(
      filter(([article, _quantity, _unitCode]) => !this.articleService.isArticleDiscontinued(article)),
      switchMap(([article, quantity, unitCode]) => {
        const unit = this.getUnitFromArticleByUnitCode(article, unitCode);
        return this.stockInfoService.getStockInfoDetails(article.code, quantity, unit);
      }),
      shareReplay({ bufferSize: 1, refCount: true })
    );

    this.loadingStockInfoDetails$ = this.valueChanges$.pipe(
      switchMap(([article, quantity, unitCode]) => {
        const unit = this.getUnitFromArticleByUnitCode(article, unitCode);
        return this.stockInfoService.loadingStockInfoDetails(article.code, quantity, unit);
      }),
      shareReplay({ bufferSize: 1, refCount: true })
    );

    this.stockInfoDetailsError$ = this.valueChanges$.pipe(
      switchMap(([article, quantity, unitCode]) => {
        const unit = this.getUnitFromArticleByUnitCode(article, unitCode);
        return this.stockInfoService.getStockInfoDetailsError(article.code, quantity, unit);
      }),
      shareReplay({ bufferSize: 1, refCount: true })
    );
  }

  initializeConsignmentStockArticlesStockInfoObservables(): void {
    const valueChangesForConsignmentStock$ = combineLatest([
      this.article$,
      this.showConsignmentStockLevel$.pipe(distinctUntilChanged((prevVal, currVal) => prevVal === currVal)),
    ]).pipe(
      filter(([article, showConsignmentStockLevel]) => !!article && showConsignmentStockLevel),
      shareReplay({ bufferSize: 1, refCount: true })
    );

    this.consignmentStockArticleStockInfo$ = valueChangesForConsignmentStock$.pipe(
      switchMap(([article]) => this.assortmentService.getConsignmentStockArticleStockInfo(article.code)),
      shareReplay({ bufferSize: 1, refCount: true })
    );

    this.loadingConsignmentStockArticleStockInfo$ = valueChangesForConsignmentStock$.pipe(
      switchMap(([article]) => this.assortmentService.getConsignmentStockArticleStockInfoLoading(article.code)),
      shareReplay({ bufferSize: 1, refCount: true })
    );

    this.consignmentStockArticleStockInfoSuccess$ = valueChangesForConsignmentStock$.pipe(
      switchMap(([article]) => this.assortmentService.getConsignmentStockArticleStockInfoSuccess(article.code)),
      shareReplay({ bufferSize: 1, refCount: true })
    );

    this.consignmentStockArticleStockInfoError$ = valueChangesForConsignmentStock$.pipe(
      switchMap(([article]) => this.assortmentService.getConsignmentStockArticleStockInfoError(article.code)),
      shareReplay({ bufferSize: 1, refCount: true })
    );
  }

  private getUnitFromArticleByUnitCode(article: Article, unitCode: string): Unit {
    return article?.units?.find((u) => u.code === unitCode);
  }

  getWarehouseStockStatus(warehouse: WarehouseStockInfo | WarehouseStock): StockStatus {
    if (warehouse.quantity === 0) {
      return StockStatus.OutOfStock;
    }
    const unit = this.getUnitFromArticleByUnitCode(this.article, this.unitCode);

    if (warehouse?.unit?.code === unit?.code && warehouse?.quantity >= this.quantity) {
      return StockStatus.InStock;
    }
    if (warehouse?.quantity >= this.quantity * unit?.inEcommerceUnit) {
      return StockStatus.InStock;
    }

    return StockStatus.LowInStock;
  }

  reloadStockInfo(): void {
    this.stockInfoService.loadStockInfo(this.article.code, this.quantity, this.unit);
  }
}
