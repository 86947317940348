<div class="fab-container" *ngIf="{ menuOpen: menuOpen$ | async } as data">
  <button class="fab" (click)="toggleMenu()">
    <i nz-icon nzType="setting" nzTheme="outline" [@openAndCloseIconAnimation]="data.menuOpen ? 'open' : 'closed'"></i>
    <span *ngIf="enabledFeaturesCount$ | async as enabledFeaturesCount" class="count">{{ enabledFeaturesCount }}</span>
  </button>
  @defer (when hasDebugMenu$ | async) {
    <div class="fab-menu" *ngIf="data.menuOpen">
      <py-debug-menu-item
        [icon]="'translation'"
        [debugKey]="debugKey.TRANSLATION_KEYS"
        [label]="'common.toggleTranslationKeyVisibility_label' | cxTranslate"
        [tooltip]="'common.toggleTranslationKeyVisibility_tooltip' | cxTranslate"
        [principalConfigurationCode]="'enableDisplayTranslationKeyDebug'"
      ></py-debug-menu-item>
      <py-debug-menu-item
        [icon]="'trophy'"
        [debugKey]="debugKey.SEARCH_SCORE"
        [label]="'common.debugSearchScore_label' | cxTranslate"
      ></py-debug-menu-item>
      <py-debug-menu-item
        [icon]="'trophy'"
        [debugKey]="debugKey.SEARCH_SCORE_EXPLANATION"
        [label]="'common.debugSearchScoreExplanation_hint' | cxTranslate"
        [principalConfigurationCode]="'enableSearchScoreExplanation'"
      ></py-debug-menu-item>
    </div>
  }
</div>
