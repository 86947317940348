import { ChangeDetectionStrategy, Component, ElementRef, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import {
  BehaviorSubject,
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  Observable,
  shareReplay,
  startWith,
  Subscription,
  take,
  withLatestFrom,
} from 'rxjs';
import { BaseAbstractModalComponent, LaunchDialogService } from '../../../../core/modal';
import { AvailableSoldTosSearchParams, SoldTo } from '../../../../core/model';
import { shallowEqualArrays, shallowEqualObjects } from '../../../../core/util';
import { SoldToFacade } from '../../../../features/sold-to-base';
import { infinitelyScrolling } from '../../../utils';

export interface SoldToSelectorModalData {
  soldTo: string;
  label?: string;
  type?: string;
  showActiveSoldToName?: boolean;
  changeSoldTo: (soldTo: SoldTo) => void;
  onDismiss: () => void;
}

@Component({
  selector: 'py-sold-to-selector-modal',
  templateUrl: './sold-to-selector-modal.component.html',
  styleUrls: ['./sold-to-selector-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SoldToSelectorModalComponent extends BaseAbstractModalComponent implements OnInit, OnDestroy {
  private subscriptions = new Subscription();
  soldTo$ = new BehaviorSubject<string>(undefined);
  label$ = new BehaviorSubject<string>(undefined);
  type$ = new BehaviorSubject<string>(undefined);
  showActiveSoldToName$ = new BehaviorSubject<boolean>(false);
  selectedSoldTo$ = new BehaviorSubject<SoldTo>(undefined);
  private _changeSoldTo: (soldTo: SoldTo) => void;
  private _onDismiss: () => void;

  form = new UntypedFormGroup({
    text: new UntypedFormControl(''),
  });

  availableSoldTos$: Observable<SoldTo[]>;
  availableSoldTosLoading$: Observable<boolean>;
  availableSearchResultEntitiesCount$: Observable<number>;
  currentPage$: Observable<number>;

  constructor(
    protected launchDialogService: LaunchDialogService,
    protected el: ElementRef,
    protected renderer: Renderer2,
    private soldToService: SoldToFacade
  ) {
    super(el, launchDialogService, renderer);
  }

  ngOnInit(): void {
    this.subscriptions.add(
      this.launchDialogService.data$.subscribe((data: SoldToSelectorModalData) => {
        this.soldTo$.next(data.soldTo);
        this.label$.next(data.label);
        this.type$.next(data.type);
        this.showActiveSoldToName$.next(data.showActiveSoldToName);
        this._changeSoldTo = data.changeSoldTo;
        this._onDismiss = data.onDismiss;
      })
    );

    this.availableSearchResultEntitiesCount$ = this.soldToService.getAvailableSearchResultEntitiesCount();
    this.availableSoldTosLoading$ = this.soldToService.getAvailableSoldTosLoading();

    const searchParametersForAvailableSoldTos$: Observable<AvailableSoldTosSearchParams> = this.soldToService
      .getSearchParametersForAvailableSoldTos()
      .pipe(distinctUntilChanged(shallowEqualObjects), shareReplay({ bufferSize: 1, refCount: true }));

    this.currentPage$ = searchParametersForAvailableSoldTos$.pipe(
      map((params) => params.page),
      shareReplay({ bufferSize: 1, refCount: true })
    );

    const availableSoldTos$: Observable<SoldTo[]> = this.soldToService
      .getAvailableSoldTos()
      .pipe(distinctUntilChanged(shallowEqualArrays));

    this.availableSoldTos$ = infinitelyScrolling(availableSoldTos$, this.currentPage$, 'uid').pipe(
      shareReplay({ bufferSize: 1, refCount: true })
    );

    this.subscriptions.add(
      searchParametersForAvailableSoldTos$.subscribe((searchParams) => {
        this.soldToService.searchForAvailableSoldTos(searchParams);
      })
    );

    this.subscriptions.add(
      this.form
        .get('text')
        .valueChanges.pipe(debounceTime(400), startWith(''))
        .subscribe((value: string) => {
          this.triggerSearch(value);
        })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  changeSoldTo(soldTo: SoldTo) {
    if (this._changeSoldTo) {
      this._changeSoldTo(soldTo);
    }
  }

  onDismiss(): void {
    if (this._onDismiss) {
      this._onDismiss();
    }
  }

  fetchMoreSoldTos() {
    this.soldToService
      .getAvailableSoldTosPagination()
      .pipe(
        take(1),
        withLatestFrom(this.currentPage$, this.availableSoldTosLoading$),
        filter(([pagination, currentPage, soldTosLoading]) => !soldTosLoading && currentPage + 1 < pagination.totalPages),
        map(([_pagination, currentPage]) => currentPage + 1)
      )
      .subscribe((page) => {
        this.triggerSearch(this.form.get('text').value, page);
      });
  }

  selectSoldTo(soldTo: SoldTo) {
    this.soldTo$.next(soldTo?.uid);
    this.selectedSoldTo$.next(soldTo);
  }

  private triggerSearch(text?: string, page?: number) {
    this.soldToService.updateSearchParametersForAvailableSoldTos({
      key: text || 'latest',
      text,
      count: 25,
      page: page || 0,
    });
  }
}
