import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { BehaviorSubject, combineLatest, map, Observable, shareReplay, Subscription } from 'rxjs';
import { ArticleService, ProductService } from '../../../../core/catalog';
import {
  Article,
  Category,
  DiscontinuedArticleViewType,
  EntityMap,
  Product,
  SearchTermFacetMappingHint,
  SolrResultEntityRef,
  SolrSearchResult,
  SubstituteRef,
} from '../../../../core/model';
import { PrincipalConfigurationService } from '../../../../core/user';
import { CatalogTabTypes } from '../../../../features/catalog/container/catalog/catalog-search.service';

@Component({
  selector: 'py-search-results',
  templateUrl: './search-results.component.html',
  styleUrls: ['./search-results.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchResultsComponent implements OnInit, OnDestroy {
  @Input() searchFormValue: string;
  @Input() searchHints: SearchTermFacetMappingHint[];
  @Input() discontinuedArticle: Article;
  @Input() hasDiscontinuedArticle: boolean;
  @Input() discontinuedArticleViewType: DiscontinuedArticleViewType;
  @Input() substituteRefs: SubstituteRef[] = [];
  @Input() form: UntypedFormGroup;
  @Input() enableTogglingOfCustomerAssortment: boolean;
  @Input() searchLoading: boolean;
  @Input() searchLoaded: boolean;
  @Input() substituteRefsLoading: boolean;
  @Input() substituteRefsLoaded: boolean;
  @Input() set searchResults(searchResults: SolrSearchResult) {
    this.searchResults$.next(searchResults);
  }
  @Input() searchResultsSecondaryVariant: boolean;
  @Input() catalogItemQueryParam: EntityMap<string>;
  @Input() articleResultRefs: SolrResultEntityRef[];
  @Input() products$: Observable<Product>[];
  @Input() categories: Category[];
  @Input() activeTab: CatalogTabTypes;
  @Input() initialActiveTab: CatalogTabTypes;
  @Input() minSearchLength: number;
  @Input() userInputSearchInProgress: boolean;
  @Output() discontinuedArticleSubmit = new EventEmitter<void>();
  @Output() submitSearch = new EventEmitter<void>();
  @Output() selectTab = new EventEmitter<CatalogTabTypes>();

  discontinuedArticleViewTypes: typeof DiscontinuedArticleViewType = DiscontinuedArticleViewType;
  private subscriptions = new Subscription();
  private searchResults$ = new BehaviorSubject<SolrSearchResult>(undefined);

  get searchResults(): SolrSearchResult {
    return this.searchResults$.getValue();
  }

  get searchTextLengthSufficient(): boolean {
    return this.searchFormValue?.length >= this.minSearchLength;
  }

  get areSearchResultsLoading(): boolean {
    return (this.searchLoading && !this.searchLoaded) || this.userInputSearchInProgress;
  }

  get areDiscontinuedArticleSubstitutesLoading(): boolean {
    return (this.substituteRefsLoading && !this.substituteRefsLoaded) || this.userInputSearchInProgress;
  }

  get showDiscontinuedArticleSection(): boolean {
    return (
      this.hasDiscontinuedArticle &&
      this.substituteRefs.length > 0 &&
      !this.userInputSearchInProgress &&
      this.searchTextLengthSufficient
    );
  }

  get showSearchResultsContentPrimarySection(): boolean {
    return (
      !this.searchResultsSecondaryVariant &&
      !this.hasDiscontinuedArticle &&
      !this.areSearchResultsLoading &&
      this.searchTextLengthSufficient
    );
  }

  get showLoadingSectionForPrimarySearchResults(): boolean {
    return (
      ((!this.hasDiscontinuedArticle && this.areSearchResultsLoading) ||
        (this.hasDiscontinuedArticle && this.areDiscontinuedArticleSubstitutesLoading)) &&
      !this.searchResultsSecondaryVariant &&
      this.searchTextLengthSufficient
    );
  }

  get isSearchResultsContentSecondaryLoading(): boolean {
    return (
      ((!this.hasDiscontinuedArticle && this.areSearchResultsLoading) ||
        (this.hasDiscontinuedArticle && this.areDiscontinuedArticleSubstitutesLoading)) &&
      this.searchTextLengthSufficient
    );
  }

  get showResultsForSearchResultsContentSecondary(): boolean {
    return !this.hasDiscontinuedArticle && !this.areSearchResultsLoading && this.searchTextLengthSufficient;
  }

  get showNoResultsSection(): boolean {
    return (
      ((!this.searchLoading &&
        this.searchLoaded &&
        this.searchResults?.articleResultRefs?.length === 0 &&
        this.searchResults?.productRefs?.length === 0) ||
        (!this.substituteRefsLoading &&
          this.substituteRefsLoaded &&
          this.hasDiscontinuedArticle &&
          this.substituteRefs.length === 0)) &&
      this.searchTextLengthSufficient &&
      !this.userInputSearchInProgress
    );
  }

  private enableSearchDidYouMean$ = this.principalConfigurationService.isEnabled('enableSearchDidYouMean');
  private enableSearchSuggestions$ = this.principalConfigurationService.isEnabled('enableSearchSuggestions');

  didYouMeanSuggestions$ = this.searchResults$.pipe(
    map((searchResults) =>
      searchResults?.suggestions?.spellcheckCollations?.slice?.(0, 3)?.map((suggestion) => suggestion?.value)
    ),
    shareReplay({ bufferSize: 1, refCount: true })
  );
  searchSuggestions$ = this.searchResults$.pipe(
    map((searchResults) => searchResults?.suggestions?.suggestions?.map(({ value }) => value))
  );

  showDidYouMean$ = combineLatest([this.enableSearchDidYouMean$, this.didYouMeanSuggestions$]).pipe(
    map(([enableSearchDidYouMean, didYouMeanSuggestions]) => enableSearchDidYouMean && didYouMeanSuggestions?.length > 0)
  );
  showSearchSuggestions$ = combineLatest([this.enableSearchSuggestions$, this.searchSuggestions$]).pipe(
    map(([enableSearchSuggestions, searchSuggestions]) => enableSearchSuggestions && searchSuggestions?.length > 0)
  );

  constructor(
    private articleService: ArticleService,
    private productService: ProductService,
    private principalConfigurationService: PrincipalConfigurationService
  ) {}

  ngOnInit(): void {}

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  onDiscontinuedArticleSubmit(): void {
    this.discontinuedArticleSubmit.emit();
  }

  getArticlesCodesFromSubstitutes(substituteRefs: SubstituteRef[]): string[] {
    return this.articleService.getArticlesCodesFromSubstitutes(substituteRefs);
  }

  getProductsFromSubstitutes(substituteRefs: SubstituteRef[]): Observable<Product>[] {
    return this.articleService
      .getProductsCodesFromSubstitutes(substituteRefs)
      .map((code) => this.productService.getProduct(code));
  }

  onSubmit(): void {
    this.submitSearch.emit();
  }
}
