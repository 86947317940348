import { StateUtils } from '@spartacus/core';
import { AsyncReturnToken } from '../../../../../shared';
import { Category } from '../../../../model';
import { PyStateUtils } from '../../../../state/utils';
import { CATEGORY } from '../../../store/catalog-state';

export enum CategoryActionTypes {
  LoadCategories = '[Catalog] Load categories',
  LoadCategoriesSuccess = '[Catalog] Load categories success',
  LoadCategoriesFail = '[Catalog] Load categories fail',
}

export class LoadCategories extends StateUtils.LoaderLoadAction {
  readonly type = CategoryActionTypes.LoadCategories;
  constructor(
    public userId: string,
    public key: string,
    public myAssortment?: string,
    public actionToken?: AsyncReturnToken<boolean>
  ) {
    super(CATEGORY);
  }
}

export class LoadCategoriesFail extends PyStateUtils.LoaderFailAction {
  readonly type = CategoryActionTypes.LoadCategoriesFail;

  constructor(
    public key: string,
    public payload?: any
  ) {
    super(CATEGORY, payload);
  }
}

export class LoadCategoriesSuccess extends StateUtils.LoaderSuccessAction {
  readonly type = CategoryActionTypes.LoadCategoriesSuccess;

  constructor(
    public payload: Category[],
    public key: string
  ) {
    super(CATEGORY);
  }
}

export type CategoryActions = LoadCategories | LoadCategoriesSuccess | LoadCategoriesFail;
