import { ValidationErrors } from '@angular/forms';
import { Occ } from '../occ';
import { ArticleRef } from './catalog.model';
import { Image } from './image.model';
import { AlertType, GrainDirection, GrainDirectionType, PalletFlagType, Unit } from './misc.model';
import { OrderEntry, SapOrder, SimulatedOrder } from './order.model';
import { B2BUser } from './org-unit.model';
import { Permission, PermissionResult } from './permission.model';
import { Pagination, SearchParams, Sort } from './search.model';
import { Principal } from './user.model';

export interface BuyerData extends Omit<Occ.BuyerData, 'permissions' | 'originalOrderDate'> {
  permissions?: Array<Permission>;
  originalOrderDate?: Date;
}

export interface TinyCart extends Occ.TinyCart {
  cartType?: CartType;
}

export class CartNotification {
  icon?: string;
  text: string;
}

export import CartOwnerDetails = Occ.CartOwnerDetails;

export interface Cart extends SapOrder {
  cartType?: CartType;
  name?: string;

  approvalOrderRef?: string;

  invisibleItems?: ArticleRef[];
  removedItems?: ArticleRef[];
  notifications?: Array<CartNotification>;

  saveTime?: string;
  savedBy?: Principal;
  expirationTime?: string;

  cartOwnerDetails?: CartOwnerDetails;
  cartPermissionResults?: PermissionResult[];

  prePrintedLabelEntryMixedWithNonPrePrintedEntry?: boolean;
}

export interface SimulatedCart extends Cart, SimulatedOrder {
  messages: SimulatedCartMessage[];
  hasDiscontinued?: boolean;
}

export enum CartType {
  stock = 'stock',
  sample = 'sample',
  freeStock = 'freeStock',
  freeStockFillup = 'freeStockFillup',
  invoicedStock = 'invoicedStock',
  invoicedStockFillup = 'invoicedStockFillup',
  consignmentStock = 'consignmentStock',
  consignmentStockFillup = 'consignmentStockFillup',
}

export interface CartModification extends Omit<Occ.CartModification, 'cartCode' | 'deliveryModeChanged' | 'entry'> {
  entry?: OrderEntry;
  unit?: Unit;
}

export interface CartSimulation {
  cart?: SimulatedCart;
  orderNoteSettings?: OrderNoteSettings;
  punchOutPayload?: PunchOutPayload;
  hasOrderLineErrors?: boolean;
  mixedPrePrintingCart?: boolean;
}

export interface OrderNoteSettings {
  validationRegex: string;
}

export interface PunchOutPayload extends Omit<Occ.PunchOutPayload, 'payloadContent'> {
  payloadContent?: {
    [entry: string]: Array<PunchOutEntry>;
  };
}

export interface PunchOutEntry {
  key: string;
  value: string;
}
export interface InternalNotificationMessage extends AbstractMessage {
  key?: string;
}

export interface SimulatedCartMessage extends AbstractMessage {
  type?: SimulatedCartMessageType;
}

export interface OrderLineMessage extends AbstractMessage {
  updateable: boolean;
  type?: OrderLineMessageType;
  quantity?: number;
  unitCode?: string;
}

export interface AbstractMessage {
  code?: string;
  tag?: AlertType;
  text?: string;
  identifier?: string;
  type?: string;
}

export enum SimulatedCartMessageType {
  CODE_MATERIAL_ARTICLE_BLOCKED_BY_REPLENISHMENT = 'CODE_MATERIAL_ARTICLE_BLOCKED_BY_REPLENISHMENT',
  DEFAULT = 'DEFAULT',
  ORDER_APPROVAL = 'ORDER_APPROVAL',
  PURCHASE_LIMIT = 'PURCHASE_LIMIT',
}

export enum OrderLineMessageType {
  CODE_ORDERLINE_DELIVERY_QUANTITY = 'CODE_ORDERLINE_DELIVERY_QUANTITY',
  CODE_ORDERLINE_MINIMUM_PURCHASE_QUANTITY = 'CODE_ORDERLINE_MINIMUM_PURCHASE_QUANTITY',
  DEFAULT = 'DEFAULT',
}

export interface MiniShoppingList {
  code?: string;
  name?: string;
  description?: string;
  numberOfArticles?: number;
  numberOfUnavailableArticles?: number;
  images?: Image[];
  reader?: boolean;
  writer?: boolean;
  owner?: boolean;
  numberOfConnectedPrincipals?: number;
  accessType?: ShoppingListAccessType;
}

export interface ShoppingList extends Cart {
  reader?: boolean;
  writer?: boolean;
  owner?: boolean;
  readers?: Principal[];
  writers?: Principal[];
  numberOfConnectedPrincipals?: number;
  numberOfArticles?: number;
  accessType?: ShoppingListAccessType;
  isEveryoneReads?: boolean;
  isEveryoneWrites?: boolean;
}

/**
 * CustomerSharedTemplate
 */
export interface CustomerSharedTemplate {
  customerData?: B2BUser;
  reader?: boolean;
  writer?: boolean;
}

export interface PrincipalSharedShoppingListFlags {
  principal?: Principal;
  reader?: boolean;
  writer?: boolean;
}
export interface PrincipalSharedShoppingListFlagsPage {
  /**
   * Pagination info
   */
  pagination?: Pagination;
  /**
   * Result list
   */
  results?: PrincipalSharedShoppingListFlags[];
  sorts?: Sort[];
}

export interface PrincipalSharedShoppingListSearchParams extends SearchParams {
  searchTerm?: string;
}

export interface UpdateShoppingListPermissionsParams {
  isEveryoneReads?: boolean;
  isEveryoneWrites?: boolean;
}

export interface PrincipalTemplateFlags {
  principalId: string;
  reader: boolean;
  writer: boolean;
}
export interface MiniShoppingListSearchResult {
  results?: MiniShoppingList[];
  pagination?: Pagination;
  sorts?: Sort[];
}

export interface WritableShoppingListSearchResult {
  results?: MiniShoppingList[];
  pagination?: Pagination;
  sorts?: Sort[];
}

export import ShoppingListAccessType = Occ.CartTemplateAccessTypeEnum;

export enum UpdatePermissionType {
  CONNECT = 'CONNECT',
  CONNECT_ALL = 'CONNECT_ALL',
  REMOVE = 'REMOVE',
  REMOVE_ALL = 'REMOVE_ALL',
  WRITER = 'WRITER',
  WRITER_REMOVE = 'WRITER_REMOVE',
  WRITER_ALL = 'WRITER_ALL',
  WRITER_ALL_REMOVE = 'WRITER_ALL_REMOVE',
}

export interface ShoppingListPermissions {
  permissions: ShoppingListPermission[];
}
export interface ShoppingListPermission {
  uid?: string;
  reader: boolean;
  writer: boolean;
}

export interface CuttingOptions {
  enabled: boolean;
  dustFree?: {
    enabled: boolean;
    defaultValue: boolean;
  };
  perpendicular?: {
    enabled: boolean;
    defaultValue: boolean;
  };
  grainDirection?: {
    enabled: boolean;
    options: GrainDirection[];
  };
  packaging?: {
    options: CuttingPackaging[];
    defaultOption: string;
    largeOptions: string[];
    smallOptions: string[];
    smallMaxSize: CuttingPackagingMaxSize;
  };
  format?: {
    options: CuttingFormat[];
  };
  cutting?: {
    minimumSize: number;
    minimumMargin: number;
  };
}

export interface CuttingMother {
  longSide: number;
  shortSide: number;
  grainDirection: GrainDirectionType;
}

export interface CuttingDaughter {
  longSide: number;
  shortSide: number;
  grainDirection: GrainDirectionType;
}

export interface CuttingPackaging {
  code: string;
  translationKey: string;
  defaultTranslation: string;
}

export interface CuttingPackagingMaxSize {
  shortSide: number;
  longSide: number;
}

export interface CuttingFormat {
  code: string;
  width: number;
  length: number;
}

export interface CuttingTemplate {
  code?: string;
  paperUsagePercent?: number;
  numberOfCuts?: number;
  numberOfDaughters?: number;
  totalNumberOfDaughters?: number;
}

export interface CuttingTemplateRequest {
  daughterLength: number;
  daughterWidth: number;
  dustFree: boolean;
  perpendicular: boolean;
}

export interface CuttingRequest extends CuttingTemplateRequest {
  daughterGrainDirection: GrainDirectionType;
  numberOfCuts: number;
  numberOfDaughters: number;
  packagingCode: string;
  templateCode: string;
}

export interface ReelCuttingOptions {
  enabled: boolean;
  lengthCutting: {
    enabled: boolean;
    minimumLength?: number;
  };
  widthCutting: {
    enabled: boolean;
    maxNumberOfTargetReels?: number;
    minimumMargin?: number;
    cutWidth?: number;
    lastTargetReelMinimumWidth?: number;
  };
}

export interface ReelCuttingDaughter {
  width: number;
  length: number;
  isRemainder: boolean;
}

export interface ReelCuttingRequest {
  daughterLength: number;
  daughterWidths: number[];
  remainder: boolean;
}

export interface PalletFlag {
  type: PalletFlagType;
  value: string;
}

export interface PalletFlagsRequest {
  flags: PalletFlag[];
}

export interface FullPalletUpgrade {
  fullPalletQuantity?: number;
  remainingQuantity?: number;
  remainingQuantityInCustomerSelectedUnit?: number;
  upgradable?: boolean;
}

export enum CartActionSource {
  SHOPPING_LIST,
}

export interface CartActionSourceData {
  source: CartActionSource;
  sourceId?: string;
}

export enum CartErrorContext {
  ACCOUNTING_CODE = 'accountingCode',
}

export interface CartError {
  context: CartErrorContext;
  entryId: string;
  error: ValidationErrors | null;
}
