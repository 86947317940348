import { AbstractControl, UntypedFormGroup, ValidationErrors } from '@angular/forms';
import { defer, finalize, shareReplay, Subject } from 'rxjs';

export abstract class AbstractReactiveFormInput {
  abstract getGroup(): UntypedFormGroup;

  abstract getField(): AbstractControl;

  abstract isIncludeGroupErrors(): boolean | string;

  showTouchedFieldAsInvalid?: boolean;

  /**
   * Includes errors from the group if includeGroupErrors is true
   */
  errors(): ValidationErrors {
    const fieldErrors = this.getField().errors;
    const includeGroupErrors = this.isIncludeGroupErrors();
    if (includeGroupErrors) {
      if (typeof includeGroupErrors === 'string') {
        const groupErrors = this.getGroup().errors?.[includeGroupErrors];
        return { ...(groupErrors ?? {}), ...fieldErrors };
      }
      return { ...this.getGroup().errors, ...fieldErrors };
    }
    return fieldErrors;
  }

  /**
   * Checks validity of field and group if group check enabled
   */
  isInvalid(): boolean {
    const field = this.getField();
    const errors = this.errors();
    const dirty = field.dirty || (this.showTouchedFieldAsInvalid && field.touched);
    return dirty && ((errors && Object.keys(errors || {}).length > 0) || field.invalid);
  }

  isPending(): boolean {
    const group = this.getGroup();
    const field = this.getField();
    return group?.pending || field?.pending;
  }

  isDisabled(): boolean {
    const field = this.getField();
    return field?.disabled;
  }

  controlEvents$ = defer(() => {
    const control = this.getField();
    const events$ = new Subject<'markAsDirty' | 'markAsPristine' | 'markAsTouched' | 'markAsUntouched'>();

    // save original methods
    const { markAsDirty, markAsTouched, markAsPristine, markAsUntouched } = control;

    // patch methods to emit events
    control.markAsDirty = (...args) => {
      events$.next('markAsDirty');
      markAsDirty.apply(control, args);
    };
    control.markAsPristine = (...args) => {
      events$.next('markAsPristine');
      markAsPristine.apply(control, args);
    };
    control.markAsTouched = (...args) => {
      events$.next('markAsTouched');
      markAsTouched.apply(control, args);
    };
    control.markAsUntouched = (...args) => {
      events$.next('markAsUntouched');
      markAsUntouched.apply(control, args);
    };

    return events$.pipe(
      finalize(() => {
        // restore original methods
        control.markAsDirty = markAsDirty;
        control.markAsTouched = markAsTouched;
        control.markAsPristine = markAsPristine;
        control.markAsUntouched = markAsUntouched;
      })
    );
  }).pipe(shareReplay({ bufferSize: 1, refCount: true }));
}
