import { ChangeDetectorRef, Pipe, PipeTransform } from '@angular/core';
import { ValidationErrors } from '@angular/forms';
import { TranslatePipe, TranslationService } from '../../core/i18n';
import { UserInputCheck } from '../../core/model';
import { getRegexExample } from '../../core/util';
import { RegExpRule, RegExpRules } from '../validators/regexp';

@Pipe({
  name: 'inputError',
  pure: false,
})
export class InputErrorPipe extends TranslatePipe implements PipeTransform {
  constructor(service: TranslationService, cd: ChangeDetectorRef) {
    super(service, cd);
  }

  transform(value: ValidationErrors | null): string {
    if (!value) {
      return '';
    }
    let rvalue = '';
    if (value['multiEmail']) {
      rvalue = super.transform('validation.multiEmailNotValid_hint');
    }
    if (value['singleEmail']) {
      rvalue = super.transform('registration.emailNotValid_hint');
    }
    if (value['required']) {
      rvalue = super.transform('validation.requiredValue_hint');
    }
    if (value['needToSave']) {
      rvalue = super.transform('validation.needToSaveField_hint');
    }
    if (value['postalCode']) {
      const check: UserInputCheck = value['postalCode'];
      rvalue = check.message || super.transform('validation.invalidPostalCode_hint');
      this.cd.markForCheck();
    }
    if (value['taxCode']) {
      const check: UserInputCheck = value['taxCode'];
      rvalue = check.message || super.transform('validation.invalidTaxCode_hint');
      this.cd.markForCheck();
    }
    if (value['phoneNumber']) {
      const check: UserInputCheck = value['phoneNumber'];
      rvalue = check.message || super.transform('validation.invalidPhoneNumber_hint');
      this.cd.markForCheck();
    }
    if (value['username']) {
      const check: UserInputCheck = value['username'];
      rvalue = check.message || super.transform('validation.usernameAlreadyUsed_hint');
      this.cd.markForCheck();
    }
    if (value['onlyNumbers']) {
      rvalue = super.transform('validation.invalidNumericValue_hint');
    }
    if (value['onlyNumbersDashAndSpaces']) {
      rvalue = super.transform('validation.invalidNumericValue_hint');
    }
    if (value['equalFieldValues']) {
      rvalue = super.transform('validation.valuesMustMatch_hint');
    }
    if (value['noHashes']) {
      rvalue = super.transform('validation.noHashMarks_hint');
    }
    if (value['badCredentials']) {
      rvalue = super.transform('validation.invalidCredentials_hint');
    }
    if (value['invalidCharacter'] && !value['invalidCharacter'].valid) {
      rvalue = super.transform('validation.invalidCharacter_hint', { character: value['invalidCharacter'].character });
    }
    if (value['maxlength']) {
      const maxValue = value['maxlength']['requiredLength'];
      rvalue = super.transform('validation.maxLength_hint', { max: maxValue });
    }
    if (value['minlength']) {
      const minValue = value['minlength']['requiredLength'];
      rvalue = super.transform('validation.minLength_hint', { min: minValue });
    }
    if (value[RegExpRules.AT_LEAST_ONE_LOWER_CASE.key]) {
      rvalue = super.transform('password.rules.atLeastOneLowercaseCharacter_text');
    }
    if (value[RegExpRules.AT_LEAST_ONE_UPPER_CASE.key]) {
      rvalue = super.transform('password.rules.atLeastOneUppercaseCharacter_text');
    }
    if (value[RegExpRules.AT_LEAST_ONE_DIGIT.key]) {
      rvalue = super.transform('password.rules.atLeastOneDigit_text');
    }
    if (value[RegExpRules.AT_LEAST_ONE_SPECIAL_CHAR.key]) {
      rvalue = super.transform('password.rules.atLeastOneSpecialCharacter_text');
    }
    if (value[RegExpRules.AT_LEAST_TWO_WORDS.key]) {
      rvalue = super.transform('validation.atLeastTwoWords_hint');
    }
    if (value['pattern']) {
      const check: RegExpRule = value['pattern'];

      rvalue = check?.translationKey
        ? super.transform(check.translationKey)
        : super.transform('validation.invalidPattern_hint', {
            example: getRegexExample(value['pattern']['requiredPattern']),
          });
    }
    if (value['registrationNumber']) {
      const check: UserInputCheck = value['registrationNumber'];
      rvalue = check.message || super.transform('registration.regNumberInvalid_message');
      this.cd.markForCheck();
    }
    if (value['registrationEmail']) {
      const check: UserInputCheck = value['registrationEmail'];
      rvalue = check.message || super.transform('registration.emailNotValid_hint');
      this.cd.markForCheck();
    }
    if (value['nameValidation']) {
      const check: UserInputCheck = value['nameValidation'];
      rvalue = check.message || super.transform('registration.nameNotValid_hint');
      this.cd.markForCheck();
    }
    if (value['min']) {
      const minValue = value['min']['min'];
      rvalue = super.transform('validation.minValue_hint', { min: minValue });
    }
    if (value['max']) {
      const maxValue = value['max']['max'];
      rvalue = super.transform('validation.maxValue_hint', { max: maxValue });
    }
    if (value['message']) {
      rvalue = value['message'];
    }
    if (value['notReinvoicingFieldValues']) {
      rvalue = super.transform('accountingCodes.orderLineAccountingCodesMustAllBeReinvoicing_message');
    }
    if (value['invalidAccountingCodeAssigned']) {
      rvalue = super.transform('accountingCodes.invalidAccountingCodeAssigned_message');
    }
    if (value['invalidAccountingCodeRequired']) {
      rvalue = super.transform('accountingCodes.orderLineAccountingCodesRequired_message');
    }
    if (value['password']) {
      rvalue = value.password.message;
      this.cd.markForCheck();
    }
    if (value['newPassword']) {
      rvalue = value.newPassword.message;
      this.cd.markForCheck();
    }
    return rvalue;
  }
}
