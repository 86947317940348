import { Injectable } from '@angular/core';
import { facadeFactory } from '@spartacus/core';
import { Observable } from 'rxjs';
import {
  Article,
  Cart,
  CartActionSourceData,
  CartType,
  CuttingRequest,
  OrderEntry,
  PalletFlagsRequest,
  ReelCuttingRequest,
  Unit,
} from '../../../../core/model';
import { CART_CORE_FEATURE } from '../store/cart-state';

@Injectable({
  providedIn: 'root',
  useFactory: () =>
    facadeFactory({
      facade: ActiveCartFacade,
      feature: CART_CORE_FEATURE,
      async: true,
      methods: [
        'getActiveCart',
        'getActiveCartId',
        'getEntries',
        'getEntryById',
        'getLoading',
        'isStable',
        'addEntry',
        'addA4SampleEntry',
        'quickAddEntry',
        'addEntries',
        'removeEntry',
        'removeEntryByNumber',
        'removeEntryById',
        'removeAllEntries',
        'removeEntries',
        'updateEntry',
        'updateEntryNote',
        'updateNameMarking',
        'updateEntryStatisticsCode',
        'replaceEntry',
        'addEntryFullPalletNote',
        'removeEntryFullPalletNote',
        'addEntryCutting',
        'removeEntryCutting',
        'addEntryReelCutting',
        'removeEntryReelCutting',
        'addEntryReaming',
        'removeEntryReaming',
        'updateEntryPalletFlags',
        'getLastEntry',
        'isMaxOrderLinesExceeded',
        'reloadActiveCart',
      ],
    }),
})
export abstract class ActiveCartFacade {
  abstract getActiveCart(cartType: CartType, isViewCart?: boolean): Observable<Cart | undefined>;
  abstract getActiveCartId(cartType: CartType): Observable<string>;
  abstract getEntries(cartType: CartType): Observable<OrderEntry[]>;
  abstract getEntryById(entryId: string, cartType: CartType): Observable<OrderEntry | undefined>;
  abstract getLoading(cartType: CartType): Observable<boolean>;
  abstract isStable(cartType: CartType): Observable<boolean>;
  abstract addEntry(
    articleCode: string,
    quantity: number,
    customerSelectedUnit: Unit,
    cartType: CartType,
    orderCardCode?: string,
    customerMaterialNumber?: string,
    cartTemplate?: string
  ): Observable<boolean>;
  abstract addA4SampleEntry(productCode: string, grammage: number, color: string, quantity: number): Observable<boolean>;
  abstract quickAddEntry(
    cartType: CartType,
    articleCode: string,
    quantity: number,
    customerSelectedUnit: Unit,
    note?: string,
    customerMaterialNumber?: string
  ): Observable<boolean>;
  abstract addEntries(entries: OrderEntry[], cartType: CartType, sourceData?: CartActionSourceData): Observable<boolean>;
  abstract removeEntry(entry: OrderEntry, cartType: CartType): Observable<boolean>;
  abstract removeEntryByNumber(entryNumber: number, cartType: CartType): Observable<boolean>;
  abstract removeEntryById(entryId: string, cartType: CartType): Observable<boolean>;
  abstract removeAllEntries(cartType: CartType): Observable<boolean>;
  abstract removeEntries(entries: OrderEntry[], cartType: CartType): Observable<boolean>;
  abstract updateEntry(entry: OrderEntry, quantity: number, unit: Unit, cartType: CartType): Observable<boolean>;
  abstract updateEntryNote(entry: OrderEntry, note: string, cartType: CartType): Observable<boolean>;
  abstract updateNameMarking(
    entry: OrderEntry,
    qualifier: string,
    ruleId: string,
    extendedIdentifier: string,
    values: string[],
    cartType: CartType
  ): Observable<boolean>;
  abstract updateEntryStatisticsCode(entry: OrderEntry, statisticsCode: string, cartType: CartType): Observable<boolean>;
  abstract replaceEntry(entry: OrderEntry, replacementArticle: Article, cartType: CartType): Observable<boolean>;
  abstract addEntryFullPalletNote(entry: OrderEntry, cartType: CartType): Observable<boolean>;
  abstract removeEntryFullPalletNote(entry: OrderEntry, cartType: CartType): Observable<boolean>;
  abstract addEntryCutting(entry: OrderEntry, cutting: CuttingRequest, cartType: CartType): Observable<boolean>;
  abstract removeEntryCutting(entry: OrderEntry, cartType: CartType): Observable<boolean>;
  abstract addEntryReelCutting(entry: OrderEntry, reelCutting: ReelCuttingRequest, cartType: CartType): Observable<boolean>;
  abstract removeEntryReelCutting(entry: OrderEntry, cartType: CartType): Observable<boolean>;
  abstract addEntryReaming(entry: OrderEntry, cartType: CartType): Observable<boolean>;
  abstract removeEntryReaming(entry: OrderEntry, cartType: CartType): Observable<boolean>;
  abstract updateEntryPalletFlags(entry: OrderEntry, palletFlags: PalletFlagsRequest, cartType: CartType): Observable<boolean>;
  abstract getLastEntry(articleCode: string, quantity: number, unit: Unit, cartType: CartType): Observable<OrderEntry>;
  abstract isMaxOrderLinesExceeded(cartType: CartType): Observable<boolean>;
  abstract reloadActiveCart(cartType: CartType): void;
}
