import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { BehaviorSubject, Observable, Subscription, combineLatest } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { TranslationService } from '../../../core/i18n';
import { PrincipalConfigurationService } from '../../../core/user';
import { AbstractReactiveFormInput } from '../reactive-forms-input/abstract-reactive-form-input';

@Component({
  selector: 'py-order-line-input',
  templateUrl: './order-line-input.component.html',
  styleUrls: ['./order-line-input.component.scss'],
})
export class OrderLineInputComponent extends AbstractReactiveFormInput implements OnInit, OnDestroy {
  isShowingInput = false;
  label$: Observable<string>;
  placeholder$: Observable<string>;
  _initValue: string;
  _key: string;
  _formGroup: UntypedFormGroup;
  _labelOverride$ = new BehaviorSubject<string>(undefined);
  _placeholderOverride$ = new BehaviorSubject<string>(undefined);

  @Output() save = new EventEmitter<string>();

  @Input() icon: string = 'pushpin';
  @Input() iconTheme: string = 'fill';
  @Input() disabled: boolean;
  @Input() isMandatory: boolean;
  @Input() entryFormGroup: UntypedFormGroup;
  @Input() regEx: string;
  @Input() set label(label: string) {
    this._labelOverride$.next(label);
  }
  @Input() set placeholder(placeholder: string) {
    this._placeholderOverride$.next(placeholder);
  }
  @Input() labelConfigurationKey: string;
  @Input() placeholderConfigurationKey: string;
  @Input() showButton: boolean;
  @Input() includeGroupErrors: boolean | string = false;
  @Input() useEntryFormGroup: boolean = false;
  @Input() showTouchedFieldAsInvalid = false;
  @Input() formGroupErrors: ValidationErrors;

  @Input() set key(value: string) {
    if (value) {
      this._key = value;
      this._formGroup = this.fb.group({
        [this._key]: ['', []],
      });
    }
  }

  @Input() set initValue(value: string) {
    if (value) {
      this.isShowingInput = false;
    }
    this._initValue = value;
    if (this.value !== value) {
      this.control?.setValue(value || '', { emitEvent: false });
      this.entryFormControl?.setValue(value);
    }
  }

  private subscription: Subscription = new Subscription();

  get entryFormControl(): AbstractControl {
    return this.entryFormGroup?.controls[this.key];
  }

  get formGroup(): UntypedFormGroup {
    if (this.useEntryFormGroup) {
      return this.entryFormGroup;
    }
    return this._formGroup;
  }

  get control(): AbstractControl {
    return this.formGroup?.controls[this.key];
  }

  get value(): string {
    return this.control?.value;
  }

  get initValue(): string {
    return this._initValue;
  }

  get key(): string {
    return this._key;
  }

  get saveDisabled(): boolean {
    if (!!this.control.value && this.control.errors?.needToSave) {
      return false;
    }
    return !this.control.value || !this.control.valid || this.initValue === this.value;
  }

  get invalid(): boolean {
    return !this.control.valid;
  }

  getField(): AbstractControl {
    return this.control;
  }

  getGroup(): UntypedFormGroup {
    return this.formGroup;
  }

  isIncludeGroupErrors(): boolean | string {
    return this.includeGroupErrors;
  }

  errors(): ValidationErrors {
    if (this.formGroupErrors) {
      return { ...this.formGroupErrors, ...super.errors() };
    }
    return super.errors();
  }

  constructor(
    public principalConfigurationService: PrincipalConfigurationService,
    private cd: ChangeDetectorRef,
    private fb: UntypedFormBuilder,
    protected translationService: TranslationService
  ) {
    super();
  }

  ngOnInit(): void {
    this.control.setValue(this.initValue || '', { emitEvent: false });
    this.entryFormControl.setValue(this.value);

    const validators: ValidatorFn[] = [];

    if (this.key === 'note') {
      validators.push(Validators.maxLength(35));
    } else if (this.key === 'statisticsCode') {
      validators.push(Validators.maxLength(30));
    }

    if (this.isMandatory) {
      validators.push(Validators.required);
    }
    this.entryFormControl.setValidators(validators);
    this.control.setValidators(validators);

    if (this.regEx) {
      this.setRegexControls(validators);
    }

    this.label$ = combineLatest([
      this._labelOverride$,
      this.principalConfigurationService.getValue(this.labelConfigurationKey),
      this.translationService.translate('cart.orderLineNote_heading'),
      this.translationService.translate('cart.statisticsCode_heading'),
    ]).pipe(
      map(([labelOverride, configValue, orderLineNoteHeading, statisticsCodeHeading]) => {
        if (labelOverride) {
          return labelOverride;
        }
        if (!!configValue?.value) {
          return configValue.value as string;
        } else if (this.key === 'note') {
          return orderLineNoteHeading;
        } else if (this.key === 'statisticsCode') {
          return statisticsCodeHeading;
        }
        return '';
      }),
      shareReplay({ bufferSize: 1, refCount: true })
    );

    this.placeholder$ = combineLatest([
      this._placeholderOverride$,
      this.principalConfigurationService.getValue(this.placeholderConfigurationKey),
      this.translationService.translate('cart.orderLineNote_placeholder'),
      this.translationService.translate('cart.statisticsCode_placeholder'),
    ]).pipe(
      map(([placeholderOverride, configValue, orderLineNotePlaceholder, statisticsCodePlaceholder]) => {
        if (placeholderOverride) {
          return placeholderOverride;
        }
        if (!!configValue?.value) {
          return configValue.value as string;
        } else if (this.key === 'note') {
          return orderLineNotePlaceholder;
        } else if (this.key === 'statisticsCode') {
          return statisticsCodePlaceholder;
        }
        return '';
      }),
      shareReplay({ bufferSize: 1, refCount: true })
    );

    this.entryFormControl.updateValueAndValidity();
    this.control.updateValueAndValidity();
  }

  setRegexControls(oldValidators: ValidatorFn[]): void {
    this.subscription.add(
      this.principalConfigurationService
        .getValue(this.regEx)
        .pipe(map((val) => val.value))
        .subscribe((regEx) => {
          if (typeof regEx === 'string') {
            const validators = [...oldValidators, Validators.pattern(regEx)];
            this.entryFormControl.setValidators(validators);
            this.entryFormControl.updateValueAndValidity();
            this.control.setValidators(validators);
            this.control.updateValueAndValidity();
          }
        })
    );
  }

  markAsDirty() {
    if (this.isMandatory) {
      this.entryFormControl?.updateValueAndValidity();
      this.control.updateValueAndValidity();

      if (!this.entryFormControl.valid && !this.control.valid) {
        this.control.setErrors(this.entryFormControl.errors);
      } else if (!this.entryFormControl.valid && this.control.valid) {
        this.control.setErrors({ needToSave: true });
      }
    }

    this.control.markAsDirty();
    this.cd.detectChanges();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  onSubmit(): void {
    if (!this.isInvalid() && !this.isPending()) {
      this.isShowingInput = false;
      this.save.emit(this.value);

      if (this.isMandatory) {
        this.entryFormControl.setValue(this.value);
      }

      this.initValue = this.value;
    }
  }

  onBlurSubmit(event: any): void {
    if (!event?.relatedTarget?.className?.includes('prevent-blur-submit') && this.isShowingInput) {
      if (this.initValue !== this.value && !this.isInvalid()) {
        this.onSubmit();
      } else if (this.initValue === this.value) {
        this.isShowingInput = false;
      }
    }
  }

  clear(): void {
    this.formGroup.patchValue({ [this.key]: '' });
    this.entryFormControl.setValue(this.value);

    this.isShowingInput = false;

    if (!!this.initValue) {
      this.save.emit(this.value);
    }
  }
}
