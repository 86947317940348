import { Component, HostBinding, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { BehaviorSubject, combineLatest, map } from 'rxjs';
import { SearchTermFacetMappingHint } from '../../../../../core/model';

@Component({
  selector: 'py-search-hints',
  templateUrl: './search-hints.component.html',
  styleUrls: ['./search-hints.component.scss'],
})
export class SearchHintsComponent {
  private _hints$ = new BehaviorSubject<SearchTermFacetMappingHint[]>([]);
  private _maxCollapsedHints$ = new BehaviorSubject<number>(3);
  private _collapseHints$ = new BehaviorSubject<boolean>(false);

  @Input() form: UntypedFormGroup;
  @Input() text?: string;
  @Input() set hints(hints: SearchTermFacetMappingHint[]) {
    this._hints$.next(hints);
  }
  @Input() set maxCollapsedHints(maxCollapsedHints: number) {
    this._maxCollapsedHints$.next(maxCollapsedHints);
  }
  @Input() set collapseHints(collapseHints: boolean) {
    this._collapseHints$.next(collapseHints);
  }
  @Input() @HostBinding('class.single-row') singleRow: boolean = false;
  @Input() @HostBinding('class.side-scroll') sideScroll: boolean = true;

  hiddenHintsCount$ = combineLatest([this._hints$, this._collapseHints$, this._maxCollapsedHints$]).pipe(
    map(([hints, collapseHints, maxCollapsedHints]) => (collapseHints ? (hints?.length ?? 0) - maxCollapsedHints : 0))
  );

  showExpandButton$ = combineLatest([this.hiddenHintsCount$]).pipe(map(([hiddenHintsCount]) => hiddenHintsCount > 0));

  hints$ = combineLatest([this._hints$, this.hiddenHintsCount$]).pipe(
    map(([hints, hiddenHintsCount]) => hints.slice(0, hints.length - hiddenHintsCount))
  );

  onHintClick(hint: SearchTermFacetMappingHint): void {
    this.form.get('search').setValue(hint);
  }

  onExpandHints(): void {
    this._collapseHints$.next(false);
  }
}
