import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { distinctUntilChanged, Observable, shareReplay, switchMap, tap } from 'rxjs';
import { LAUNCH_CALLER, LaunchDialogService, ModalCloseReason } from '../../../core/modal';
import { AvailableSoldTosSearchParams, SoldTo } from '../../../core/model';
import { shallowEqualObjects } from '../../../core/util';
import { SoldToFacade } from '../../../features/sold-to-base';
import { SoldToSelectorModalData } from './sold-to-selector-modal/sold-to-selector-modal.component';

@Component({
  selector: 'py-sold-to-selector',
  templateUrl: './sold-to-selector.component.html',
  styleUrls: ['./sold-to-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SoldToSelectorComponent implements OnInit {
  @Input()
  soldTo: string;

  @Input()
  label?: string;

  @Input() type?: string = 'select-sold-to';

  @Input() showActiveSoldToName?: boolean = false;

  @Output()
  update = new EventEmitter<string>();

  availableSearchResultEntitiesCount$: Observable<number>;

  constructor(
    private soldToService: SoldToFacade,
    private launchDialogService: LaunchDialogService
  ) {}

  ngOnInit(): void {
    const searchParametersForAvailableSoldTos$: Observable<AvailableSoldTosSearchParams> = this.soldToService
      .getSearchParametersForAvailableSoldTos()
      .pipe(distinctUntilChanged(shallowEqualObjects), shareReplay({ bufferSize: 1, refCount: true }));

    this.availableSearchResultEntitiesCount$ = searchParametersForAvailableSoldTos$.pipe(
      tap((searchParams) => this.soldToService.searchForAvailableSoldTos(searchParams)),
      switchMap(() => this.soldToService.getAvailableSearchResultEntitiesCount())
    );
  }

  changeSoldTo(soldTo: SoldTo) {
    if (this.showActiveSoldToName) {
      this.label = soldTo.name;
    }
    this.soldTo = soldTo.uid;
    this.update.emit(soldTo.uid);
    this.launchDialogService.closeDialog(ModalCloseReason.SOLD_TO_SELECTOR_CONFIRMED);
  }

  onDismiss(): void {
    this.launchDialogService.closeDialog(ModalCloseReason.SOLD_TO_SELECTOR_REJECTED);
  }

  openModal() {
    const soldToSelectorModalData: SoldToSelectorModalData = {
      soldTo: this.soldTo,
      label: this.label,
      type: this.type,
      showActiveSoldToName: this.showActiveSoldToName,
      changeSoldTo: (soldTo) => this.changeSoldTo(soldTo),
      onDismiss: () => this.onDismiss(),
    };
    this.launchDialogService.openDialogAndSubscribe(LAUNCH_CALLER.SOLD_TO_SELECTOR, undefined, soldToSelectorModalData);
  }
}
