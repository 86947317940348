import { Injectable } from '@angular/core';
import { facadeFactory } from '@spartacus/core';
import { Observable } from 'rxjs';
import { Cart, CartError } from '../../../../core/model';
import { CART_CORE_FEATURE } from '../store/cart-state';

@Injectable({
  providedIn: 'root',
  useFactory: () =>
    facadeFactory({
      facade: CartValidatorFacade,
      async: true,
      feature: CART_CORE_FEATURE,
      methods: ['getCartErrors', 'getCartErrorMessage'],
    }),
})
export abstract class CartValidatorFacade {
  abstract getCartErrors(cart: Cart): Observable<CartError[] | null>;
  abstract getCartErrorMessage(cartErrors: CartError[] | null): Observable<string | null>;
}
