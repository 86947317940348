import { Injectable } from '@angular/core';
import { facadeFactory, StateUtils } from '@spartacus/core';
import { Observable } from 'rxjs';
import {
  AccountingCode,
  Cart,
  CartActionSourceData,
  CartType,
  CuttingRequest,
  OrderEntry,
  PalletFlagsRequest,
  ReelCuttingRequest,
  Unit,
} from '../../../../core/model';
import { CART_CORE_FEATURE } from '../store/cart-state';

@Injectable({
  providedIn: 'root',
  useFactory: () =>
    facadeFactory({
      facade: CartFacade,
      feature: CART_CORE_FEATURE,
      async: true,
      methods: [
        'getCart',
        'getCartEntity',
        'getEntries',
        'getEntryById',
        'isStable',
        'loadCart',
        'reloadCart',
        'clearCarts',
        'addEntry',
        'addA4SampleEntry',
        'quickAddEntry',
        'addEntries',
        'removeEntry',
        'removeEntries',
        'removeAllEntries',
        'updateEntry',
        'updateEntryNote',
        'updateNameMarking',
        'updateEntryStatisticsCode',
        'updateEntryAccountingCode',
        'getUpdateEntryAccountingCode',
        'replaceEntry',
        'addEntryFullPalletNote',
        'removeEntryFullPalletNote',
        'addEntryCutting',
        'removeEntryCutting',
        'addEntryReelCutting',
        'removeEntryReelCutting',
        'addEntryReaming',
        'removeEntryReaming',
        'updateEntryPalletFlags',
        'getLastEntry',
        'isMaxOrderLinesExceeded',
      ],
    }),
})
export abstract class CartFacade {
  abstract getCart(cartId: string): Observable<Cart>;
  abstract getCartEntity(cartId: string): Observable<StateUtils.ProcessesLoaderState<Cart>>;
  abstract getEntries(cartId: string): Observable<OrderEntry[]>;
  abstract getEntryById(cartId: string, entryId: string): Observable<OrderEntry | undefined>;
  abstract isStable(cartId: string): Observable<boolean>;
  abstract loadCart({
    cartType,
    cartId,
    userId,
    extraData,
  }: {
    cartType: CartType;
    cartId: string;
    userId: string;
    extraData?: {
      active?: boolean;
    };
  }): void;
  abstract reloadCart(cartId: string, cartType: CartType, extraData?: { active: boolean }): void;
  abstract clearCarts(): void;
  abstract addEntry(
    userId: string,
    cartType: CartType,
    cartId: string,
    articleCode: string,
    quantity: number,
    unit: Unit,
    orderCardCode?: string,
    customerMaterialNumber?: string,
    cartTemplate?: string
  ): Observable<boolean>;
  abstract addA4SampleEntry(
    userId: string,
    cartId: string,
    productCode: string,
    grammage: number,
    color: string,
    quantity: number
  ): Observable<boolean>;
  abstract quickAddEntry(
    userId: string,
    cartType: CartType,
    cartId: string,
    articleCode: string,
    quantity: number,
    unit: Unit,
    note?: string,
    customerMaterialNumber?: string
  ): Observable<boolean>;
  abstract addEntries(
    userId: string,
    cartType: CartType,
    cartId: string,
    entries: OrderEntry[],
    sourceData?: CartActionSourceData
  ): Observable<boolean>;
  abstract removeEntry(userId: string, cartType: CartType, cartId: string, entry: OrderEntry): Observable<boolean>;
  abstract removeEntries(userId: string, cartType: CartType, cartId: string, entries: OrderEntry[]): Observable<boolean>;
  abstract removeAllEntries(userId: string, cartType: CartType, cartId: string): Observable<boolean>;
  abstract updateEntry(
    userId: string,
    cartType: CartType,
    cartId: string,
    entry: OrderEntry,
    quantity?: number,
    unit?: Unit
  ): Observable<boolean>;
  abstract updateEntryNote(
    userId: string,
    cartType: CartType,
    cartId: string,
    entry: OrderEntry,
    note: string
  ): Observable<boolean>;
  abstract updateNameMarking(
    userId: string,
    cartType: CartType,
    cartId: string,
    entry: OrderEntry,
    qualifier: string,
    ruleId: string,
    extendedIdentifier: string,
    values: string[]
  ): Observable<boolean>;
  abstract updateEntryStatisticsCode(
    userId: string,
    cartType: CartType,
    cartId: string,
    entry: OrderEntry,
    statisticsCode: string
  ): Observable<boolean>;
  abstract updateEntryAccountingCode(
    userId: string,
    cartType: CartType,
    cartId: string,
    entry: OrderEntry,
    accountingCode: string
  ): Observable<boolean>;
  abstract getUpdateEntryAccountingCode(cartId, entry: OrderEntry): Observable<AccountingCode>;
  abstract replaceEntry(
    userId: string,
    cartType: CartType,
    cartId: string,
    entry: OrderEntry,
    articleNumber: string,
    quantity: number,
    unit: Unit
  ): Observable<boolean>;
  abstract addEntryFullPalletNote(userId: string, cartType: CartType, cartId: string, entry: OrderEntry): Observable<boolean>;
  abstract removeEntryFullPalletNote(userId: string, cartType: CartType, cartId: string, entry: OrderEntry): Observable<boolean>;
  abstract addEntryCutting(
    userId: string,
    cartType: CartType,
    cartId: string,
    entry: OrderEntry,
    cutting: CuttingRequest
  ): Observable<boolean>;
  abstract removeEntryCutting(userId: string, cartType: CartType, cartId: string, entry: OrderEntry): Observable<boolean>;
  abstract addEntryReelCutting(
    userId: string,
    cartType: CartType,
    cartId: string,
    entry: OrderEntry,
    reelCutting: ReelCuttingRequest
  ): Observable<boolean>;
  abstract removeEntryReelCutting(userId: string, cartType: CartType, cartId: string, entry: OrderEntry): Observable<boolean>;
  abstract addEntryReaming(userId: string, cartType: CartType, cartId: string, entry: OrderEntry): Observable<boolean>;
  abstract removeEntryReaming(userId: string, cartType: CartType, cartId: string, entry: OrderEntry): Observable<boolean>;
  abstract updateEntryPalletFlags(
    userId: string,
    cartType: CartType,
    cartId: string,
    entry: OrderEntry,
    palletFlags: PalletFlagsRequest
  ): Observable<boolean>;
  abstract getLastEntry(cartId: string, articleCode: string, quantity: number, unit: Unit): Observable<OrderEntry | null>;
  abstract isMaxOrderLinesExceeded(cartId): Observable<boolean>;
}
