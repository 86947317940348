<span class="search-hint-info mr-2">
  {{ text ?? ('catalog.searchHints_heading' | cxTranslate) }}
</span>

<div class="position-relative flex-shrink-1" style="min-width: 0">
  <div *ngIf="!sideScroll; else sideScrollTemplate" class="search-hint-holder">
    <ng-container *ngTemplateOutlet="hintsTemplate"></ng-container>
  </div>
</div>

<ng-template #sideScrollTemplate>
  <div class="search-hint-holder side-scroll" pyNavSideScroll [skipNavScrollArrows]="true">
    <ng-container *ngTemplateOutlet="hintsTemplate"></ng-container>
  </div>
</ng-template>

<ng-template #hintsTemplate>
  <button *ngFor="let hint of hints$ | async" (click)="onHintClick(hint)" class="d-flex align-items-center search-hint">
    <i nz-icon nzType="search" class="mr-1"></i>
    <span [innerHTML]="hint"></span>
  </button>
  <button *ngIf="showExpandButton$ | async" (click)="onExpandHints()" class="d-flex align-items-center search-hint">
    <span>+{{ hiddenHintsCount$ | async }}</span>
  </button>
</ng-template>
