import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { BehaviorSubject, Observable, UnaryFunction, pipe } from 'rxjs';
import { combineLatestWith, filter, map, mergeMap, take, tap } from 'rxjs/operators';
import { AsyncReturnService } from '../../../../shared';
import { UserIdService } from '../../../auth';
import { Category, CategoryRef } from '../../../model/catalog.model';
import { LoaderError } from '../../../model/misc.model';
import { CatalogKeysService } from '../../services';
import { StateWithCatalog } from '../../store/catalog-state';
import { CatalogSelectors } from '../../store/selectors';
import { CategoryLevelType } from '../model/category-level';
import { CategoryActions } from '../store/actions';
import { areCategoriesEmpty, categoryRefsByLevelSelector } from '../store/selectors/category.selectors';

@Injectable({
  providedIn: 'root',
})
export class CategoryService {
  private dispatchedLoadCategories$ = new BehaviorSubject<Array<{ userId: string; key: string; myAssortment?: string }>>([]);

  constructor(
    private store: Store<StateWithCatalog>,
    private userIdService: UserIdService,
    private asyncReturnService: AsyncReturnService,
    private catalogKeysService: CatalogKeysService
  ) {}

  getCategoriesLoading(catalogUsageKey: string = 'default'): Observable<boolean> {
    return this.store.select(CatalogSelectors.getCategoriesLoading, { catalogUsageKey: catalogUsageKey });
  }

  getCategoriesSuccess(catalogUsageKey: string = 'default'): Observable<boolean> {
    return this.store.select(CatalogSelectors.getCategoriesSuccess, { catalogUsageKey: catalogUsageKey });
  }

  getCategoriesError(catalogUsageKey: string = 'default'): Observable<LoaderError | boolean> {
    return this.store.select(CatalogSelectors.getCategoriesError, { catalogUsageKey: catalogUsageKey });
  }

  getCategory(ref: CategoryRef, catalogUsageKey: string = 'default'): Observable<Category> {
    return this.store.pipe(
      select(CatalogSelectors.categoryByRefSelector, { ref: ref, catalogUsageKey: catalogUsageKey }),
      this.loadIfNotPresentPipe(catalogUsageKey)
    );
  }

  getCategoriesByLevel(level: CategoryLevelType, catalogUsageKey: string = 'default'): Observable<Array<Category>> {
    return this.getCategoryRefsByLevel(level, catalogUsageKey).pipe(
      mergeMap((refs) => this.getCategoriesByRefs(refs, catalogUsageKey))
    );
  }

  getAreCategoriesEmpty(catalogUsageKey: string = 'default'): Observable<boolean> {
    return this.store.select(areCategoriesEmpty, { catalogUsageKey: catalogUsageKey });
  }

  getCategoryRefsByLevel(level: CategoryLevelType, catalogUsageKey: string = 'default'): Observable<Array<CategoryRef>> {
    return this.store.pipe(
      select(categoryRefsByLevelSelector, { level: level, catalogUsageKey: catalogUsageKey }),
      this.loadIfNotPresentPipe(catalogUsageKey)
    );
  }

  getCategoriesByRefs(refs: Array<CategoryRef>, catalogUsageKey: string = 'default'): Observable<Array<Category>> {
    return this.store.pipe(
      select(CatalogSelectors.categoriesForRefsSelector, { refs: refs, catalogUsageKey: catalogUsageKey }),
      this.loadIfNotPresentPipe(catalogUsageKey)
    );
  }

  getCategoriesByRefsAndLevel(
    level: number,
    refs: Array<CategoryRef>,
    catalogUsageKey: string = 'default'
  ): Observable<Array<Category>> {
    return this.store.pipe(
      select(CatalogSelectors.categoriesForRefsAndLevelSelector, { level: level, refs: refs, catalogUsageKey: catalogUsageKey }),
      this.loadIfNotPresentPipe(catalogUsageKey)
    );
  }

  private loadIfNotPresentPipe<T>(catalogUsageKey: string): UnaryFunction<Observable<T>, Observable<T>> {
    return pipe(
      combineLatestWith(
        this.userIdService.getUserId(),
        this.catalogKeysService.getKey(catalogUsageKey),
        this.catalogKeysService.getCatalogKeysReady(),
        this.getCategoriesLoading(catalogUsageKey),
        this.getCategoriesSuccess(catalogUsageKey)
      ),
      filter(([_categories, _userId, _key, catalogKeysReady, _loading, _success]) => !!catalogKeysReady),
      tap(([categories, userId, key, _catalogKeysReady, loading, success]) => {
        if (!categories && !loading && !success) {
          const myAssortment =
            (catalogUsageKey && catalogUsageKey.startsWith('mya:') && catalogUsageKey.substring(4)) || undefined;
          this.loadCategories(userId, key, myAssortment);
        }
      }),
      filter(([categories, _]) => !!categories),
      map(([categories, _]) => categories)
    );
  }

  private loadCategories(userId: string, key: string, myAssortment?: string): void {
    const equalsCurrentEvent = (event: { userId: string; key: string; myAssortment?: string }) =>
      event.userId === userId && event.key === key && event.myAssortment === myAssortment;

    // Avoid triggering multiple loads with the same parameters at the same time
    if (!this.dispatchedLoadCategories$.value.find(equalsCurrentEvent)) {
      this.dispatchedLoadCategories$.next([...this.dispatchedLoadCategories$.value, { userId, key, myAssortment }]);
      this.asyncReturnService
        .asyncReturnSerializable<boolean>((actionToken) =>
          this.store.dispatch(new CategoryActions.LoadCategories(userId, key, myAssortment, actionToken))
        )
        .pipe(take(1))
        .subscribe(() =>
          this.dispatchedLoadCategories$.next(this.dispatchedLoadCategories$.value.filter((event) => !equalsCurrentEvent(event)))
        );
    }
  }
}
