import { DIALOG_TYPE, LAUNCH_CALLER, LayoutConfig } from '@spartacus/storefront';
import { SoldToSelectorModalComponent } from './sold-to-selector-modal.component';

export const soldToSelectorModalLayoutConfig: LayoutConfig = {
  launch: {
    [LAUNCH_CALLER.SOLD_TO_SELECTOR]: {
      inlineRoot: true,
      component: SoldToSelectorModalComponent,
      dialogType: DIALOG_TYPE.DIALOG,
      dialogOptions: {
        autoDismissOnRouteChange: true,
      },
    },
  },
};
