<py-modal
  *ngIf="{
    soldTo: soldTo$ | async,
    label: label$ | async,
    type: type$ | async,
    showActiveSoldToName: showActiveSoldToName$ | async,
    availableSoldTos: availableSoldTos$ | async,
    availableSoldTosLoading: availableSoldTosLoading$ | async,
    availableSearchResultEntitiesCount: availableSearchResultEntitiesCount$ | async,
    selectedSoldTo: selectedSoldTo$ | async,
  } as data"
  [title]="data.label"
  [actions]="templateActions"
  [centered]="true"
  [cxFocus]="focusConfig"
  [scrollable]="true"
  [blockDismissCoreAction]="true"
  (dismiss)="onDismiss()"
>
  <div class="sold-to-selector-dropdown">
    <div class="p-2">
      <form [formGroup]="form">
        <input
          type="text"
          class="form-control"
          [formControlName]="'text'"
          [placeholder]="'common.search_placeholder' | cxTranslate"
          [size]="24"
        />
      </form>
    </div>
    <ng-container [ngSwitch]="data.type">
      <ul
        *ngSwitchCase="'select-sold-to'"
        infiniteScroll
        [alwaysCallback]="true"
        (scrolled)="fetchMoreSoldTos()"
        [scrollWindow]="false"
        class="select-sold-to"
      >
        <li
          *ngFor="let availableSoldTo of data.availableSoldTos"
          class="p-3"
          [attr.active]="data.soldTo === availableSoldTo.uid ? 'true' : null"
          (click)="selectSoldTo(availableSoldTo)"
        >
          <div>{{ availableSoldTo.name }}</div>
          <span *ngIf="availableSoldTo?.uid">{{ availableSoldTo?.uid }}</span>
        </li>
      </ul>
      <ul
        *ngSwitchDefault
        infiniteScroll
        [alwaysCallback]="true"
        (scrolled)="fetchMoreSoldTos()"
        [scrollWindow]="false"
        class="change-sold-to"
      >
        <li
          *ngFor="let availableSoldTo of data.availableSoldTos"
          class="p-3"
          [attr.active]="data.soldTo === availableSoldTo.uid ? 'true' : null"
          (click)="selectSoldTo(availableSoldTo)"
        >
          <div>{{ availableSoldTo.uid }} - {{ availableSoldTo.name }}</div>
          <ng-container
            *ngIf="{
              address:
                ('enableDisplayDefaultShipToAsSoldToAddress' | principalConfigValue)
                  ? availableSoldTo?.shipTo
                  : availableSoldTo?.customerAddress,
            } as soldToData"
          >
            <span *ngIf="soldToData?.address?.computedDescription">{{ soldToData.address.computedDescription }}</span>
          </ng-container>
        </li>
      </ul>
    </ng-container>
    <div class="py-2 text-center" *ngIf="data.availableSoldTosLoading">
      <cx-spinner></cx-spinner>
    </div>
  </div>

  <ng-template #templateActions>
    <py-action-button
      [buttonClass]="'btn btn-ternary'"
      [type]="'button'"
      [buttonLabel]="'common.close_action' | cxTranslate"
      (click)="onDismiss()"
    ></py-action-button>
    <py-action-button
      [buttonClass]="'btn btn-primary'"
      [disabled]="!data.selectedSoldTo"
      [type]="'button'"
      [buttonLabel]="'myPage.changeCustomer_action' | cxTranslate"
      (click)="changeSoldTo(data.selectedSoldTo)"
    ></py-action-button>
  </ng-template>
</py-modal>
