import { CxEvent } from '@spartacus/core';
import {
  Article,
  Cart,
  Order,
  OrderEntry,
  PaymentOption,
  Product,
  ShippingOption,
  UserSelectContentTypes,
} from '../../../core/model';

export enum LoginMethod {
  WITH_CREDENTIALS = 'WITH_CREDENTIALS',
  WITH_AUTH_PARAMS_IN_URL = 'WITH_AUTH_PARAMS_IN_URL',
  WITH_CUSTOMER_SUPPORT_AGENT = 'WITH_CUSTOMER_SUPPORT_AGENT',
  WITH_CUSTOMER_EMULATION_SESSION = 'WITH_CUSTOMER_EMULATION_SESSION',
}
export enum ItemListType {
  SIMILAR_ARTICLES = 'SIMILAR_ARTICLES',
  CATALOG = 'CATALOG',
  PDP_ARTICLES_LIST = 'PDP_ARTICLES_LIST',
  RECOMMENDED_ARTICLES = 'RECOMMENDED_ARTICLES',
  RECOMMENDED_PRODUCTS = 'RECOMMENDED_PRODUCTS',
  SUBSTITUTES_ARTICLES = 'SUBSTITUTES_ARTICLES',
  RELATED_ARTICLES_AND_PRODUCTS = 'RELATED_ARTICLES_AND_PRODUCTS',
  MOST_ORDERED_ARTICLES = 'MOST_ORDERED_ARTICLES',
  LAST_ORDERED_ARTICLES = 'LAST_ORDERED_ARTICLES',
}
abstract class CanonicalBusinessEvent extends CxEvent {
  cart: Cart;
}

/**
 * A user adds entries to cart
 */
export class TrackingEventAddEntriesToCart extends CanonicalBusinessEvent {
  static readonly type = 'TrackingEventAddEntriesToCart';
  articles: Article[];
  entries: OrderEntry[];
}

/**
 * A user removes entries from cart
 */
export class TrackingEventRemoveEntriesFromCart extends CanonicalBusinessEvent {
  static readonly type = 'TrackingEventRemoveEntriesFromCart';
  articles: Article[];
  entries: OrderEntry[];
}

/**
 * A user enter the checkout page
 */
export class TrackingEventBeginCheckout extends CanonicalBusinessEvent {
  static readonly type = 'TrackingEventBeginCheckout';
  code: string;
  articles: Article[];
  entries: OrderEntry[];
}

/**
 * A user completes a order. This will trigger BEFORE the payment procedure
 */
export class TrackingEventPurchase extends CanonicalBusinessEvent {
  static readonly type = 'TrackingEventPurchase';
  order: Order;
  articles: Article[];
  entries: OrderEntry[];
}

/**
 * A user is adding payment info on the checkout page
 */
export class TrackingEventAddPaymentInfo extends CanonicalBusinessEvent {
  static readonly type = 'TrackingEventAddPaymentInfo';
  paymentOption: PaymentOption;
}

/**
 * A user is adding shipping info on the checkout page
 */
export class TrackingEventAddShippingInfo extends CanonicalBusinessEvent {
  static readonly type = 'TrackingEventAddShippingInfo';
  shippingOption: ShippingOption;
}

/**
 * A user is removing a article from shopping-list
 */
export class TrackingEventRemoveFromWishlist extends CanonicalBusinessEvent {
  static readonly type = 'TrackingEventRemoveFromWishlist';
  articles: Article[];
  entries: OrderEntry[];
}

/**
 * A user payment was successful
 */
export class TrackingEventPayment extends CanonicalBusinessEvent {
  static readonly type = 'TrackingEventPayment';
  constructor(public sapOrderNumber: string) {
    super();
  }
}

/**
 * A user is adding a article to shopping-list
 */
export class TrackingEventAddToWishlist extends CanonicalBusinessEvent {
  static readonly type = 'TrackingEventAddToWishlist';
  articles: Article[];
  entries: OrderEntry[];
}

/**
 * A user is submitting any mailform on the site
 */
export class TrackingEventGenerateLead extends CanonicalBusinessEvent {
  formData: any;
  static readonly type = 'TrackingEventGenerateLead';
}

/**
 * A user is logging in
 */
export class TrackingEventLogin extends CanonicalBusinessEvent {
  method: LoginMethod;
  static readonly type = 'TrackingEventLogin';
}
/**
 * A user is logging out
 */
export class TrackingEventLogout extends CanonicalBusinessEvent {
  static readonly type = 'TrackingEventLogout';
}

/**
 * TODO Not implemented
 */
export class TrackingEventRefund extends CanonicalBusinessEvent {
  static readonly type = 'TrackingEventRefund';
}

/**
 * A user is using search
 */
export class TrackingEventSearch extends CanonicalBusinessEvent {
  freeTextSearch: string;
  articles: Article[];
  products: Product[];
  static readonly type = 'TrackingEventSearch';
}

/**
 * A user enter the PDP
 */
export class TrackingEventSelectItem extends CanonicalBusinessEvent {
  productRef?: string;
  articleRef?: string;
  id?: ItemListType;
  ticket?: string;
  static readonly type = 'TrackingEventSelectItem';
}

/**
 * TODO Not implemented
 */
export class TrackingEventSelectPromotion extends CanonicalBusinessEvent {
  static readonly type = 'TrackingEventSelectPromotion';
}

/**
 * TODO Not implemented
 */
export class TrackingEventShare extends CanonicalBusinessEvent {
  static readonly type = 'TrackingEventShare';
}

/**
 * TODO Not implemented
 */
export class TrackingEventSignUp extends CanonicalBusinessEvent {
  static readonly type = 'TrackingEventSignUp';
}

/**
 * A user enters the cartpage or opens cart drawer
 */
export class TrackingEventViewCart extends CanonicalBusinessEvent {
  static readonly type = 'TrackingEventViewCart';
  articles: Article[];
  entries: OrderEntry[];
}

/**
 * A user enter the PDP
 */
export class TrackingEventViewItem extends CanonicalBusinessEvent {
  product: Product;
  id: ItemListType;
  static readonly type = 'TrackingEventViewItem';
}

/**
 * A user is fetching articles/products to view in the catalog (solrSearchResult)
 */
export class TrackingEventViewItemList extends CanonicalBusinessEvent {
  articles?: Article[];
  products?: Product[];
  id: ItemListType;
  static readonly type = 'TrackingEventViewItemList';
}

/**
 * TODO Not implemented
 */
export class TrackingEventViewPromotion extends CanonicalBusinessEvent {
  static readonly type = 'TrackingEventViewPromotion';
}

/**
 * A user starts the onboarding flow
 */
export class TrackingEventTutorialBegin extends CanonicalBusinessEvent {
  static readonly type = 'TrackingEventTutorialBegin';
}

/**
 * A user completes the onboarding flow
 */
export class TrackingEventTutorialComplete extends CanonicalBusinessEvent {
  static readonly type = 'TrackingEventTutorialComplete';
}

/**
 * A user selects any content on the site (mouseclicks)
 */
export class TrackingEventSelectContent extends CanonicalBusinessEvent {
  target: HTMLElement;
  type: UserSelectContentTypes;
  id: string;
  static readonly type = 'TrackingEventSelectContent';
}
