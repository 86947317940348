import { MemoizedSelector, MemoizedSelectorWithProps, createSelector } from '@ngrx/store';
import { StateUtils } from '@spartacus/core';
import { Category, CategoryRef } from '../../../../model';
import { PyStateUtils } from '../../../../state';
import { CatalogState, CategoryEntries, StateWithCatalog } from '../../../store/catalog-state';
import { getCatalogState } from '../../../store/selectors/feature.selector';

export const getAreasOfUseState: MemoizedSelector<StateWithCatalog, StateUtils.LoaderState<CategoryEntries>> = createSelector(
  getCatalogState,
  (state: CatalogState) => state.areasOfUse ?? PyStateUtils.initialLoaderState
);

export const getAreasOfUseEntriesState: MemoizedSelector<StateWithCatalog, CategoryEntries> = createSelector(
  getAreasOfUseState,
  (state) => (state && StateUtils.loaderValueSelector(state)) || undefined
);

export const getAreasOfUseLoading: MemoizedSelector<StateWithCatalog, boolean> = createSelector(
  getAreasOfUseState,
  (state) => (state && StateUtils.loaderLoadingSelector(state)) || false
);

export const getAreasOfUseLoaded: MemoizedSelector<StateWithCatalog, boolean> = createSelector(
  getAreasOfUseState,
  (state) => (state && StateUtils.loaderSuccessSelector(state)) || false
);

export const areasOfUseSelector: MemoizedSelector<StateWithCatalog, Array<CategoryRef>> = createSelector(
  getAreasOfUseLoaded,
  getAreasOfUseEntriesState,
  (loaded, entriesState) => (loaded && entriesState && entriesState.tree['0']) || undefined
);

export const areaOfUseByRefSelector: MemoizedSelectorWithProps<StateWithCatalog, any, Category> = createSelector(
  getAreasOfUseLoaded,
  getAreasOfUseEntriesState,
  (loaded, entriesState, props) => (loaded && entriesState && entriesState.entities[props.ref]) || undefined
);

export const areasOfUseForRefsSelector: MemoizedSelectorWithProps<StateWithCatalog, any, Category[]> = createSelector(
  getAreasOfUseLoaded,
  getAreasOfUseEntriesState,
  (loaded, entriesState, props) =>
    (loaded && entriesState && props.refs.map((ref) => entriesState.entities[ref]).filter((x) => x)) || undefined
);

export const areasOfUseForRefsAndLevelSelector: MemoizedSelectorWithProps<StateWithCatalog, any, Category[]> = createSelector(
  getAreasOfUseLoaded,
  getAreasOfUseEntriesState,
  (loaded, entriesState, props) => {
    return (
      (loaded &&
        entriesState &&
        props.refs.map((ref) => entriesState.entities[ref]).filter((category) => category.level === props.level)) ||
      []
    );
  }
);

export const areasOfUseForRefsAndSuperCategoryRefSelector: MemoizedSelectorWithProps<StateWithCatalog, any, Category[]> =
  createSelector(getAreasOfUseLoaded, getAreasOfUseEntriesState, (loaded, entriesState, props) => {
    return (
      (loaded &&
        entriesState &&
        props.refs
          .map((ref) => entriesState.entities[ref])
          .filter((category) => !!category)
          .filter((category) => category.superCategoryRef === props.superCategoryRef)) ||
      []
    );
  });

export const getAreasOfUseLastUpdateTime: MemoizedSelector<StateWithCatalog, string> = createSelector(
  getAreasOfUseLoaded,
  getAreasOfUseEntriesState,
  (loaded, entriesState) => (loaded && entriesState && entriesState.lastUpdateTime) || undefined
);
