import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';
import { filter, map, switchMapTo } from 'rxjs/operators';
import { ArticlePrice, CartType, ComparativePrice, HorizontalAlignment, Price, SpinnerType } from '../../../core/model';
import { PrincipalConfigurationService } from '../../../core/user';
import { PricePipe } from '../../pipes/price.pipe';
import { UnitPipe } from '../../pipes/unit.pipe';
import { getTextToDisplayInsteadOfPrice } from '../../utils/price-utility';
import { BadgeType } from '../badge';

@Component({
  selector: 'py-article-price',
  templateUrl: './article-price.component.html',
  styleUrls: ['./article-price.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ArticlePriceComponent implements OnInit {
  readonly badgeType = BadgeType;
  readonly spinnerType = SpinnerType;

  _contractedPrice?: Price;
  _textInsteadOfPrice: string | undefined;
  @Input() price: Price;
  @Input() set contractedPrice(contractedPrice: Price) {
    this._contractedPrice = contractedPrice;
  }
  @Input() articlePrice: ArticlePrice;
  @Input() loading = false;
  @Input() showArticlePriceInsteadOfEntryPrice?: boolean = false;
  @Input() priceCampaign?: boolean;
  @Input() horizontalAlignment: HorizontalAlignment = HorizontalAlignment.RIGHT;
  @Input() set cartType(cartType: CartType) {
    this._textInsteadOfPrice = getTextToDisplayInsteadOfPrice(cartType);
  }
  @Input() set textInsteadOfPrice(textInsteadOfPrice: string | undefined) {
    this._textInsteadOfPrice = textInsteadOfPrice;
  }
  @Input() showOutgoingBadge: boolean = false;
  @Input() showLastChanceBadge: boolean = false;

  @Input() set comparativePrice(comparativePrice: ComparativePrice) {
    this.comparativePrice$.next(comparativePrice);
  }

  @Output() loadPrice = new EventEmitter<void>();

  tooltip$: Observable<string>;
  horizontalAlignments = HorizontalAlignment;
  get textInsteadOfPrice() {
    return this._textInsteadOfPrice;
  }

  get showContractedPrice(): boolean {
    return this.showArticlePriceInsteadOfEntryPrice
      ? Number.parseFloat(this.articlePrice?.price) < Number.parseFloat(this.articlePrice?.contractedPrice)
      : this.price?.value < this.contractedPrice?.value;
  }

  get contractedPrice(): Price {
    return this.showArticlePriceInsteadOfEntryPrice
      ? ({
          value: this.articlePrice?.contractedPrice ? Number.parseFloat(this.articlePrice.contractedPrice) : undefined,
        } as Price)
      : this._contractedPrice || ({} as Price);
  }

  private comparativePrice$ = new ReplaySubject<ComparativePrice>(1);

  constructor(
    private principalConfigurationService: PrincipalConfigurationService,
    private pricePipe: PricePipe,
    private unitPipe: UnitPipe
  ) {}

  ngOnInit(): void {
    this.tooltip$ = this.principalConfigurationService.isEnabled('enableDisplayComparativePrices').pipe(
      filter(Boolean),
      switchMapTo(this.comparativePrice$),
      filter((comparativePrice) => !!comparativePrice && !!comparativePrice?.price?.value),
      map(
        (comparativePrice) =>
          `${this.pricePipe.transform(comparativePrice?.price, 'symbol-narrow', true)} / ${
            comparativePrice?.quantity
          } ${this.unitPipe.transform(comparativePrice?.unit, comparativePrice?.quantity)}`
      )
    );
  }

  parsePrice(value: string): number {
    return parseFloat(value);
  }
}
