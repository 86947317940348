<ng-container
  *ngIf="{
    renderFully: renderFully$ | async,
    article: article$ | async,
    articleLoading: articleLoading$ | async,
    articleFailure: articleFailure$ | async,
    showArticleError: showArticleError$ | async,
    articlePrice: articlePrice$ | async,
    articlePriceLoading: articlePriceLoading$ | async,
    quantityAndUnitValue: quantityAndUnitValue$ | async,
    initialMinimumQuantity: initialMinimumQuantity$ | async,
    disableActionButtons: disableActionButtons$ | async,
    columnAttributes: columnAttributes$ | async,
    isAddToCartInProgress: isAddToCartInProgress$ | async,
    hasSearchScore: hasSearchScore$ | async,
    showPrePrintedLabelDropdown: showPrePrintedLabelDropdown$ | async,
  } as data"
>
  <tr>
    <td class="py-0 w-100" colspan="100">
      <div class="position-relative">
        <!-- Error handling -->
        <ng-container *ngIf="data.showArticleError && !data.articleLoading">
          <aside class="overlay pb-1" *ngIf="!!data.article; else articleFailedTemplate">
            <py-catalog-article-overlay-message
              [expanded]="isSimilarArticlesExpanded"
              [articleRef]="articleResultRef?.ref"
              (expandedChange)="onOverlayExpanded($event)"
            ></py-catalog-article-overlay-message>
          </aside>

          <ng-template #articleFailedTemplate>
            <div class="pt-4" [class.pb-4]="!isSimilarArticlesExpanded">
              <py-notification type="danger">
                <div class="content">
                  <p>
                    {{ 'catalog.articleNoLongerAvailable_text' | cxTranslate: { articleRef: articleResultRef?.ref } }}
                  </p>
                </div>
              </py-notification>
            </div>
          </ng-template>
        </ng-container>

        <!-- "information" section, which may be covered by an overlay -->
        <table class="no-border w-100">
          <tbody>
            <!-- Main row -->
            <tr class="main" *ngIf="!!data.article || data.articleLoading">
              <td class="image" [attr.rowspan]="enableSecondaryArticleRowVariant ? 3 : 2">
                <ng-container *ngIf="!enableSecondaryArticleRowVariant; else imageSecondaryRowVariant">
                  <py-article-image
                    *ngIf="data.renderFully"
                    [images]="
                      data.article && !!(data.article.image || data.article.articleGroupImage)
                        ? [data.article.image || data.article.articleGroupImage]
                        : []
                    "
                    [loading]="data.articleLoading"
                    [altText]="data?.article?.articleName || data?.article?.productName"
                  ></py-article-image>
                </ng-container>
              </td>

              <td
                class="info"
                [attr.colspan]="
                  enableSecondaryArticleRowVariant && !(showAttributesInSeparateColumn && data.columnAttributes?.length > 0)
                    ? 2
                    : 1
                "
                [attr.rowspan]="enableSecondaryArticleRowVariant ? 2 : 1"
              >
                <div class="d-flex flex-column">
                  <div class="d-flex align-items-start">
                    <py-badge
                      *ngIf="data.hasSearchScore && articleResultRef?.score > 0"
                      [type]="articleResultRef.topScore ? 'success' : 'info'"
                      (click)="openSearchScoreModal()"
                      class="searchScoreBadge"
                    >
                      <i nz-icon nzType="trophy" nzTheme="fill" *ngIf="articleResultRef.topScore"></i>
                      {{ articleResultRef.score }}
                    </py-badge>
                  </div>
                  <py-article-info
                    [article]="data.article"
                    [queryParams]="queryParams"
                    [outOfStock]="articleOutOfStock"
                    [substituteBadgeType]="substituteBadgeType"
                    [enableSecondaryArticleRowVariant]="enableSecondaryArticleRowVariant"
                    [articleNumber]="articleNumber"
                  ></py-article-info>
                </div>
              </td>

              <td
                *ngIf="enableSecondaryArticleRowVariant && showAttributesInSeparateColumn && data.columnAttributes?.length > 0"
                class="separate-attributes-column"
                rowspan="2"
              >
                <py-article-info-attributes
                  [columnAttributes]="data.columnAttributes"
                  [showAttributesInSeparateColumn]="showAttributesInSeparateColumn"
                  [enableSecondaryArticleRowVariant]="enableSecondaryArticleRowVariant"
                ></py-article-info-attributes>
              </td>

              <td class="quantity">
                <div class="skeleton" *ngIf="!data.article && data.articleLoading"></div>

                <div *ngIf="!!data.article && data.renderFully" class="d-flex hide-on-print">
                  <form [formGroup]="prePrintedCountryForm" *ngIf="data.showPrePrintedLabelDropdown">
                    <py-dropdown
                      class="flex-grow-1 country-dropdown"
                      [formControlName]="'configuration'"
                      [options]="data.article.articlePrePrintedConfigurationWithCountry"
                      [title]="'catalog.specialPrePrintingArticleLabel_heading' | cxTranslate"
                      [titleIcon]="'tags'"
                      [resetable]="true"
                      [dropdownContainer]="'body'"
                      [resetLabel]="'common.resetDropdown_action' | cxTranslate"
                      (resetValue)="prePrintedConfigurationControl.reset()"
                    ></py-dropdown>
                  </form>

                  <py-quantity-and-unit-selector
                    [article]="data.article"
                    [values]="data.quantityAndUnitValue"
                    [initialMinimumQuantity]="data.initialMinimumQuantity"
                    (valueChanges)="updateQuantityAndUnit($event)"
                    (belowMinimum)="onBelowMinimum($event)"
                    container="body"
                  ></py-quantity-and-unit-selector>
                </div>
                <!-- Placeholder elements are used to preserve the height of the row when quantity-and-unit-selector is not rendered due to "renderFully=false"  -->
                <div
                  *ngIf="(!data.article || !data.renderFully) && !data.articleLoading"
                  class="quantity-and-unit-placeholder"
                ></div>
              </td>

              <td class="price" *ngIf="!enableSecondaryArticleRowVariant">
                <ng-template [ngTemplateOutlet]="price"></ng-template>
              </td>

              <td class="action hide-on-print">
                <div class="skeleton" *ngIf="!data.article"></div>

                <div class="d-flex flex-row align-items-end" *ngIf="!!data.article">
                  <button
                    class="btn btn-icon btn-primary"
                    (click)="addToCart()"
                    [disabled]="data.disableActionButtons || data.isAddToCartInProgress"
                  >
                    <i *ngIf="!data.isAddToCartInProgress" nz-icon nzType="shopping-cart" nzTheme="outline"></i>
                    <cx-spinner *ngIf="data.isAddToCartInProgress"></cx-spinner>
                  </button>

                  <ng-container *pyPrincipalConfiguration="'enableOrderTemplates'">
                    <py-add-to-shopping-list
                      *ngIf="data.renderFully && !hideAddToShoppingListButton && data.article?.cartType === stockCartType"
                      class="ml-2"
                      [articleCode]="data.article?.code"
                      [quantity]="data.quantityAndUnitValue?.quantity"
                      [unit]="resolveActiveUnit(data.article, data.quantityAndUnitValue?.unitCode)"
                      [placement]="['left', 'left-bottom', 'left-top', 'top', 'top-left', 'top-right']"
                      [disabled]="data.disableActionButtons"
                      [articleNumber]="shoppingListArticleNumber"
                    ></py-add-to-shopping-list>
                  </ng-container>

                  <div *ngIf="additionalArticleActions" class="ml-2">
                    <ng-container
                      *ngTemplateOutlet="
                        additionalArticleActions;
                        context: {
                          article: data.article,
                          quantityAndUnitValue: data.quantityAndUnitValue,
                          disabled: data.disableActionButtons,
                        }
                      "
                    ></ng-container>
                  </div>
                </div>
              </td>
            </tr>

            <tr
              class="price-secondary-row-variant"
              *ngIf="enableSecondaryArticleRowVariant && (!!data.article || data.articleLoading)"
            >
              <td class="price pt-0 pl-2" colspan="2" rowspan="2">
                <div class="mt-3">
                  <ng-template [ngTemplateOutlet]="price"></ng-template>
                </div>
              </td>
            </tr>

            <!-- Extra row -->
            <tr class="extra" *ngIf="!!data.article || data.articleLoading">
              <td [attr.colspan]="enableSecondaryArticleRowVariant ? 2 : 4">
                <div class="d-flex flex-column align-items-start">
                  <py-article-info-attributes
                    class="align-self-stretch"
                    *ngIf="
                      enableSecondaryArticleRowVariant && data.columnAttributes?.length > 0 && !showAttributesInSeparateColumn
                    "
                    [columnAttributes]="data.columnAttributes"
                    [showAttributesInSeparateColumn]="showAttributesInSeparateColumn"
                    [enableSecondaryArticleRowVariant]="enableSecondaryArticleRowVariant"
                  ></py-article-info-attributes>

                  <py-detailed-stock-info
                    [cssClasses]="'mt-2 mb-1'"
                    [small]="true"
                    [article]="data.article"
                    [quantity]="data.quantityAndUnitValue?.quantity"
                    [unitCode]="data.quantityAndUnitValue?.unitCode"
                    [showConsignmentStockLevel]="showConsignmentStockLevel"
                    [enableSecondaryArticleRowVariant]="enableSecondaryArticleRowVariant"
                    [similarArticlesTemplate]="similarArticlesTemplate"
                    (articleOutOfStockChange)="setArticleOutOfStock($event)"
                    [expandedByDefault]="expandedByDefault"
                  ></py-detailed-stock-info>

                  <py-catalog-article-envelope
                    *ngIf="
                      !!data.article &&
                      data.article?.hasOwnProperty('envelopeData') &&
                      (data.article?.envelopeData | json) != '{}'
                    "
                    class="mt-1 w-100"
                    [article]="data.article"
                    [renderFully]="data.renderFully"
                    [expandedByDefault]="expandedByDefault"
                  ></py-catalog-article-envelope>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <!-- notification/action section, which can still be interacted with even if overlay is active -->
      <table class="no-border w-100">
        <tbody>
          <!-- Similar articles row -->
          <tr *ngIf="isSimilarArticlesExpanded">
            <td colspan="5" class="pt-0">
              <ng-template
                [ngTemplateOutlet]="similarArticlesTemplate"
                [ngTemplateOutletContext]="{
                  carouselData: {
                    showTitle: true,
                    showAlternativeArticleBadge: true,
                    showPaginationAtTheBottom: true,
                    useDefaultArticleQuantityAndUnit: true,
                  },
                }"
              ></ng-template>
            </td>
          </tr>
        </tbody>
      </table>

      <ng-template #imageSecondaryRowVariant>
        <!-- When using secondary article row variant we display two images - articleGroup image (e.g. paper color, if available) and article image -->
        <div *ngIf="enableSecondaryArticleRowVariant" class="d-flex flex-column">
          <py-article-image
            *ngIf="data.renderFully && !!data.article?.articleGroupImage"
            class="mb-2"
            [images]="data.article && !!data.article.articleGroupImage ? [data.article.articleGroupImage] : []"
            [loading]="data.articleLoading"
            [altText]="data?.article?.articleName || data?.article?.productName"
          ></py-article-image>

          <py-article-image
            *ngIf="data.renderFully"
            [images]="data.article && !!data.article.image ? [data.article.image] : []"
            [loading]="data.articleLoading"
            [altText]="data?.article?.articleName || data?.article?.productName"
          ></py-article-image>
        </div>
      </ng-template>

      <ng-template #price>
        <div class="skeleton" *ngIf="!data.article"></div>
        <py-catalog-article-price
          *ngIf="!!data.article"
          [article]="data.article"
          [quantityAndUnitValue]="data.quantityAndUnitValue"
          [articlePrice]="data.articlePrice"
          [loadingPrice]="data.articlePriceLoading"
          [cartType]="cartType"
          [showOutgoingBadge]="enableSecondaryArticleRowVariant && data.article?.outgoingOnSale"
          [showLastChanceBadge]="enableSecondaryArticleRowVariant && data.article?.articleStatus === 'ZT'"
          (loadPrice)="loadPrice()"
        ></py-catalog-article-price>
      </ng-template>
    </td>
  </tr>

  <ng-template #similarArticlesTemplate let-carouselData="carouselData">
    <py-catalog-similar-articles
      [articleRef]="articleResultRef?.ref"
      [queryParams]="queryParams"
      [showMessageWhenEmpty]="data.showArticleError && !!data.article"
      [enableSecondaryArticleRowVariant]="enableSecondaryArticleRowVariant"
      [showTitle]="carouselData?.showTitle"
      [showAlternativeArticleBadge]="carouselData?.showAlternativeArticleBadge"
      [showPaginationAtTheBottom]="carouselData?.showPaginationAtTheBottom"
      [useDefaultArticleQuantityAndUnit]="carouselData?.useDefaultArticleQuantityAndUnit"
      [carouselActionMode]="carouselActionModes.ADD_TO_CART_MODE"
    ></py-catalog-similar-articles>
  </ng-template>
</ng-container>
