import { Pipe, PipeTransform } from '@angular/core';
import { MediaService } from './media.service';

@Pipe({ name: 'mediaLocalAsset' })
export class MediaLocalAssetPipe implements PipeTransform {
  constructor(private mediaService: MediaService) {}

  transform(url?: string): string {
    if (!url) {
      return url;
    }
    return this.mediaService.getLocalAssetPath(url);
  }
}
