<ng-container
  *ngIf="{
    loadingStockInfo: loadingStockInfo$ | async,
    status: status$ | async,
    stockInfoError: stockInfoError$ | async,
  } as data"
>
  <div
    *ngIf="
      (forceShowLoading || data.loadingStockInfo || data.status || data.stockInfoError || showDetailed) && !isArticleDiscontinued
    "
    [class.small]="small"
    [ngClass]="cssClasses"
  >
    <div class="d-flex align-items-center flex-wrap gap-2 gap-md-4 basic-info-wrapper" *ngIf="data.status as status">
      <div>
        <ng-container *ngIf="status === stockStatus.InStock" [ngTemplateOutlet]="inStockTemplate"></ng-container>
        <ng-container *ngIf="status === stockStatus.LowInStock" [ngTemplateOutlet]="lowInStockTemplate"></ng-container>
        <ng-container *ngIf="status === stockStatus.OutOfStock" [ngTemplateOutlet]="outOfStockTemplate"></ng-container>
        <ng-container *ngIf="status === stockStatus.SalesBlocked" [ngTemplateOutlet]="salesBlockedTemplate"></ng-container>
        <ng-container
          *ngIf="status === stockStatus.OutOfStockOnWebshop"
          [ngTemplateOutlet]="outOfStockOnWebshopTemplate"
        ></ng-container>
      </div>

      <ng-container
        *ngIf="showStockInfoButton && data.status !== stockStatus.SalesBlocked && data.status !== stockStatus.OutOfStockOnWebshop"
      >
        <ng-container
          *ngIf="!enableSecondaryArticleRowVariant && (status !== stockStatus.OutOfStock || showConsignmentStockLevel)"
          [ngTemplateOutlet]="defaultStockInfoButtonTemplate"
        ></ng-container>
        <ng-container
          *ngIf="enableSecondaryArticleRowVariant && status"
          [ngTemplateOutlet]="secondaryStockInfoButtonTemplate"
        ></ng-container>
      </ng-container>
    </div>

    <ng-container
      *ngIf="(forceShowLoading || data.loadingStockInfo) && !data.status"
      [ngTemplateOutlet]="loadingTemplate"
    ></ng-container>
    <ng-container
      *ngIf="!!data.stockInfoError && !data.status && !data.loadingStockInfo"
      [ngTemplateOutlet]="errorTemplate"
    ></ng-container>
    <ng-container *ngIf="showDetailed" [ngTemplateOutlet]="detailedTemplate"></ng-container>
  </div>
  <div *ngIf="showDiscontinued && isArticleDiscontinued">
    <ng-container [ngTemplateOutlet]="discontinuedTemplate"></ng-container>
  </div>
</ng-container>

<ng-template #defaultStockInfoButtonTemplate>
  <button class="link" (click)="showDetailed = !showDetailed">
    <span class="link-text">
      {{ 'catalog.showDetailedStockInfo_action' | cxTranslate }}
    </span>
    <i nz-icon [nzType]="showDetailed ? 'minus-circle' : 'plus-circle'" nzTheme="outline" class="ml-2"></i>
  </button>
</ng-template>

<ng-template #secondaryStockInfoButtonTemplate>
  <button class="link extended-stock-info-button" (click)="showDetailed = !showDetailed">
    <i nz-icon [nzType]="showDetailed ? 'minus-circle' : 'plus-circle'" nzTheme="outline" class="mr-2"></i>
    <span class="link-text">
      {{ 'catalog.ShowStockAndArticleDetails_action' | cxTranslate }}
    </span>
  </button>
</ng-template>

<ng-template #loadingTemplate>
  <div class="d-flex align-items-center loading-detailed-stock-info">
    <py-badge [loading]="true">
      <span class="text-body">{{ 'catalog.loadingStock_hint' | cxTranslate }}</span>
    </py-badge>
  </div>
</ng-template>

<ng-template #inStockTemplate>
  <div class="d-flex align-items-center">
    <py-badge type="success" [showCircle]="true">
      <span>{{ 'catalog.inStock_hint' | cxTranslate }}</span>
    </py-badge>
  </div>
</ng-template>

<ng-template #lowInStockTemplate>
  <div class="d-flex align-items-center">
    <py-badge type="warning" [showCircle]="true"
      ><span>{{ 'catalog.lowInStock_hint' | cxTranslate }}</span></py-badge
    >
  </div>
</ng-template>

<ng-template #outOfStockTemplate>
  <div class="d-flex align-items-center">
    <py-badge type="info" [showCircle]="true" [ngbTooltip]="'catalog.outOfStock_tooltip' | cxTranslate"
      ><span>{{ 'catalog.outOfStock_hint' | cxTranslate }}</span></py-badge
    >
  </div>
</ng-template>

<ng-template #salesBlockedTemplate>
  <div class="d-flex align-items-center">
    <py-badge type="danger" [showCircle]="true"
      ><span>{{ 'catalog.salesBlocked_hint' | cxTranslate }}</span></py-badge
    >
  </div>
</ng-template>

<ng-template #outOfStockOnWebshopTemplate>
  <div class="d-flex align-items-center">
    <py-badge type="danger" [showCircle]="true">
      <span>{{ 'catalog.outOfStockOnWebshop_hint' | cxTranslate }}</span>
    </py-badge>
  </div>
</ng-template>

<ng-template #discontinuedTemplate>
  <div class="d-flex align-items-center">
    <py-badge type="discontinued" [showCircle]="true">
      <span>{{ 'common.discontinued_badge' | cxTranslate }}</span>
    </py-badge>
  </div>
</ng-template>

<ng-template #detailedTemplate>
  <ng-container *ngIf="showConsignmentStockLevel; else standardStockLevel">
    <py-consignment-stock-article-stock-info
      [consignmentStockArticleStockInfo]="consignmentStockArticleStockInfo$ | async"
      [consignmentStockArticleStockInfoSuccess]="consignmentStockArticleStockInfoSuccess$ | async"
      [consignmentStockArticleStockInfoError]="consignmentStockArticleStockInfoError$ | async"
      [loadingConsignmentStockArticleStockInfo]="loadingConsignmentStockArticleStockInfo$ | async"
      [stockStatusTemplate]="stockStatusTemplate"
      [errorTemplate]="errorTemplate"
    ></py-consignment-stock-article-stock-info>
  </ng-container>

  <ng-template #standardStockLevel>
    <ng-container *ngIf="stockInfo$ | async as stockInfo">
      <ng-container *ngIf="enableSecondaryArticleRowVariant; else defaultDetailedTemplate">
        <py-extended-detailed-stock-info
          [stockInfoDetails]="stockInfoDetails$ | async"
          [loadingStockInfoDetails]="loadingStockInfoDetails$ | async"
          [stockInfoDetailsError]="stockInfoDetailsError$ | async"
          [article]="article"
          [articleStockStatus]="status$ | async"
          [stockStatusTemplate]="stockStatusTemplate"
          [errorTemplate]="errorTemplate"
          [similarArticlesTemplate]="similarArticlesTemplate"
          [warehouses]="stockInfo.stockInfos"
        ></py-extended-detailed-stock-info>
      </ng-container>

      <ng-template #defaultDetailedTemplate>
        <div class="mt-2 d-flex align-items-center justify-content-between" *ngFor="let warehouse of stockInfo.stockInfos">
          <div class="d-flex align-items-center">
            <ng-container
              *ngTemplateOutlet="
                stockStatusTemplate;
                context: { $implicit: { warehouse: warehouse, warehouseName: warehouse.warehouseName } }
              "
            ></ng-container>
          </div>
          <span>{{ warehouse.quantity | cxNumeric: '1.0-3' }} {{ warehouse.unit | unit: warehouse.quantity }} </span>
        </div>
      </ng-template>
    </ng-container>
  </ng-template>
</ng-template>

<ng-template #stockStatusTemplate let-data>
  <div class="d-flex align-items-center stock-status-wrapper" [class.secondary-stock-status]="enableSecondaryArticleRowVariant">
    <ng-container *ngIf="getWarehouseStockStatus(data.warehouse) as warehouseStockStatus">
      <ng-container *ngIf="enableSecondaryArticleRowVariant; else defaultStockStatusTemplate">
        <i
          nz-icon
          *ngIf="warehouseStockStatus === stockStatus.InStock"
          class="py-color-success-500 pr-2"
          nzType="check-circle"
          nzTheme="fill"
        ></i>
        <i
          nz-icon
          *ngIf="warehouseStockStatus === stockStatus.LowInStock"
          class="py-color-warning-500 pr-2"
          nzType="check-circle"
          nzTheme="fill"
        ></i>
        <i
          nz-icon
          *ngIf="warehouseStockStatus === stockStatus.OutOfStock"
          class="py-color-grey-500 pr-2"
          nzType="close-circle"
          nzTheme="fill"
        ></i>
      </ng-container>

      <ng-template #defaultStockStatusTemplate>
        <span class="stock-status bg-success" *ngIf="warehouseStockStatus === stockStatus.InStock"></span>
        <span class="stock-status bg-warning" *ngIf="warehouseStockStatus === stockStatus.LowInStock"></span>
        <span class="stock-status out-of-stock" *ngIf="warehouseStockStatus === stockStatus.OutOfStock"></span>
      </ng-template>
    </ng-container>

    <span>{{ data.warehouseName }}</span>
  </div>
</ng-template>

<ng-template #errorTemplate>
  <button class="link extended-stock-info-button" (click)="reloadStockInfo()">
    <i nz-icon nzType="sync" nzTheme="outline" class="mr-2"></i>
    <span class="link-text">
      {{ 'catalog.loadStockInfo_action' | cxTranslate }}
    </span>
  </button>
</ng-template>
