import { inject } from '@angular/core';
import { Observable, of } from 'rxjs';
import { RoutingContext, SET_ROUTING_CONTEXT } from '../../core/model';

export const routingContextGuard = (routingContext: RoutingContext) => (): Observable<boolean> => {
  const setRoutingContext = inject(SET_ROUTING_CONTEXT, { optional: true });
  if (setRoutingContext) {
    setRoutingContext(routingContext);
  }
  return of(true);
};
