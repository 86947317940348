import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { AbstractReactiveFormInput } from '../abstract-reactive-form-input';

@Component({
  selector: 'py-reactive-form-textarea',
  templateUrl: './reactive-form-textarea.component.html',
  styleUrls: ['./reactive-form-textarea.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReactiveFormTextareaComponent extends AbstractReactiveFormInput implements OnInit, OnDestroy {
  private subscriptions = new Subscription();

  @Input()
  label: string;

  @Input()
  placeholder = '';

  @Input()
  optional?: boolean;

  @Input()
  fieldName: string;

  @Input()
  group: UntypedFormGroup;

  @Input()
  description?: string;

  @Input()
  showOptionalHint? = true;

  @Input()
  includeGroupErrors?: boolean | string = false;

  @Input()
  hideErrorMessages? = false;

  @Input()
  showSuccessState? = false;

  @Input()
  maxLength?: number;

  @Input()
  tooltip?: string;

  constructor(private cd: ChangeDetectorRef) {
    super();
  }

  ngOnInit(): void {
    this.subscriptions.add(this.controlEvents$.subscribe(() => this.cd.markForCheck()));
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  getField(): AbstractControl {
    return this.getGroup().get(this.fieldName);
  }

  getGroup(): UntypedFormGroup {
    return this.group;
  }

  isIncludeGroupErrors(): boolean | string {
    return this.includeGroupErrors;
  }

  get showDescription(): boolean {
    return !!this.getField().value;
  }

  get fieldValueLength(): number {
    return this.getField().value?.length;
  }
}
