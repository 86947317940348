import { APP_BASE_HREF, registerLocaleData } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { APP_ID, APP_INITIALIZER, Injector, ModuleWithProviders, NgModule } from '@angular/core';
import { provideClientHydration, withHttpTransferCacheOptions } from '@angular/platform-browser';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { ConfigModule, ErrorHandlingModule, WindowRef } from '@spartacus/core';
import { BUILD_TIMESTAMP } from '../../../assets/src/build-timestamp';
import { DefaultStorefrontModule } from '../../../storefrontlib/src/default-storefront.module';
import { BrandConfig } from '../../../storefrontlib/src/layout/main/brand-config';
import { SuperStorefrontComponent } from '../../../storefrontlib/src/layout/main/super-storefront.component';
import { BrandConfigService } from '../../../storefrontlib/src/shared';
import { environment } from '../environments/environment';
import { Brand, getBrand } from './brand-utils';

const devImports: ModuleWithProviders<any>[] = [];

if (!environment.production) {
  devImports.push(StoreDevtoolsModule.instrument({ connectInZone: true }));
}

async function fetchBrandConfig(host: string): Promise<BrandConfig> {
  // Fetch config
  const brandConfigLoader: { [key in Brand]: () => Promise<{ config: BrandConfig }> } = {
    [Brand.PACSON]: () => import('../../../pacson/src/app/brand-config'),
    [Brand.PAPYRUS]: () => import('../../../papyrus/src/app/brand-config'),
    [Brand.PROCURATOR]: () => import('../../../procurator/src/app/brand-config'),
    [Brand.SCALDIA]: () => import('../../../scaldia/src/app/brand-config'),
    [Brand.VELIMARK]: () => import('../../../velimark/src/app/brand-config'),
    [Brand.WALKIMEDICAL]: () => import('../../../walkimedical/src/app/brand-config'),
  };
  const brand = getBrand(host);
  if (brand in brandConfigLoader) {
    return brandConfigLoader[brand]().then(({ config }) => config);
  }

  // No config found
  return Promise.reject(`Host ${host} is not configured`);
}

@NgModule({
  imports: [HttpClientModule, DefaultStorefrontModule, ErrorHandlingModule.forRoot(), ...devImports, ConfigModule],
  providers: [
    { provide: APP_BASE_HREF, useValue: '/' },
    { provide: APP_ID, useValue: 'kasbah' },
    {
      provide: APP_INITIALIZER,
      useFactory: (injector: Injector, winRef: WindowRef, brandConfigService: BrandConfigService) => () =>
        brandConfigService
          .loadBrandConfig(() => fetchBrandConfig(injector.get('host')))
          .then((config) => {
            // locale
            config.localeData.forEach((localeData) => registerLocaleData(...localeData));

            // <head> - stylesheets
            config.stylesheets.forEach((stylesheetUrl) => {
              const stylesheetUrlWithVersion = `${stylesheetUrl}?v=${BUILD_TIMESTAMP}`;
              if (!winRef.document.head.querySelector(`link[rel='stylesheet'][href='${stylesheetUrlWithVersion}']`)) {
                const element: HTMLLinkElement = winRef.document.createElement('link');
                element.rel = 'stylesheet';
                element.href = stylesheetUrlWithVersion;
                winRef.document.head.appendChild(element);
              }
            });

            // <head> - links
            config.links?.forEach((attributes, i) => {
              const id = `brand-config-link-${i}`;
              if (!winRef.document.getElementById(id)) {
                const element: HTMLLinkElement = winRef.document.createElement('link');
                element.id = id;
                Object.keys(attributes).forEach((key) => element.setAttribute(key, attributes[key]));
                winRef.document.head.appendChild(element);
              }
            });

            // <head> - meta
            config.meta?.forEach((attributes, i) => {
              const id = `brand-config-meta-${i}`;
              if (!winRef.document.getElementById(id)) {
                const element: HTMLMetaElement = winRef.document.createElement('meta');
                element.id = id;
                Object.keys(attributes).forEach((key) => element.setAttribute(key, attributes[key]));
                winRef.document.head.appendChild(element);
              }
            });

            // <head> - brand config object (pass config object from SSR to browser)
            if (!winRef.document.getElementById('brand-config')) {
              const element: HTMLScriptElement = winRef.document.createElement('script');
              element.id = 'brand-config';
              element.type = 'application/json';
              element.innerText = JSON.stringify(config, (_key, value) => (value === undefined ? '__undefined' : value));
              winRef.document.head.appendChild(element);
            }
          }),
      multi: true,
      deps: [Injector, WindowRef, BrandConfigService],
    },
    provideClientHydration(
      withHttpTransferCacheOptions({
        filter: (req) => req.method === 'GET' && !req.headers.has('Authorization'),
      })
    ),
  ],
  bootstrap: [SuperStorefrontComponent],
})
export class AppModule {}
