import { createFontStack, FontMetrics } from '@capsizecss/core';

export namespace FontUtilsTypes {
  export type FontFace = ReturnType<typeof createFontStack>['fontFaces'][0];
  export type AdditionalFontFaceProperties = Parameters<typeof createFontStack>[1]['fontFaceProperties'];
  export interface BrandFont {
    fontFaceProperties?: FontUtilsTypes.AdditionalFontFaceProperties;
    fallback?: FontMetrics[];
    metrics: FontMetrics;
    src: {
      woff2: string;
      woff: string;
    };
    variants?: Array<Pick<BrandFont, 'src' | 'fontFaceProperties'>>;
  }
  export interface BrandFonts {
    primary: BrandFont;
    'primary-light': BrandFont;
    secondary: BrandFont;
    'secondary-bold': BrandFont;
  }
}

export const getFontFaceDescriptors = (fontFace: FontUtilsTypes.FontFace): FontFaceDescriptors => {
  const font = fontFace['@font-face'];
  return {
    ascentOverride: font.ascentOverride,
    descentOverride: font.descentOverride,
    display: font.fontDisplay,
    featureSettings: font.fontFeatureSettings,
    lineGapOverride: font.lineGapOverride,
    stretch: font.fontStretch,
    style: font.fontStyle,
    unicodeRange: font.unicodeRange,
    weight: <string>font.fontWeight,
  };
};

export const getFontSrc = (brandFont: Pick<FontUtilsTypes.BrandFont, 'src'>): string => {
  return [`url(${brandFont.src.woff2}) format('woff2')`, `url(${brandFont.src.woff}) format('woff')`].join(',');
};

export const fixFallbackFontSrc = (src: string): string => {
  const sources = src?.split(',');
  if (/Arial/.test(sources?.[0]) && /Arial.*MT/.test(sources?.slice(-1)?.[0])) {
    // EBIZ-6673: For some users, Arial may resolve to some Z@Rxxx.tmp font which only contains special characters.
    // The default Arial on Windows has ArialMT as postscript name, so perhaps we should attempt that first.
    return sources.reverse().join(',');
  }
  return src;
};

export const getFontStack = (brandFonts: FontUtilsTypes.BrandFonts): FontUtilsTypes.FontFace[] => {
  return Object.keys(brandFonts ?? {}).reduce((fontStack, key) => {
    const brandFont: FontUtilsTypes.BrandFont = brandFonts[key];
    const fontFamily = key;
    const {
      fontFaces: [brandFontFace],
    } = createFontStack([brandFont.metrics, brandFont.metrics], {
      fontFaceFormat: 'styleObject',
      fontFaceProperties: brandFont.fontFaceProperties,
    });
    const brandFontFaceVariants = (brandFont.variants ?? []).map((font) =>
      replaceFontFaceProperties(
        createFontStack([brandFont.metrics, brandFont.metrics], {
          fontFaceFormat: 'styleObject',
          fontFaceProperties: font.fontFaceProperties ?? brandFont.fontFaceProperties,
        }).fontFaces?.[0],
        { fontFamily, src: getFontSrc(font) }
      )
    );
    const { fontFaces: fallbackFontFaces } = !!brandFont.fallback
      ? createFontStack([brandFont.metrics, ...brandFont.fallback], {
          fontFaceFormat: 'styleObject',
        })
      : { fontFaces: [] };
    return [
      ...fontStack,
      replaceFontFaceProperties(brandFontFace, { fontFamily, src: getFontSrc(brandFont) }),
      ...brandFontFaceVariants,
      ...fallbackFontFaces.map((fallbackFontFace, i) =>
        replaceFontFaceProperties(fallbackFontFace, {
          fontFamily: `${fontFamily}-fallback-${i}`,
          src: fixFallbackFontSrc(fallbackFontFace['@font-face'].src),
        })
      ),
    ];
  }, []);
};

export const replaceFontFaceProperties = (
  fontFace: FontUtilsTypes.FontFace,
  properties: Partial<FontUtilsTypes.FontFace['@font-face']>
): FontUtilsTypes.FontFace => {
  return {
    '@font-face': {
      ...fontFace['@font-face'],
      ...properties,
    },
  };
};

export const loadFont = (fontFace: FontUtilsTypes.FontFace, document: Document): void => {
  const { fontFamily, src } = fontFace['@font-face'];
  const font = new FontFace(fontFamily, src, getFontFaceDescriptors(fontFace));
  (<any>document.fonts).add(font);
  if (!document.fonts.check(`16px ${fontFamily}`)) {
    font.load().catch(() => null);
  }
};

export const isFallbackFontFace = (fontFace: FontUtilsTypes.FontFace): boolean => {
  return !fontFace['@font-face'].src.includes('url(');
};

export const cssFromFontFace = (fontFace: FontUtilsTypes.FontFace): string => {
  const font = fontFace['@font-face'];
  const css = [
    '@font-face {',
    Object.keys(font)
      .filter((key) => !!font[key])
      .reduce((row, key) => {
        const kebabCaseKey = key.replace(/[A-Z]/g, (s) => '-' + s.toLowerCase());
        const escapedValue =
          (kebabCaseKey === 'font-family' && font[key]?.includes(' ')) ||
          (typeof font[key] === 'string' && font[key]?.includes(';'))
            ? `'${font[key].replace(/'/g, "\\'")}'`
            : font[key];
        return [...row, `${kebabCaseKey}: ${escapedValue}`];
      }, [])
      .join('; '),
    '}',
  ].join(' ');
  return css;
};

export const scriptFromFontFace = (fontFace: FontUtilsTypes.FontFace): string => {
  const font = fontFace['@font-face'];
  const script = [
    '(function(){',
    `const fontFamily = ${JSON.stringify(font.fontFamily)};`,
    `const fontSrc = ${JSON.stringify(font.src)};`,
    `const fontFaceDescriptors = ${JSON.stringify(getFontFaceDescriptors(fontFace))};`,
    'const fontFace = new FontFace(fontFamily, fontSrc, fontFaceDescriptors);',
    'document.fonts.add(fontFace);',
    'fontFace.load().catch(() => null);',
    '})();',
  ].join(' ');
  return script;
};
