<ng-container
  *ngIf="{
    availableSearchResultEntitiesCount: availableSearchResultEntitiesCount$ | async,
  } as data"
>
  <py-action-button *ngIf="data.availableSearchResultEntitiesCount > 1" class="mt-5" (click)="openModal()">
    <i nz-icon nzType="user-switch" nzTheme="outline"></i>
    {{ label }}
  </py-action-button>
</ng-container>
