import { registerLocaleData } from '@angular/common';
import { InjectionToken } from '@angular/core';
import { FontUtilsTypes } from './font-utils';

export interface BrandConfig {
  localeData: Parameters<typeof registerLocaleData>[];
  stylesheets: string[];
  fonts: FontUtilsTypes.BrandFonts;
  links: { [key: string]: string }[];
  meta: { [key: string]: string }[];
  iconPathPrefix: string;
}

export const BRAND_CONFIG = new InjectionToken<BrandConfig>('BrandConfig');
