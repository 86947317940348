import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { ChangeSoldToActionTypes } from '../../../../../features/sold-to-base/store/actions/sold-to-change.action';
import { AsyncReturnService } from '../../../../../shared';
import { BaseSiteActionTypes } from '../../../../site-context/store/actions/base-site.action';
import { LanguagesActionTypes } from '../../../../site-context/store/actions/languages.action';
import { normalizeHttpError, withdrawOn } from '../../../../util';
import { CategoryConnector } from '../../connector/category.connector';
import { CategoryActions } from '../actions';

type CategoryActions = CategoryActions.LoadCategoriesSuccess | CategoryActions.LoadCategoriesFail;

@Injectable({ providedIn: 'root' })
export class CategoryEffects {
  private contextChange$: Observable<Action> = this.actions$.pipe(
    ofType(BaseSiteActionTypes.BaseSiteChange, LanguagesActionTypes.LanguageChange, ChangeSoldToActionTypes.ChangeSoldToSuccess)
  );

  loadCategories$: Observable<CategoryActions> = createEffect(() =>
    this.actions$.pipe(
      ofType(CategoryActions.CategoryActionTypes.LoadCategories),
      mergeMap((action: CategoryActions.LoadCategories) => {
        return this.connector.load(action.userId, action.myAssortment).pipe(
          map((data) => {
            this.asyncReturnService.resolveToken(action.actionToken, true);
            return new CategoryActions.LoadCategoriesSuccess(data.result, data.key);
          }),
          catchError((error) => {
            this.asyncReturnService.resolveToken(action.actionToken, false);
            return of(new CategoryActions.LoadCategoriesFail(action.key, normalizeHttpError(error)));
          })
        );
      }),
      withdrawOn(this.contextChange$)
    )
  );

  constructor(
    private actions$: Actions,
    private connector: CategoryConnector,
    private asyncReturnService: AsyncReturnService
  ) {}
}
