import { CxEvent } from '@spartacus/core';
import { ConsentEvent } from '../../../core/events';
import { UserCustomAnalyticsEvent } from '../../../core/user';
import {
  TrackingEventAddEntriesToCart,
  TrackingEventAddPaymentInfo,
  TrackingEventAddShippingInfo,
  TrackingEventAddToWishlist,
  TrackingEventBeginCheckout,
  TrackingEventGenerateLead,
  TrackingEventLogin,
  TrackingEventPurchase,
  TrackingEventRemoveEntriesFromCart,
  TrackingEventRemoveFromWishlist,
  TrackingEventSearch,
  TrackingEventSelectContent,
  TrackingEventSelectItem,
  TrackingEventTutorialBegin,
  TrackingEventTutorialComplete,
  TrackingEventViewCart,
  TrackingEventViewItem,
  TrackingEventViewItemList,
} from '../events';
import {
  Ga4AddPaymentInfoEvent,
  Ga4AddShippingInfoEvent,
  Ga4AddToCartEvent,
  Ga4AddToWishlistEvent,
  Ga4BeginCheckoutEvent,
  Ga4GenerateLeadEvent,
  Ga4LoginEvent,
  Ga4PurchaseEvent,
  Ga4RemoveFromCartEvent,
  Ga4RemoveFromWishlistEvent,
  Ga4SearchEvent,
  Ga4SelectContent,
  Ga4SelectItemEvent,
  Ga4TutorialBegin,
  Ga4TutorialComplete,
  Ga4ViewCartEvent,
  Ga4ViewItemEvent,
  Ga4ViewItemListEvent,
} from '../events/ga4.events';
import {
  Ga4TrackingAddPaymentInfo,
  Ga4TrackingAddShippingInfo,
  Ga4TrackingAddToCart,
  Ga4TrackingAddToWishlist,
  Ga4TrackingBeginCheckout,
  Ga4TrackingGenerateLead,
  Ga4TrackingLogin,
  Ga4TrackingPurchase,
  Ga4TrackingRemoveFromWishlist,
  Ga4TrackingSearch,
  Ga4TrackingSelectContent,
  Ga4TrackingSelectItem,
  Ga4TrackingTutorialBegin,
  Ga4TrackingTutorialComplete,
  Ga4TrackingViewCart,
  Ga4TrackingViewItem,
  Ga4TrackingViewItemList,
} from '../models/ga4.model';
import { GtmTrackingEvent } from './gtm-event';
import { GtmEventUtils } from './gtm-event.utils';

export class GtmEventMapper {
  static mapEvent(event: CxEvent): GtmTrackingEvent {
    if (event instanceof ConsentEvent) {
      return GtmEventUtils.fromConsentEvent(event);
    }
    if (event instanceof TrackingEventAddEntriesToCart) {
      return this.fromTrackingEventAddEntriesToCart(event);
    }
    if (event instanceof TrackingEventRemoveEntriesFromCart) {
      return this.fromTrackingEventRemoveEntriesFromCart(event);
    }
    if (event instanceof TrackingEventViewCart) {
      return this.fromTrackingEventViewCart(event);
    }
    if (event instanceof TrackingEventPurchase) {
      return this.fromTrackingEventPurchase(event);
    }
    if (event instanceof TrackingEventBeginCheckout) {
      return this.fromTrackingEventBeginCheckout(event);
    }
    if (event instanceof TrackingEventAddPaymentInfo) {
      return this.fromTrackingEventAddPaymentInfo(event);
    }
    if (event instanceof TrackingEventAddShippingInfo) {
      return this.fromTrackingEventAddShippingInfo(event);
    }
    if (event instanceof TrackingEventAddToWishlist) {
      return this.fromTrackingEventAddToWishlist(event);
    }
    if (event instanceof TrackingEventRemoveFromWishlist) {
      return this.fromTrackingEventRemoveFromWishlist(event);
    }
    if (event instanceof TrackingEventGenerateLead) {
      return this.fromTrackingEventGenerateLead(event);
    }
    if (event instanceof TrackingEventLogin) {
      return this.fromTrackingEventLogin(event);
    }
    if (event instanceof TrackingEventSearch) {
      return this.fromTrackingEventSearch(event);
    }
    if (event instanceof TrackingEventSelectItem) {
      return this.fromTrackingEventSelectItem(event);
    }
    if (event instanceof TrackingEventViewItem) {
      return this.fromTrackingEventViewItem(event);
    }
    if (event instanceof TrackingEventViewItemList) {
      return this.fromTrackingEventViewItemList(event);
    }
    if (event instanceof TrackingEventTutorialBegin) {
      return this.fromTrackingEventTutorialBegin(event);
    }
    if (event instanceof TrackingEventTutorialComplete) {
      return this.fromTrackingEventTutorialComplete(event);
    }
    if (event instanceof TrackingEventSelectContent) {
      return this.fromTrackingEventSelectContent(event);
    }
    if (event instanceof UserCustomAnalyticsEvent) {
      return GtmEventUtils.fromGtag('event', event.code, event.parameters);
    }

    return undefined;
  }

  private static fromTrackingEventRemoveEntriesFromCart(event: TrackingEventRemoveEntriesFromCart): Ga4RemoveFromCartEvent {
    return {
      event: 'remove_from_cart',
      ecommerce: {
        ...GtmEventUtils.calculateTotalValueFromEntries(event.entries),
        items:
          event.articles?.map &&
          event.articles.map((article, i) => GtmEventUtils.mapItemFromArticleAndEntry(article, event.entries?.[i])),
      },
    };
  }

  private static fromTrackingEventSelectContent(event: TrackingEventSelectContent): Ga4SelectContent {
    return {
      event: 'select_content',
      ecommerce: {
        content_id: event.id,
        content_type: event.type,
        content_text: event.target?.innerText,
      } as Ga4TrackingSelectContent,
    };
  }

  private static fromTrackingEventViewItemList(event: TrackingEventViewItemList): Ga4ViewItemListEvent {
    return {
      event: 'view_item_list',
      ecommerce: {
        items: [
          ...(event?.articles || []).map(GtmEventUtils.mapItemFromArticle),
          ...(event?.products || []).map(GtmEventUtils.mapItemFromProduct),
        ],
        item_list_id: event.id,
        item_list_name: undefined,
      } as Ga4TrackingViewItemList,
    };
  }

  private static fromTrackingEventViewItem(event: TrackingEventViewItem): Ga4ViewItemEvent {
    return {
      event: 'view_item',
      ecommerce: {
        items: [{ ...event.product }].map(GtmEventUtils.mapItemFromProduct),
        item_list_id: event.id,
      } as Ga4TrackingViewItem,
    };
  }

  private static fromTrackingEventSelectItem(event: TrackingEventSelectItem): Ga4SelectItemEvent {
    return {
      event: 'select_item',
      ecommerce: {
        items: [
          ...(event?.articleRef ? [{ code: event.articleRef }] : []).map(GtmEventUtils.mapItemFromArticle),
          ...(event?.productRef ? [{ code: event.productRef }] : []).map(GtmEventUtils.mapItemFromProduct),
        ],
        item_list_id: event.id,
      } as Ga4TrackingSelectItem,
    };
  }

  private static fromTrackingEventTutorialBegin(_event: TrackingEventTutorialBegin): Ga4TutorialBegin {
    return {
      event: 'tutorial_begin',
      ecommerce: {} as Ga4TrackingTutorialBegin,
    };
  }

  private static fromTrackingEventTutorialComplete(_event: TrackingEventTutorialComplete): Ga4TutorialComplete {
    return {
      event: 'tutorial_complete',
      ecommerce: {} as Ga4TrackingTutorialComplete,
    };
  }

  private static fromTrackingEventGenerateLead(event: TrackingEventGenerateLead): Ga4GenerateLeadEvent {
    return {
      event: 'generate_lead',
      ecommerce: {
        formData: event.formData,
      } as Ga4TrackingGenerateLead,
    };
  }

  private static fromTrackingEventSearch(event: TrackingEventSearch): Ga4SearchEvent {
    return {
      event: 'search',
      ecommerce: {
        search_term: event.freeTextSearch,
        items: [
          ...(event?.articles || []).map(GtmEventUtils.mapItemFromArticle),
          ...(event?.products || []).map(GtmEventUtils.mapItemFromProduct),
        ],
      } as Ga4TrackingSearch,
    };
  }

  private static fromTrackingEventLogin(event: TrackingEventLogin): Ga4LoginEvent {
    return {
      event: 'login',
      ecommerce: { ...GtmEventUtils.mapCurrencyValueFromPrice(event.cart?.totalPrice), method: event.method } as Ga4TrackingLogin,
    };
  }

  private static fromTrackingEventAddPaymentInfo(event: TrackingEventAddPaymentInfo): Ga4AddPaymentInfoEvent {
    return {
      event: 'add_payment_info',
      ecommerce: {
        ...GtmEventUtils.mapCurrencyValueFromPrice(event.cart?.totalPrice),
        payment_type: event.paymentOption.type,
      } as Ga4TrackingAddPaymentInfo,
    };
  }

  private static fromTrackingEventAddToWishlist(event: TrackingEventAddToWishlist): Ga4AddToWishlistEvent {
    return {
      event: 'add_to_wishlist',
      ecommerce: {
        ...GtmEventUtils.calculateTotalValueFromEntries(event.entries),
        items: event?.articles.map((article, i) => GtmEventUtils.mapItemFromArticleAndEntry(article, event.entries?.[i])),
      } as Ga4TrackingAddToWishlist,
    };
  }

  private static fromTrackingEventRemoveFromWishlist(event: TrackingEventRemoveFromWishlist): Ga4RemoveFromWishlistEvent {
    return {
      event: 'remove_from_wishlist',
      ecommerce: {
        ...GtmEventUtils.calculateTotalValueFromEntries(event.entries),
        items:
          event.articles?.map &&
          event.articles.map((article, i) => GtmEventUtils.mapItemFromArticleAndEntry(article, event.entries?.[i])),
      } as Ga4TrackingRemoveFromWishlist,
    };
  }

  private static fromTrackingEventAddShippingInfo(event: TrackingEventAddShippingInfo): Ga4AddShippingInfoEvent {
    return {
      event: 'add_shipping_info',
      ecommerce: {
        ...GtmEventUtils.mapCurrencyValueFromPrice(event.cart?.totalPrice),
        shipping_tier: event.shippingOption?.type,
      } as Ga4TrackingAddShippingInfo,
    };
  }

  private static fromTrackingEventViewCart(event: TrackingEventViewCart): Ga4ViewCartEvent {
    return {
      event: 'view_cart',
      ecommerce: {
        ...GtmEventUtils.mapCurrencyValueFromPrice(event.cart?.totalPrice),
        items:
          event.articles?.map &&
          event.articles.map((article, i) => GtmEventUtils.mapItemFromArticleAndEntry(article, event.entries?.[i])),
      } as Ga4TrackingViewCart,
    };
  }

  private static fromTrackingEventPurchase(event: TrackingEventPurchase): Ga4PurchaseEvent {
    return {
      event: 'purchase',
      ecommerce: {
        ...GtmEventUtils.mapCurrencyValueFromPrice(event.cart?.totalPrice),
        items: event.articles.map((article, i) => GtmEventUtils.mapItemFromArticleAndEntry(article, event.entries?.[i])),
        transaction_id: event.order.code,
        shipping: event.order.shippingOptions.map(({ type }) => type).join(','),
        tax: event.order.totalTax,
      } as Ga4TrackingPurchase,
    };
  }

  private static fromTrackingEventBeginCheckout(event: TrackingEventBeginCheckout): Ga4BeginCheckoutEvent {
    return {
      event: 'begin_checkout',
      ecommerce: {
        ...GtmEventUtils.mapCurrencyValueFromPrice(event.cart?.totalPrice),
        items: event.articles.map((article, i) => GtmEventUtils.mapItemFromArticleAndEntry(article, event.entries?.[i])),
      } as Ga4TrackingBeginCheckout,
    };
  }

  private static fromTrackingEventAddEntriesToCart(event: TrackingEventAddEntriesToCart): Ga4AddToCartEvent {
    return {
      event: 'add_to_cart',
      ecommerce: {
        ...GtmEventUtils.calculateTotalValueFromEntries(event.entries),
        items:
          event.articles?.map &&
          event.articles.map((article, i) => GtmEventUtils.mapItemFromArticleAndEntry(article, event.entries?.[i])),
      } as Ga4TrackingAddToCart,
    };
  }
}
