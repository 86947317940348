import { ConsentCode, ConsentEvent } from '../../../core/events';
import { Article, OrderEntry, Price, Product } from '../../../core/model';
import { Ga4TrackingItem } from '../models';
import { GtmConsentEvent } from './gtm-event';

export class GtmEventUtils {
  static mapItemFromArticle(a: Article): Ga4TrackingItem {
    return {
      item_id: a?.code,
      item_name: a?.productName,
      affiliation: undefined,
      coupon: undefined,
      discount: undefined,
      index: undefined,
      item_brand: undefined,
      item_category: a?.categoryCode,
      item_category2: undefined,
      item_category3: undefined,
      item_category4: undefined,
      item_category5: undefined,
      item_list_id: undefined,
      item_list_name: undefined,
      item_variant: undefined,
      location_id: undefined,
      price: undefined,
      quantity: undefined,
      value: undefined,
      currency: undefined,
      type: 'ARTICLE',
      ticket: a?.ticket,
    };
  }

  static mapItemFromProduct(p: Product): Ga4TrackingItem {
    return {
      item_id: p?.code,
      item_name: p?.name,
      affiliation: undefined,
      coupon: undefined,
      discount: undefined,
      index: undefined,
      item_brand: p?.brand?.name,
      item_category: p?.categoryRef,
      item_category2: undefined,
      item_category3: undefined,
      item_category4: undefined,
      item_category5: undefined,
      item_list_id: undefined,
      item_list_name: undefined,
      item_variant: undefined,
      location_id: undefined,
      price: undefined,
      quantity: undefined,
      value: undefined,
      currency: undefined,
      type: 'PRODUCT',
      ticket: p?.ticket,
    };
  }

  static mapItemFromEntry(entry: OrderEntry): Ga4TrackingItem {
    return {
      item_id: entry?.articleRef,
      item_name: undefined,
      affiliation: undefined,
      coupon: undefined,
      discount: entry?.discount ? entry?.basePrice?.value - entry?.totalPrice?.value : undefined,
      index: entry?.entryNumber,
      item_brand: undefined,
      item_category: entry?.itemCategory,
      item_category2: undefined,
      item_category3: undefined,
      item_category4: undefined,
      item_category5: undefined,
      item_list_id: undefined,
      item_list_name: undefined,
      item_variant: undefined,
      location_id: undefined,
      price: entry?.totalPrice?.value,
      quantity: entry?.quantity,
      value: undefined,
      currency: undefined,
      type: 'ARTICLE',
    };
  }

  static mapItemFromArticleAndEntry(a: Article, e: OrderEntry): Ga4TrackingItem {
    const articleEvent = GtmEventUtils.mapItemFromArticle(a);
    const entryEvent = GtmEventUtils.mapItemFromEntry(e);
    return Object.keys(entryEvent).reduce((event, key) => ({ ...event, [key]: event[key] ?? entryEvent[key] }), articleEvent);
  }

  static mapConsentCode(code: ConsentCode): string[] {
    switch (code) {
      case ConsentCode.MARKETING_THIRD_PARTY:
        return ['ad_storage', 'ad_user_data'];
      case ConsentCode.PERSONALIZATION:
        return ['personalization_storage', 'ad_personalization'];
      case ConsentCode.TRACKING:
        return ['analytics_storage'];
    }
  }

  /**
   * Create a datalayer "update consent" object from a ConsentEvent.
   *
   * @param event The ConsentEvent to be converted to the datalayer object.
   * @returns An array-like object, ready to be pushed onto the dataLayer.
   */
  static fromConsentEvent(event: ConsentEvent): GtmConsentEvent {
    const consentCodes = GtmEventUtils.mapConsentCode(event.code);
    return this.fromGtag(
      'consent',
      'update',
      consentCodes.reduce((consents, consentCode) => ({ ...consents, [consentCode]: event.isGiven ? 'granted' : 'denied' }), {})
    );
  }

  /**
   * Create a datalayer object using gtag() compatible syntax.
   *
   * The difference from gtag() is that this function returns the object
   * instead of pushing it directly to the datalayer.
   *
   * @param _args Same arguments as per gtag() documentation.
   * @returns An array-like object, ready to be pushed onto the dataLayer.
   */
  static fromGtag(..._args: any[]) {
    return arguments;
  }

  static diffPrice(oldPrice: Price, newPrice: Price): Price {
    return { ...newPrice, value: +(newPrice.value - oldPrice.value).toFixed(2) };
  }

  static mapCurrencyValueFromPrice(price: any): { currency: string; value: number } {
    return {
      currency: price?.currencyIso,
      value: price?.value,
    };
  }

  static calculateTotalValueFromEntries(entries: OrderEntry[]): { currency: string; value: number } {
    return {
      currency: entries.find((entry) => !!entry?.totalPrice?.currencyIso)?.totalPrice?.currencyIso,
      value: entries.reduce((value, entry) => value + (entry?.totalPrice?.value ?? 0), 0),
    };
  }
}
