import { AsyncValidatorFn } from '@angular/forms';
import { map, Observable } from 'rxjs';
import { CartError, CartErrorContext } from '../../../../core/model';

export class CartValidator {
  public static validateCartEntry(
    cartErrors$: Observable<CartError[] | null>,
    context: CartErrorContext,
    entryId: string
  ): AsyncValidatorFn {
    return () =>
      cartErrors$.pipe(
        map(
          (cartErrors) =>
            cartErrors?.find((cartError) => cartError?.context === context && cartError?.entryId === entryId)?.error ?? null
        )
      );
  }
}
