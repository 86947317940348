import { ChangeDetectionStrategy, Component, HostBinding, Input, OnInit } from '@angular/core';
import { AuthService } from '@spartacus/core';
import { LAUNCH_CALLER, LaunchDialogService } from '@spartacus/storefront';
import { BehaviorSubject, Observable, combineLatest } from 'rxjs';
import { filter, map, shareReplay, startWith, switchMap } from 'rxjs/operators';
import { ArticleService } from '../../../../core/catalog';
import { Article, Certification, ColumnAttribute, Image, PackagingType, SubstituteType } from '../../../../core/model';
import { ArticleInfoAttributesFacade, PrincipalConfigurationService } from '../../../../core/user';
import { prepareUrlForLink } from '../../../../core/util';
import { ImageModalData } from '../../image-modal/image-modal.component';
import { MediaService } from '../../media';

@Component({
  selector: 'py-article-info',
  templateUrl: './info.component.html',
  styleUrls: ['./info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ArticleInfoComponent implements OnInit {
  substituteTypes: typeof SubstituteType = SubstituteType;
  packagingTypes: typeof PackagingType = PackagingType;

  article$ = new BehaviorSubject<Article | undefined>(undefined);
  isDiscontinued: boolean;

  @Input() set article(article: Article) {
    this.article$.next(article);
  }

  @Input() showSplit = false;

  @Input() queryParams: {
    [k: string]: any;
  };

  @Input() outOfStock: boolean;
  @Input() substituteBadgeType: SubstituteType;
  @Input() articleNumber?: string;
  @Input() showPrePrintedLabelIconForSpecialArticle?: boolean = true;

  private _showOrderReturnEntryBadge: boolean;

  @Input() set showOrderReturnEntryBadge(showBadge: boolean) {
    this._showOrderReturnEntryBadge = showBadge;

    if (showBadge) {
      this.showBadges$ = this.showBadges();
    }
  }

  get showOrderReturnEntryBadge(): boolean {
    return this._showOrderReturnEntryBadge;
  }

  @HostBinding('class.secondary-styling')
  @Input()
  enableSecondaryArticleRowVariant = false;

  displaybadgeforZTarticle$: Observable<boolean>;
  enableContractedArticleIcon$: Observable<boolean>;
  enableCuttingOrEnableReelCutting$: Observable<boolean>;
  enableDisplayPrePrintingSymbolOnArticle$: Observable<boolean>;
  enableReaming$: Observable<boolean>;
  enableBreakable$: Observable<boolean>;
  columnAttributes$: Observable<ColumnAttribute[]>;
  isUserLoggedIn$: Observable<boolean>;
  tenderedEnabled$: Observable<boolean>;
  enableDisplayOutgoingArea$: Observable<boolean>;
  showBadges$: Observable<boolean>;

  prePrintedLabelContryCode$: Observable<string>;

  featuredCertificates$: Observable<Certification[]>;

  get url(): string | undefined {
    const url = this.article$.value?.url;
    const qp = this.queryParams ?? { mya: '0' };
    return url ? prepareUrlForLink(url, qp) : undefined;
  }

  constructor(
    private authService: AuthService,
    private mediaService: MediaService,
    private articleService: ArticleService,
    private principalConfigurationService: PrincipalConfigurationService,
    private articleInfoAttributesService: ArticleInfoAttributesFacade,
    private launchDialogService: LaunchDialogService
  ) {}

  getMediaUrl(url: string): string {
    return this.mediaService.resolveAbsoluteUrl(url);
  }

  ngOnInit(): void {
    this.isUserLoggedIn$ = this.authService
      .isUserLoggedIn()
      .pipe(startWith(false), shareReplay({ bufferSize: 1, refCount: true }));

    this.displaybadgeforZTarticle$ = this.principalConfigurationService.isEnabled('displaybadgeforZTarticle');

    this.enableCuttingOrEnableReelCutting$ = combineLatest([
      this.principalConfigurationService.isEnabled('enableCutting'),
      this.principalConfigurationService.isEnabled('enableReelCutting'),
      this.isUserLoggedIn$,
    ]).pipe(
      map(([enableCutting, enableReelCutting, isUserLoggedIn]) => (enableCutting || enableReelCutting) && isUserLoggedIn),
      shareReplay({ bufferSize: 1, refCount: true })
    );

    this.enableReaming$ = combineLatest([
      this.principalConfigurationService.isEnabled('enableReaming'),
      this.principalConfigurationService.isEnabled('enableDisplayReamingSymbolOnArticle'),
    ]).pipe(
      map(([enableReaming, enableDisplayReamingSymbolOnArticle]) => enableReaming && enableDisplayReamingSymbolOnArticle),
      shareReplay({ bufferSize: 1, refCount: true })
    );

    this.enableBreakable$ = this.principalConfigurationService
      .isEnabled('enableBreakableArticleExplanation')
      .pipe(shareReplay({ bufferSize: 1, refCount: true }));

    this.enableDisplayPrePrintingSymbolOnArticle$ = this.principalConfigurationService
      .isEnabled('enableDisplayPrePrintingSymbolOnArticle')
      .pipe(shareReplay({ bufferSize: 1, refCount: true }));

    this.enableContractedArticleIcon$ = this.principalConfigurationService.isEnabled('enableContractedArticleIcon');

    this.columnAttributes$ = this.article$.pipe(
      filter((article) => !!article),
      switchMap((article) =>
        this.articleInfoAttributesService.getColumnAttributes(article, this.enableSecondaryArticleRowVariant)
      )
    );

    this.tenderedEnabled$ = this.principalConfigurationService.isEnabled('enableTendered');
    this.enableDisplayOutgoingArea$ = this.principalConfigurationService.isEnabled('enableDisplayOutgoingArea');

    this.showBadges$ = this.showBadges();

    this.featuredCertificates$ = combineLatest([
      this.article$,
      this.principalConfigurationService.isEnabled('enableArticleLevelCertificatesProcurator'),
    ]).pipe(
      filter(([article, enableArticleLevelCertificatesProcurator]) => !!article && !!enableArticleLevelCertificatesProcurator),
      map(([article]) => article.extraInfo?.productCertifications?.groups?.flatMap((group) => group?.certificates)),
      map((certificates) => certificates?.filter((certificate) => certificate?.featured))
    );

    this.prePrintedLabelContryCode$ = this.article$.pipe(
      filter((article) => !!article),
      map(
        (article) =>
          article.articlePrePrintedConfigurationWithCountry?.find((config) => config.value === this.articleNumber)?.label
      )
    );
  }

  openCertificate(icon: Image): void {
    const modalData: ImageModalData = {
      images: [icon],
    };

    this.launchDialogService.openDialogAndSubscribe(LAUNCH_CALLER.IMAGE, undefined, modalData);
  }

  private showBadges(): Observable<boolean> {
    return combineLatest([
      this.article$,
      this.tenderedEnabled$,
      this.enableDisplayOutgoingArea$,
      this.displaybadgeforZTarticle$,
    ]).pipe(
      filter(([article]) => !!article),
      map(([article, tenderedEnabled, enableDisplayOutgoingArea, displaybadgeforZTarticle]) => {
        const tendered = article.tendered && tenderedEnabled;
        const outlet = article.outgoingOnSale && enableDisplayOutgoingArea;
        this.isDiscontinued = this.articleService.isArticleDiscontinued(article);
        const lastChance = displaybadgeforZTarticle && article.articleStatus === 'ZT';

        return (
          article.overlayNew ||
          article.breakable ||
          tendered ||
          outlet ||
          this.isDiscontinued ||
          !!this.substituteBadgeType ||
          !!this.showOrderReturnEntryBadge ||
          lastChance
        );
      })
    );
  }
}
