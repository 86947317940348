import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { I18nModule } from '../../../core/i18n';
import { PipesModule } from '../../pipes';
import { ActionButtonModule } from '../action-button';
import { SpinnerModule } from '../spinner';
import { SoldToSelectorModalModule } from './sold-to-selector-modal/sold-to-selector-modal.module';
import { SoldToSelectorComponent } from './sold-to-selector.component';

@NgModule({
  declarations: [SoldToSelectorComponent],
  exports: [SoldToSelectorComponent],
  imports: [
    CommonModule,
    I18nModule,
    NzIconModule,
    NgbDropdownModule,
    InfiniteScrollModule,
    SpinnerModule,
    ReactiveFormsModule,
    PipesModule,
    ActionButtonModule,
    SoldToSelectorModalModule,
  ],
})
export class SoldToSelectorModule {}
