import { Inject, Injectable, Optional } from '@angular/core';
import { WindowRef } from '@spartacus/core';
import { BRAND_CONFIG, BrandConfig } from '../../../layout/main/brand-config';

@Injectable({ providedIn: 'root' })
export class BrandConfigService {
  constructor(
    protected winRef: WindowRef,
    @Optional() @Inject(BRAND_CONFIG) protected config: BrandConfig
  ) {}

  async loadBrandConfig(fetchConfig: () => Promise<BrandConfig>): Promise<BrandConfig> {
    // Get already resolved config, if applicable
    if (this.config) {
      return Promise.resolve(this.config);
    }

    // Read config from SSR response
    const brandConfigElement = this.winRef.document.getElementById('brand-config');
    if (brandConfigElement) {
      this.config = JSON.parse(brandConfigElement.innerText, (_key, value) => (value === '__undefined' ? undefined : value));
      return Promise.resolve(this.config);
    }

    // Fetch config
    return fetchConfig().then((config) => {
      this.config = config;
      return config;
    });
  }

  getBrandConfig(): BrandConfig {
    return this.config;
  }
}
