export enum Brand {
  PACSON = 'pacson',
  PROCURATOR = 'procurator',
  PAPYRUS = 'papyrus',
  SCALDIA = 'scaldia',
  VELIMARK = 'velimark',
  WALKIMEDICAL = 'walkimedical',
}

const brandHostMap: { [key in Brand]: RegExp } = {
  [Brand.PACSON]: /pacson/,
  [Brand.PAPYRUS]: /papyrus/,
  [Brand.PROCURATOR]: /procurator/,
  [Brand.SCALDIA]: /scaldia/,
  [Brand.VELIMARK]: /velimark/,
  [Brand.WALKIMEDICAL]: /walkimedical/,
};

export function getBrand(host: string): Brand {
  return Object.keys(brandHostMap).find((key: string) => brandHostMap[key].test(host)) as Brand;
}
