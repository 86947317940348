<tr>
  <td class="py-0 w-100" colspan="100%">
    <div class="position-relative">
      <aside class="overlay" *ngIf="(articleFailure$ | async) && !article">
        <py-cart-overlay-message
          [(expanded)]="isSimilarArticlesExpanded"
          [expandable]="canExpandSimilarArticles"
          [message]="'cart.articleCanNoLongerBePurchased_hint' | cxTranslate"
          [hideCartLink]="hideCartLink"
          [iconLabel]="entry.articleRef"
        ></py-cart-overlay-message>
      </aside>

      <!-- "information" section, which may be covered by an overlay -->
      <table class="no-border w-100">
        <tbody>
          <tr class="main">
            <td
              *ngIf="
                (config.selectable && !isOnOrderHistoryPage) ||
                (config.selectable && isOnOrderHistoryPage && isInOrderReturnMode$ | async)
              "
              class="select"
              rowspan="3"
            >
              <py-checkbox>
                <input
                  type="checkbox"
                  [checked]="selected"
                  [disabled]="
                    ((isDiscontinued || isSalesBlocked) && !(isInOrderReturnMode$ | async)) ||
                    isOrderEntryReturned ||
                    ((isInOrderReturnMode$ | async) && !entry?.delivered)
                  "
                  (change)="onSelect($event.target.checked)"
                />
              </py-checkbox>
            </td>

            <td class="image" rowspan="3">
              <py-article-image
                *ngIf="enableSecondaryArticleRowVariant && article?.articleGroupImage"
                class="mb-2"
                [images]="[article.articleGroupImage]"
                [loading]="!article"
                [altText]="article?.articleName || article?.productName"
              ></py-article-image>

              <py-article-image
                [images]="article?.image && [article.image]"
                [loading]="!article"
                [altText]="article?.articleName || article?.productName"
              ></py-article-image>
            </td>

            <td class="info" rowspan="2">
              <py-article-info
                [article]="article"
                [showSplit]="entry.splitOrderEntry"
                [substituteBadgeType]="substituteBadgeType$ | async"
                [showOrderReturnEntryBadge]="config.showOrderReturnEntryBadge && !!getReturnEntryForCurrentEntry"
                [enableSecondaryArticleRowVariant]="enableSecondaryArticleRowVariant"
                [articleNumber]="articleNumber"
                [showPrePrintedLabelIconForSpecialArticle]="articleNumber !== prePrintedSpecial.VOLVO"
              ></py-article-info>

              <ng-container
                *ngIf="{
                  columnAttributes: columnAttributes$ | async,
                } as data"
              >
                <py-article-info-attributes
                  *ngIf="enableSecondaryArticleRowVariant && data.columnAttributes?.length > 0 && !!article"
                  class="align-self-stretch d-block mt-2"
                  [columnAttributes]="data.columnAttributes"
                  [enableSecondaryArticleRowVariant]="enableSecondaryArticleRowVariant"
                ></py-article-info-attributes>
              </ng-container>
              <ng-container *ngIf="!(isDiscontinued && (showSubstitutesArticles || showBrowseReplacementButton))">
                <py-detailed-stock-info
                  *ngIf="config.enableStockInfo"
                  [cssClasses]="'mt-3 mb-1'"
                  [article]="article"
                  [quantity]="entry.quantity"
                  [unitCode]="entry.unit?.code"
                  [small]="true"
                  [showConsignmentStockLevel]="showConsignmentStockLevel"
                  [enableSecondaryArticleRowVariant]="enableSecondaryArticleRowVariant"
                  [similarArticlesTemplate]="similarArticlesTemplate"
                ></py-detailed-stock-info>

                <ng-container *pyPrincipalConfiguration="'enableWarehouseOnOrderLine'">
                  <p
                    *ngIf="config.showWarehouseName && entry.warehouse?.name"
                    class="warehouse"
                    [ngClass]="isOnCheckoutPage ? 'mt-2 mb-3' : 'my-2'"
                  >
                    {{
                      (isOnCheckoutPage ? 'checkout.warehouse_hint' : 'orderHistory.warehouse_hint')
                        | cxTranslate: { name: entry.warehouse.name }
                    }}
                  </p>
                </ng-container>

                <button
                  *ngIf="(isInOrderReturnMode$ | async) && showAdditionalInfoButton"
                  class="link text-nowrap show-extras-button"
                  (click)="showExtrasSectionInReturnMode = !showExtrasSectionInReturnMode"
                >
                  <i
                    nz-icon
                    [nzType]="showExtrasSectionInReturnMode ? 'minus-circle' : 'plus-circle'"
                    nzTheme="outline"
                    class="d-flex mr-2"
                  ></i>
                  <span class="link-text">
                    {{ 'orderHistory.additionalInfo_action' | cxTranslate }}
                  </span>
                </button>
              </ng-container>
            </td>

            <td class="quantity" *ngIf="!(isDiscontinued && showSubstitutesArticles)">
              <ng-container *ngIf="config.enableQuantityControl && enableEditEntryAndShowingSimilar && !!article">
                <py-quantity-and-unit-selector
                  [article]="article"
                  [values]="{ quantity: this.entry?.quantity, unit: this.entry?.unit?.code }"
                  [initialMinimumQuantity]="initialMinimumQuantity$ | async"
                  (valueChanges)="updateQuantityAndUnit($event)"
                ></py-quantity-and-unit-selector>
              </ng-container>

              <ng-template
                *ngIf="
                  !enableSecondaryArticleRowVariant &&
                  !(config.enableQuantityControl && enableEditEntryAndShowingSimilar && !!article)
                "
                [ngTemplateOutlet]="quantity"
              ></ng-template>
            </td>

            <td
              class="price"
              *ngIf="!enableSecondaryArticleRowVariant && showPrice && !(isDiscontinued && showSubstitutesArticles)"
            >
              <ng-template [ngTemplateOutlet]="priceAndQuantityMessage"></ng-template>
            </td>

            <td
              colspan="9"
              class="delete pb-3"
              *ngIf="(isDiscontinued && showBrowseReplacementButton) || (config.deletable && enableEditEntryAndShowingSimilar)"
            >
              <div class="d-flex justify-content-end">
                <div class="mr-2" *ngIf="isDiscontinued && showBrowseReplacementButton">
                  <button
                    *ngIf="isOnCheckoutPage; else replacementCategoryAction"
                    class="btn btn-ternary btn-link"
                    [routerLink]="{ cxRoute: 'cart' } | cxUrl"
                    [queryParams]="cartTypeQueryParams"
                  >
                    {{ 'common.browseReplacement_action' | cxTranslate }}
                  </button>

                  <ng-template #replacementCategoryAction>
                    <button
                      class="btn btn-ternary btn-link"
                      (click)="navigateToReplacementCategoryOrSearchForDisplayName(article?.categoryCode, article?.displayName)"
                    >
                      {{ 'common.browseReplacement_action' | cxTranslate }}
                    </button>
                  </ng-template>
                </div>
                <ng-container *ngIf="config.deletable && enableEditEntryAndShowingSimilar">
                  <py-delete-button (confirmDeletion)="onConfirmedDelete()"></py-delete-button>
                </ng-container>
              </div>
            </td>
          </tr>

          <tr class="price-secondary-row-variant">
            <td class="price py-0 pl-2" colspan="9">
              <ng-container *ngIf="enableSecondaryArticleRowVariant">
                <div
                  *ngIf="
                    !(config.enableQuantityControl && enableEditEntryAndShowingSimilar && !!article) &&
                    entry.showCustomerSelectedUnit
                  "
                  class="text-right"
                >
                  <ng-template [ngTemplateOutlet]="quantity"></ng-template>
                </div>
                <ng-template
                  *ngIf="showPrice && !(isDiscontinued && showSubstitutesArticles)"
                  [ngTemplateOutlet]="priceAndQuantityMessage"
                ></ng-template>

                <ul class="order-conditions">
                  <ng-container *pyPrincipalConfiguration="'enableNetPrices'">
                    <ng-container
                      *ngIf="
                        !!entry.cutting &&
                        !config.showCuttingButton &&
                        enableEditEntryAndShowingSimilar &&
                        cartType === cartTypeStock
                      "
                    >
                      <li class="d-flex pb-1 align-items-end justify-content-end">
                        <span class="mr-1 text-right"
                          >+ {{ config.translationKeyPrefix + '.cutting.calculatedCuttingCost_heading' | cxTranslate }}:</span
                        >
                        <span>{{ (entry.cutting?.cuttingPrice | price: 'symbol-narrow' : true) || '-' }}</span>
                      </li>
                      <li class="d-flex pb-1 align-items-end justify-content-end">
                        <span class="mr-1 text-right"
                          >+ {{ config.translationKeyPrefix + '.cutting.packagingCost_heading' | cxTranslate }}:</span
                        >
                        <span>{{ (entry.cutting?.packagingPrice | price: 'symbol-narrow' : true) || '-' }}</span>
                      </li>
                    </ng-container>
                    <ng-container
                      *ngIf="
                        !!entry.reelCutting &&
                        !config.showReelCuttingButton &&
                        enableEditEntryAndShowingSimilar &&
                        cartType === cartTypeStock
                      "
                    >
                      <li class="d-flex pb-1 align-items-end justify-content-end">
                        <span class="mr-1 text-right"
                          >+ {{ config.translationKeyPrefix + '.reelCutting.calculatedCuttingCost_heading' | cxTranslate }}:</span
                        >
                        <span>{{ (entry.reelCutting?.cuttingPrice | price: 'symbol-narrow' : true) || '-' }}</span>
                      </li>
                      <li class="d-flex pb-1 align-items-end justify-content-end">
                        <span class="mr-1 text-right"
                          >+ {{ config.translationKeyPrefix + '.reelCutting.packagingCost_heading' | cxTranslate }}:</span
                        >
                        <span>{{ (entry.reelCutting?.packagingPrice | price: 'symbol-narrow' : true) || '-' }}</span>
                      </li>
                    </ng-container>
                  </ng-container>
                  <li
                    *ngFor="let orderCondition of activeOrderConditions || []"
                    class="d-flex pb-1 align-items-end justify-content-end"
                  >
                    <span class="mr-1 text-right">+ {{ orderCondition.name }}:</span>
                    <span>{{ orderCondition.priceData | price: 'symbol-narrow' : true }}</span>
                  </li>
                </ul>
              </ng-container>
            </td>
          </tr>

          <tr class="extras">
            <td colspan="4" class="pl-2 text-left">
              <div
                #extrasDataWrapper
                [hidden]="(isInOrderReturnMode$ | async) && !showExtrasSectionInReturnMode"
                *ngIf="!(isDiscontinued && (showSubstitutesArticles || showBrowseReplacementButton))"
              >
                <ng-container *ngIf="controlsEnabled">
                  <ng-container *ngIf="hasTransferConfiguration">
                    <p class="mb-2 transfer-configuration my-2">{{ 'cart.brandedAsAgreed_hint' | cxTranslate }}</p>
                  </ng-container>

                  <ng-container *pyPrincipalConfiguration="'enableOrderNoteRow'">
                    <py-order-line-input
                      #addNote
                      *ngIf="(config.enableNoteControl && enableEditEntryAndShowingSimilar) || !!entry.note"
                      class="text-left"
                      [showButton]="config.enableNoteControl && enableEditEntryAndShowingSimilar"
                      [isMandatory]="isOrderNoteMandatory"
                      (save)="onNoteSave($event)"
                      [disabled]="loading"
                      [entryFormGroup]="entryFormGroup"
                      [initValue]="entry.note"
                      [labelConfigurationKey]="'orderLineNoteLabel'"
                      [placeholderConfigurationKey]="'orderLineNotePlaceholder'"
                      [regEx]="'orderLineNoteRegularExpressionRule'"
                      [key]="'note'"
                    ></py-order-line-input>
                  </ng-container>
                  <ng-container *pyPrincipalConfiguration="'enablePalletFlags'">
                    <py-pallet-flag
                      *ngIf="config.showPalletFlags || !!entry?.palletFlags"
                      [cartType]="cartType"
                      [entryNumber]="entry.entryNumber"
                      [entryId]="entry.id"
                      [initValue]="entry.palletFlags"
                      [viewMode]="!config.showPalletFlags"
                      [disabled]="loading"
                    ></py-pallet-flag>
                  </ng-container>
                  <ng-container *pyPrincipalConfiguration="'enableAccountingCodes'">
                    <py-order-entry-accounting-codes
                      #accountingCodes
                      *ngIf="(config.enableAccountingCodes && enableEditEntryAndShowingSimilar) || !!entry.accountingCode"
                      [entry]="entry"
                      [showButton]="config.enableAccountingCodes && enableEditEntryAndShowingSimilar"
                      [disabled]="loading || accountingCodeListLoading || disableAccountingCodeSelection"
                      [showTooltip]="showAccountingCodeTooltip"
                      [entryFormGroup]="entryFormGroup"
                      [entryFormArray]="formGroup?.get('formGroupEntries')"
                      [accountingCodeList$]="accountingCodeList$"
                      [providedCartType]="cartType"
                      [cartErrors]="cartErrors"
                      (accountingCodeUpdated)="onAccountingCodeUpdated()"
                    ></py-order-entry-accounting-codes>
                  </ng-container>
                  <ng-container *pyPrincipalConfiguration="'enableStatisticsCode'">
                    <py-order-line-input
                      #addStatisticsCode
                      *ngIf="(config.enableStatisticsCodeControl && enableEditEntryAndShowingSimilar) || !!entry.statisticsCode"
                      class="text-left"
                      [icon]="'fund'"
                      [showButton]="config.enableStatisticsCodeControl && enableEditEntryAndShowingSimilar"
                      [isMandatory]="isStatisticsCodeMandatory"
                      (save)="onStatisticsCodeSave($event)"
                      [disabled]="loading"
                      [entryFormGroup]="entryFormGroup"
                      [initValue]="entry.statisticsCode"
                      [labelConfigurationKey]="'statisticsCodeLabel'"
                      [placeholderConfigurationKey]="'statisticsCodePlaceholder'"
                      [key]="'statisticsCode'"
                    >
                    </py-order-line-input>
                  </ng-container>
                  <ng-container *ngIf="config.enableNameMarking && hasNameMarking">
                    <py-name-marking-button
                      [articleTitle]="article?.displayName"
                      [names]="nameConfigurationInfo?.configurationTextValues || []"
                      [inEcommerceQuantity]="inEcommerceQuantity"
                      [disabled]="loading"
                      [showButton]="config.showNameMarkingButton"
                      (handleSave)="updateNameMarking($event)"
                    ></py-name-marking-button>
                  </ng-container>
                  <ng-container *pyPrincipalConfiguration="'enablePrePrintedLabels'">
                    <ng-container *ngIf="hasPrePrintableLabel">
                      <py-pre-printed-button
                        [article]="article"
                        [entry]="entry"
                        [disabled]="loading"
                        [showButton]="showPrePrintedLabelButton"
                        [translationKeyPrefix]="config.translationKeyPrefix"
                      ></py-pre-printed-button>
                    </ng-container>
                  </ng-container>
                  <ng-container *pyPrincipalConfiguration="'enableCutting'">
                    <ng-container *ngIf="config.enableCutting && article?.cuttable && article?.productType !== 'R'">
                      <py-cutting-button
                        [article]="article"
                        [entry]="entry"
                        [disabled]="loading || entry?.reaming?.reamed"
                        [translationKeyPrefix]="config.translationKeyPrefix"
                        [showButton]="config.showCuttingButton && cartType === cartTypeStock"
                        [showDustFree]="config.showCuttingDustFree"
                        [showPerpendicular]="config.showCuttingPerpendicular"
                        [showPrice]="!enableSecondaryArticleRowVariant"
                        (handleSave)="updateCutting($event)"
                        (handleRemove)="removeCutting()"
                      ></py-cutting-button>
                    </ng-container>
                  </ng-container>
                  <ng-container *pyPrincipalConfiguration="'enableReelCutting'">
                    <ng-container *ngIf="config.enableReelCutting && article?.cuttable && article?.productType === 'R'">
                      <py-reel-cutting-button
                        [article]="article"
                        [entry]="entry"
                        [disabled]="loading || entry?.reaming?.reamed"
                        [translationKeyPrefix]="config.translationKeyPrefix"
                        [showButton]="config.showReelCuttingButton && cartType === cartTypeStock"
                        [showPrice]="!enableSecondaryArticleRowVariant"
                        (handleSave)="updateReelCutting($event)"
                        (handleRemove)="removeReelCutting()"
                      ></py-reel-cutting-button>
                    </ng-container>
                  </ng-container>
                  <ng-container *pyPrincipalConfiguration="'enableReaming'">
                    <ng-container *ngIf="config.enableReaming && article?.reamable">
                      <py-reaming-button
                        [article]="article"
                        [entry]="entry"
                        [disabled]="loading || !!entry?.reelCutting || !!entry?.cutting"
                        [translationKeyPrefix]="config.translationKeyPrefix"
                        [showButton]="config.showReelCuttingButton && cartType === cartTypeStock"
                        (handleSave)="addReaming()"
                        (handleRemove)="removeReaming()"
                      ></py-reaming-button>
                    </ng-container>
                  </ng-container>
                  <ng-container *pyPrincipalConfiguration="'enableFullPalletNote'">
                    <py-pallet-flag-note
                      *ngIf="showPalletFlagNote"
                      class="mt-3"
                      [cartType]="cartType"
                      [entryNumber]="entry.entryNumber"
                      [entryId]="entry.id"
                    ></py-pallet-flag-note>
                  </ng-container>
                  <ng-container *ngIf="displayCuttingMessage$ | async">
                    <div class="my-3">
                      <p class="py-color-secondary">
                        <i class="mr-1" nz-icon nzType="scissor" nzTheme="outline"></i>
                        {{ 'checkout.changeCuttingToArticlesInTheShoppingCart_hint' | cxTranslate }}
                      </p>
                    </div>
                  </ng-container>
                  <ng-container *ngIf="displayReamingMessage$ | async">
                    <div class="my-3">
                      <p class="py-color-secondary">
                        <i class="mr-1" nz-icon nzType="reaming-available" nzTheme="outline"></i>
                        {{ 'checkout.changeReamingToArticlesInTheShoppingCart_hint' | cxTranslate }}
                      </p>
                    </div>
                  </ng-container>
                </ng-container>

                <ul
                  *ngIf="(!enableSecondaryArticleRowVariant && hasActiveOrderConditions) || entry.explodedOrderLines?.length > 0"
                  class="border-top mt-2 order-conditions"
                >
                  <ng-container *ngIf="!enableSecondaryArticleRowVariant">
                    <li *ngFor="let orderCondition of activeOrderConditions || []" class="d-flex pt-2 justify-content-between">
                      <span>{{ orderCondition.name }}</span>
                      <span>{{ orderCondition.value | price: 'symbol-narrow' : true }}</span>
                    </li>
                  </ng-container>
                  <li
                    *ngFor="let explodedOrderLine of entry.explodedOrderLines || []"
                    class="d-flex pt-1 justify-content-between"
                  >
                    <span>
                      {{ explodedOrderLine.shortText }}
                      <py-name-marking-text
                        *ngIf="explodedOrderLine.showNameMarkingTextLines"
                        [names]="explodedOrderLine.nameMarkingTextLines"
                      ></py-name-marking-text>
                    </span>
                    <span *ngIf="!!explodedOrderLine.price?.value">{{
                      explodedOrderLine.price | price: 'symbol-narrow' : true
                    }}</span>
                  </li>
                </ul>
              </div>

              <ng-container *ngIf="selected && (isInOrderReturnMode$ | async)">
                <py-order-return-entry-form [entry]="entry" [article]="article"></py-order-return-entry-form>
              </ng-container>

              <ng-container *ngIf="getReturnEntryForCurrentEntry as orderReturnEntry">
                <py-order-return-entry-info
                  [orderReturnEntry]="orderReturnEntry"
                  [article]="article"
                ></py-order-return-entry-info>
              </ng-container>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <!-- notification/action section, which can still be interacted with even if overlay is active -->
    <table class="no-border w-100" [class.selectable]="config.selectable">
      <tbody>
        <tr *ngIf="hasResolveOptions" class="resolve-options">
          <td class="pl-3" colspan="4">
            <div class="resolve-options-wrapper">
              <py-order-entry-resolve-options
                [entry]="entry"
                [stockQuantityWarning]="stockQuantityWarningForPartialDelivery$ | async"
                [enableSecondaryArticleRowVariant]="enableSecondaryArticleRowVariant"
                (resolve)="onResolveOptionSelect($event)"
              ></py-order-entry-resolve-options>
            </div>
          </td>
        </tr>

        <ng-container *pyPrincipalConfiguration="'enableFullPalletUpgrade'">
          <tr class="notification" *ngIf="config.enableFullPalletUpgrade">
            <td class="pl-3" colspan="9">
              <py-order-entry-full-pallet-upgrade-wrapper
                class="d-block"
                [entry]="entry"
                [cartType]="cartType"
                (fullPalletUpgraded)="onFullPalletUpgraded()"
              ></py-order-entry-full-pallet-upgrade-wrapper>
            </td>
          </tr>
        </ng-container>

        <ng-container *ngFor="let message of entryMessages">
          <tr class="notification" *ngIf="message.tag && message.text">
            <td class="pl-3" colspan="9">
              <py-notification class="article-level-message" [type]="message.tag" [message]="message.text"></py-notification>
            </td>
          </tr>
        </ng-container>

        <!-- In the old storefront the message was displayed as icon with tooltip so the config need to be reused here  -->
        <ng-container *pyPrincipalConfiguration="'enableDisplayExtendedDeliveryTimeIcon'">
          <tr class="notification" *ngIf="isOnCheckoutPage && article?.articleExtendedDelivery">
            <td class="pl-3" colspan="9">
              <py-notification
                class="article-level-message"
                type="info"
                [message]="'checkout.review.extendedDelivery_text' | cxTranslate"
              ></py-notification>
            </td>
          </tr>
        </ng-container>

        <tr class="notification" *ngIf="showIndentNotification">
          <td class="pl-3" colspan="9">
            <py-notification
              class="article-level-message"
              type="info"
              [message]="'checkout.review.indentInfo_text' | cxTranslate"
            ></py-notification>
          </td>
        </tr>

        <tr class="notification" *ngIf="!hasDefaultResolveOptions && stockQuantityWarning$ | async as warning">
          <td class="pl-3" colspan="9">
            <py-notification class="article-level-message" type="danger" [tiny]="true" [message]="warning"></py-notification>
          </td>
        </tr>

        <tr *ngIf="!isDiscontinued && isSimilarArticlesExpanded">
          <td colspan="9">
            <ng-template
              [ngTemplateOutlet]="similarArticlesTemplate"
              [ngTemplateOutletContext]="{
                carouselData: {
                  showTitle: true,
                  showNotification: true,
                  showAlternativeArticleBadge: true,
                  showPaginationAtTheBottom: true,
                  useDefaultArticleQuantityAndUnit: true,
                },
              }"
            ></ng-template>
          </td>
        </tr>

        <tr class="substitues-row" *ngIf="showSubstitutesArticles && isDiscontinued && (substituteRefs$ | async)?.length > 0">
          <td colspan="9" class="text-left pt-0">
            <py-order-entry-substitutes-articles
              [substituteRefs]="substituteRefs$ | async"
              [isCartWriter]="isCartWriter"
              [enableSecondaryArticleRowVariant]="enableSecondaryArticleRowVariant"
              (replace)="onReplaceWithSubstitute($event)"
              (addToCart)="onAddToCart($event)"
            ></py-order-entry-substitutes-articles>
          </td>
        </tr>
      </tbody>
    </table>
  </td>
</tr>

<ng-template #priceAndQuantityMessage>
  <div class="d-flex flex-column align-items-end" *ngIf="{ articlePrice: articlePrice$ | async } as data">
    <div class="in-ecommerce-quantity text-right" *ngIf="unitQuantityMessage && enableSecondaryArticleRowVariant">
      <span>{{ 'cart.quantity_heading' | cxTranslate: { quantity: unitQuantityMessage } }}</span>
    </div>
    <py-article-price
      [loading]="loadingPrice$ | async"
      [price]="price"
      [contractedPrice]="contractedPrice"
      [articlePrice]="data.articlePrice"
      [comparativePrice]="entry.comparativePrice"
      [showArticlePriceInsteadOfEntryPrice]="config.loadAndShowArticlePriceInsteadOfEntryPrice"
      [priceCampaign]="article?.priceCampaign"
      [cartType]="cartType"
      [showOutgoingBadge]="enableSecondaryArticleRowVariant && article?.outgoingOnSale"
      (loadPrice)="loadPrice(false, true)"
    ></py-article-price>
    <ng-container *pyPrincipalConfiguration="'enableDisplayArticleLineComparativePrices'">
      <span class="in-ecommerce-quantity" *ngIf="getComparativePrice(data.articlePrice) as comparativePrice">
        {{
          'catalog.comparativePrice_heading'
            | cxTranslate
              : {
                  count: comparativePrice?.quantity,
                  price: comparativePrice?.formattedValue,
                  unit: comparativePrice?.unit | unit: comparativePrice?.quantity,
                }
        }}
      </span>
    </ng-container>
    <div class="in-ecommerce-quantity text-right" *ngIf="unitQuantityMessage && !enableSecondaryArticleRowVariant">
      <span>{{ 'cart.quantity_heading' | cxTranslate: { quantity: unitQuantityMessage } }}</span>
    </div>
  </div>
</ng-template>

<ng-template #quantity>
  <span class="text-nowrap">{{ entry.quantity }} {{ entry.unit | unit: entry.quantity }}</span>
</ng-template>

<ng-template #similarArticlesTemplate let-carouselData="carouselData">
  <ng-container *ngIf="similarArticles$ !== null && { similarArticles: similarArticles$ | async } as observables">
    <py-order-entry-similar-articles
      class="mb-4"
      [articles]="observables.similarArticles"
      [loading]="!observables.similarArticles"
      [action]="config.outOfStockAction"
      [enableSecondaryArticleRowVariant]="enableSecondaryArticleRowVariant"
      [showTitle]="carouselData?.showTitle"
      [showNotification]="carouselData?.showNotification"
      [showAlternativeArticleBadge]="carouselData?.showAlternativeArticleBadge"
      [showPaginationAtTheBottom]="carouselData?.showPaginationAtTheBottom"
      [useDefaultArticleQuantityAndUnit]="carouselData?.useDefaultArticleQuantityAndUnit"
      [carouselActionMode]="isCartWriter ? carouselActionModes.ENTRY_INTERACTION_MODE : carouselActionModes.ADD_TO_CART_MODE"
      (replace)="onReplaceWithSimilarItem($event)"
      (delete)="onConfirmedDelete()"
    ></py-order-entry-similar-articles>
  </ng-container>
</ng-template>
